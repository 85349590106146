import { createSelector } from "reselect";

const profileState = (state) => state.profile;

const getProfile = createSelector(profileState, (profile) => profile);
const getCounter = createSelector(profileState, (profile) => profile.counter);
const getPersonalInfo = createSelector(
  profileState,
  (profile) => profile.personalInfo
);
const getRasiKatam = createSelector(
  profileState,
  (profile) => profile.rasiKatam
);
const getRasiPlanets = createSelector(
  profileState,
  (profile) => profile.rasiPlanets
);
const getNavamsaKatam = createSelector(
  profileState,
  (profile) => profile.navamsaKatam
);
const getNavamsaPlanets = createSelector(
  profileState,
  (profile) => profile.navamsaPlanets
);
const getResidentialDetails = createSelector(
  profileState,
  (profile) => profile.residentialAddress
);
const getContactNumber = createSelector(
  profileState,
  (profile) => profile.contactDetails
);
const getQualification = createSelector(
  profileState,
  (profile) => profile.qualification
);
const getOccupation = createSelector(
  profileState,
  (profile) => profile.occupation
);
const getHoroscopeDetails = createSelector(
  profileState,
  (profile) => profile.horoscopeDetails
);
const getFamilyDetails = createSelector(
  profileState,
  (profile) => profile.familyDetails
);
const getBirthDetails = createSelector(
  profileState,
  (profile) => profile.birthDetails
);
const getProfileImage = createSelector(
  profileState,
  (profile) => profile.personalInfo.profileImage
);
const getIsFormChanged = createSelector(
  profileState,
  (profile) => profile.isFormChanged
);

const getGovtId = createSelector(profileState, (profile) => profile.govtId);
const getOtherDetails = createSelector(profileState, (profile) => {
  return {
    profileImage: profile.personalInfo.profileImage,
    fatherOccupation: profile.familyDetails.fatherOccupation,
    motherOccupation: profile.familyDetails.motherOccupation,
    caste: profile.familyDetails.caste,
    religion: profile.familyDetails.religion,
    companyName: profile.occupation.companyName,
    cityOfWorking: profile.occupation.cityOfWorking,
    fieldOfStudy: profile.qualification.fieldOfStudy,
    hometown: profile.residentialAddress.hometown,
    ownHouse: profile.ownHouse,
    fatherAlive: profile.familyDetails.fatherAlive,
    motherAlive: profile.familyDetails.motherAlive,
    horoscopeImage: profile.horoscopeImage,
    eatingHabits: profile.eatingHabits,
    smookingHabits: profile.smookingHabits,
    drinkingHabits: profile.drinkingHabits,
  };
});

const getVerificationDetails = createSelector(profileState, (profile) =>
  profile?.verificationDetails ? profile.verificationDetails : {}
);

export {
  getBirthDetails,
  getContactNumber,
  getCounter,
  getFamilyDetails,
  getGovtId,
  getHoroscopeDetails,
  getIsFormChanged,
  getNavamsaKatam,
  getNavamsaPlanets,
  getOccupation,
  getOtherDetails,
  getPersonalInfo,
  getProfile,
  getProfileImage,
  getQualification,
  getRasiKatam,
  getRasiPlanets,
  getResidentialDetails,
  getVerificationDetails,
};
