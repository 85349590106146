import { createSelector } from "reselect";

const memberState = (state) => state.member;

const getCounter = createSelector(
  memberState,
  (memberProfile) => memberProfile.counter
);

const getMemberDetails = createSelector(
  memberState,
  (memberProfile) => memberProfile.member
);

export { getCounter, getMemberDetails };
