import Box from "@mui/material/Box";
import React from "react";
import { useSelector } from "react-redux";
import LableWithFormField from "../../../components/label-with-formfield";
import * as config from "../../../config/config";
import { getLang } from "../../../store/selector";
// import { actions } from "../store/slice";

const Basic = () => {
  // const dispatch = useDispatch();
  const lang = useSelector(getLang);
  let fromAge,
    toAge,
    fromHeight,
    toHeight,
    motherTongue,
    physicalStatus,
    drinkingHabits,
    eatingHabits,
    smokingHabits;

  const basicFields = [
    {
      type: "select",
      text: "From Age",
      id: "fromAge",
      defaultValue: fromAge,
      placeholder: "20",
      array: config.ageLimit,
    },
    {
      type: "select",
      text: "To Age",
      id: "toAge",
      defaultValue: toAge,
      placeholder: "20",
      array: config.ageLimit,
    },
    {
      type: "select",
      text: "From Height",
      id: "fromHeight",
      defaultValue: fromHeight,
      placeholder: "20",
      array: config.height,
    },
    {
      type: "select",
      text: "To Height",
      id: "toHeight",
      defaultValue: toHeight,
      placeholder: "20",
      array: config.height,
    },
    {
      type: "select",
      text: "Mother Tongue",
      id: "motherTongue",
      defaultValue: motherTongue,
      array: config.motherTongue,
    },
    {
      type: "select",
      text: "Physical Status",
      id: "physicalStatus",
      defaultValue: physicalStatus,
      array: config.physicalStatus,
    },
    {
      type: "select",
      text: "Eating Habits",
      id: "eatingHabits",
      defaultValue: eatingHabits,
      array: config.eatingHabits,
    },
    {
      type: "select",
      text: "Drinking Habits",
      id: "drinkingHabits",
      defaultValue: drinkingHabits,
      array: config.drinkingHabits,
    },
    {
      type: "select",
      text: "Smoking Habits",
      id: "smokingHabits",
      defaultValue: smokingHabits,
      array: config.smokingHabits,
    },
  ];

  return (
    <Box
      sx={{
        flexGrow: 1,
        padding: "25px",
      }}
    >
      <div className="table-responsive">
        <table className="table">
          <tbody>
            {basicFields.map((props, index) => (
              <LableWithFormField key={index} {...props} />
            ))}
          </tbody>
        </table>
      </div>
    </Box>
  );
};

export default Basic;
