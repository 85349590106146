import React from "react";
import { useSelector } from "react-redux";
import useMediaQuery from "../src/hooks/use-media-query";
import ComingsoonPage from "./components/comingsoon-page";
import Footer from "./components/footer";
import Header from "./components/header";
import Loading from "./components/loading";
import {
  getComingSoon,
  getIsHeaderFooter,
  getIsLoading,
} from "./store/selector";

const App = ({ children }) => {
  const isHeaderFooter = useSelector(getIsHeaderFooter);
  const comingSoon = useSelector(getComingSoon);
  const isLoading = useSelector(getIsLoading);
  const { isDesktop } = useMediaQuery();

  return comingSoon ? (
    <ComingsoonPage />
  ) : (
    <>
      {isLoading && <Loading />}
      {isHeaderFooter && <Header hamburger={true} />}
      <main
        style={{
          backgroundColor: "#f4f7fC",
          marginTop: isDesktop ? "90px" : "100px",
        }}
      >
        {children}
      </main>
      {isHeaderFooter && <Footer />}
    </>
  );
};

export default App;
