import Box from "@mui/material/Box";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import LableWithFormField from "../../../components/label-with-formfield/lable-with-formfield";
import * as config from "../../../config/config";
import { IMAGE_FORMAT } from "../../../constant";
import useFileUpload from "../../../hooks/use-file-upload";
import { getHoroscopeDetails, getOtherDetails } from "../store/selector";
import { actions } from "../store/slice";

const HoroscopeDetails = () => {
  const dispatch = useDispatch();
  const horoscopeDetails = useSelector(getHoroscopeDetails);
  const otherDetails = useSelector(getOtherDetails);
  const successMessage = "Horoscope Image Uploaded Successfully!!!";
  const { fileUpload } = useFileUpload();
  const {
    rasi,
    nakshatra,
    gothram,
    mathulam,
    lagnam,
    naaligai,
    thithi,
    dasaPlanet,
    dasaBalance,
  } = horoscopeDetails;
  const { horoscopeImage } = otherDetails;

  useEffect(() => {
    dispatch(
      actions.setHoroscopeDetails({
        rasi,
        nakshatra,
        gothram,
        lagnam,
        mathulam,
        naaligai,
        thithi,
        dasaPlanet,
        dasaBalance,
      })
    );
  }, [
    rasi,
    nakshatra,
    gothram,
    lagnam,
    mathulam,
    naaligai,
    thithi,
    dasaPlanet,
    dasaBalance,
    dispatch,
  ]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    dispatch(
      actions.setHoroscopeDetails({
        ...horoscopeDetails,
        [name]: value,
      })
    );
  };

  const horoscopeFormFeilds = [
    {
      type: "select",
      text: "Rasi",
      id: "rasi",
      name: "rasi",
      defaultValue: rasi,
      onChange: (e) => handleChange(e),
      array: config.rasi,
    },
    {
      type: "select",
      text: "Nakshatra",
      id: "nakshatra",
      name: "nakshatra",
      defaultValue: nakshatra,
      onChange: (e) => handleChange(e),
      array: config.nakshatra,
    },
    {
      type: "text",
      text: "Gothram",
      id: "gothram",
      name: "gothram",
      placeholder: "...",
      defaultValue: gothram,
      onChange: (e) => handleChange(e),
    },
    {
      type: "text",
      text: "Mathulam",
      id: "mathulam",
      name: "mathulam",
      placeholder: "...",
      defaultValue: mathulam,
      onChange: (e) => handleChange(e),
    },
    {
      type: "select",
      text: "Lagnam",
      id: "lagnam",
      name: "lagnam",
      defaultValue: lagnam,
      onChange: (e) => handleChange(e),
      array: config.rasi,
    },
    {
      type: "text",
      text: "Naaligai",
      id: "naaligai",
      name: "naaligai",
      placeholder: "...",
      defaultValue: naaligai,
      onChange: (e) => handleChange(e),
    },
    {
      type: "text",
      text: "Thithi",
      id: "thithi",
      name: "thithi",
      placeholder: "...",
      defaultValue: thithi,
      onChange: (e) => handleChange(e),
    },
    {
      type: "text",
      text: "Dasa Planet",
      id: "dasaPlanet",
      name: "dasaPlanet",
      placeholder: "...",
      defaultValue: dasaPlanet,
      onChange: (e) => handleChange(e),
    },
    {
      type: "text",
      text: "Dasa Balance",
      id: "dasaBalance",
      name: "dasaBalance",
      placeholder: "...",
      defaultValue: dasaBalance,
      onChange: (e) => handleChange(e),
    },
  ];

  return (
    <Box
      sx={{
        flexGrow: 1,
        padding: "25px",
      }}
    >
      <div className="table-responsive">
        <table className="table">
          <tbody>
            <tr>
              <td colSpan={2} style={{ borderTop: "none" }}>
                <div
                  className="media"
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <img
                    src={horoscopeImage || "https://picsum.photos/200"}
                    className="horoscope-img w mr-3"
                    alt="horoscope-pic"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td colSpan={2} style={{ borderTop: "none" }}>
                <div className="custom-file">
                  <input
                    type="file"
                    className="custom-file-input form-control"
                    id="customFile"
                    onChange={(e) =>
                      fileUpload(e, successMessage, "horoscopeImage")
                    }
                    accept={IMAGE_FORMAT}
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td colSpan={2}>
                <p>
                  <small>
                    <b>Note:</b> File size should be less than or equal to 2 MB.
                  </small>
                  <small> Allowed File types are JPEG, PNG, GIF</small>
                </p>
              </td>
            </tr>
            {horoscopeFormFeilds?.map((props, index) => (
              <LableWithFormField key={index} {...props} />
            ))}
          </tbody>
        </table>
      </div>
    </Box>
  );
};

export default HoroscopeDetails;
