import Box from "@mui/material/Box";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import LableWithFormField from "../../../components/label-with-formfield/lable-with-formfield";
import { getOccupation } from "../store/selector";
import { actions } from "../store/slice";

const OccupationDetails = () => {
  const dispatch = useDispatch();
  const occupation = useSelector(getOccupation);
  const { companyName, designation, annualIncome, cityOfWorking } = occupation;

  const handleChange = (e) => {
    const { name, value } = e.target;
    dispatch(actions.setIsFormChanged(true));
    dispatch(
      actions.setOccupationDetails({
        ...occupation,
        [name]: value,
      })
    );
  };

  const occupationFormFields = [
    {
      type: "text",
      text: "Company Name",
      id: "companyName",
      name: "companyName",
      placeholder: "XYZ Pvt Ltd",
      defaultValue: companyName,
      onChange: (e) => handleChange(e),
    },
    {
      type: "text",
      text: "Designation",
      id: "designation",
      name: "designation",
      placeholder: "Manager",
      defaultValue: designation,
      onChange: (e) => handleChange(e),
    },
    {
      type: "text",
      text: "Annual Income",
      id: "annualIncome",
      name: "annualIncome",
      placeholder: "7 LPA",
      defaultValue: annualIncome,
      onChange: (e) => handleChange(e),
    },
    {
      type: "text",
      text: "City of Work",
      id: "cityOfWorking",
      name: "cityOfWorking",
      placeholder: " Chennai",
      defaultValue: cityOfWorking,
      onChange: (e) => handleChange(e),
    },
  ];

  return (
    <Box
      sx={{
        flexGrow: 1,
        padding: "25px",
      }}
    >
      <div className="table-responsive">
        <table className="table">
          <tbody>
            {occupationFormFields?.map((props, index) => (
              <LableWithFormField key={index} {...props} />
            ))}
          </tbody>
        </table>
      </div>
    </Box>
  );
};

export default OccupationDetails;
