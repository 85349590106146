import { faEye } from "@fortawesome/free-solid-svg-icons/faEye";
import { faEyeSlash } from "@fortawesome/free-solid-svg-icons/faEyeSlash";
import React, { useEffect, useState } from "react";
import ReactGA from "react-ga";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import useForm from "../../hooks/use-forms";
import { getUser } from "../../store/selector";
import { onSignIn, verifyToken } from "../../store/services";
import { actions } from "../../store/slice";
import { errorToaster, successToaster } from "../../utils/alerts";
import { setItem } from "../../utils/local-storage";
import LayoutPage from "../layout-page";
import TextInput from "../text-input";
import TextInputWithIcon from "../text-input-with-icon/text-input-with-icon";

const Login = () => {
  const currentUser = useSelector(getUser);
  const { emailAddress, password, token } = currentUser;
  const { handleChange, errors, values } = useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);

  useEffect(() => {
    dispatch(
      actions.setCurrentUser({
        ...currentUser,
        ...values,
      })
    );
  }, [values]);

  useEffect(() => {
    token &&
      verifyToken(token)
        .then((res) => {
          if (res.data.success) {
            dispatch(actions.setToken(token));
            dispatch(actions.setIsHeaderFooter(true));
            navigate("/profileedit");
          }
        })
        .catch((err) => console.error("err", err));
  }, [dispatch, navigate, token]);

  const loginInputs = [
    {
      type: "email",
      placeholder: "Enter email",
      value: emailAddress,
      name: "emailAddress",
      onChange: handleChange,
      error: errors.emailAddress,
    },
    {
      type: showPassword ? "text" : "password",
      placeholder: "Password",
      name: "password",
      value: password,
      onChange: handleChange,
      onKeyDown: (e) => {
        if (e.key === "Enter") {
          handleLogin(e);
        }
      },
      buttonOnClick: () => {
        setShowPassword(!showPassword);
      },
      buttonIcon: !showPassword ? faEye : faEyeSlash,
      error: errors.password,
    },
  ];

  const handleLogin = (e) => {
    e.preventDefault();
    dispatch(actions.setLoading(true));
    onSignIn({ emailAddress, password })
      .then((res) => {
        if (res.data.success) {
          dispatch(actions.setLoading(false));
          setItem("the_main_app", res.data.token);
          setItem("current_user", res.data.currentUser);
          successToaster(res.data.message, 4000);
          dispatch(actions.setToken(res.data.token));
          dispatch(
            actions.setLoginSuccess({
              emailAddress: res.data.currentUser.emailAddress,
              userId: res.data.currentUser.userId,
              username: res.data.currentUser.username,
            })
          );
        } else {
          errorToaster(res.data.message, 4000);
          dispatch(actions.setLoading(false));
          dispatch(
            actions.setError({
              status: true,
              message: res.data.message,
              severity: "error",
            })
          );
          console.log("success false");
        }
      })
      .catch((err) => console.error("err", err));
  };

  return (
    <LayoutPage
      content={
        <div>
          {loginInputs.map(
            (
              {
                type,
                placeholder,
                name,
                value,
                onChange,
                onKeyDown,
                buttonOnClick,
                buttonIcon,
                error,
              },
              index
            ) => {
              switch (name) {
                case "password":
                  return (
                    <TextInputWithIcon
                      key={index}
                      type={type}
                      placeholder={placeholder}
                      name={name}
                      value={value}
                      onChange={onChange}
                      onKeyDown={onKeyDown}
                      buttonOnClick={buttonOnClick}
                      buttonIcon={buttonIcon}
                      error={error}
                    />
                  );
                default:
                  return (
                    <TextInput
                      key={index}
                      type={type}
                      className="input-field"
                      placeholder={placeholder}
                      name={name}
                      value={value}
                      onChange={onChange}
                      onKeyDown={onKeyDown}
                      error={error}
                    />
                  );
              }
            }
          )}

          {/* <div className="custom-control custom-checkbox form-group">
            <input
              type="checkbox"
              className="custom-control-input"
              id="exampleCheck1"
            />
            <label className="custom-control-label" htmlFor="exampleCheck1">
              Remember
            </label>
          </div> */}
          <button
            className="btn btn-link btn-sm"
            onClick={() => navigate("/forgetpassword")}
            style={{
              textDecoration: "none",
              cursor: "pointer",
            }}
          >
            <p className="m-0">Forget Password</p>
          </button>
          <button
            className="btn btn-primary float-right m-l-xxs"
            style={{ marginRight: "6px" }}
            disabled={emailAddress === "" || password === ""}
            onClick={(e) => handleLogin(e)}
          >
            Sign In
          </button>
          <button
            onClick={() => navigate("/register")}
            className="btn btn-secondary float-right"
          >
            Register
          </button>
        </div>
      }
      title="Login"
    />
  );
};

export default Login;
