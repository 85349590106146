import { omit } from "lodash";
import { useState } from "react";

const useForm = () => {
  //Form values
  const [values, setValues] = useState({});
  //Errors
  const [errors, setErrors] = useState({});

  const validate = (event, name, value) => {
    //A function to validate each input values
    switch (name) {
      case "username":
        if (value.length <= 4) {
          // we will set the error state

          setErrors({
            ...errors,
            username: "Username atleast have 5 letters",
          });
        } else {
          // set the error state empty or remove the error for username input
          //omit function removes/omits the value from given object and returns a new object
          let newObj = omit(errors, "username");
          setErrors(newObj);
        }
        break;

      case "emailAddress":
        if (
          !new RegExp(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          ).test(value)
        ) {
          setErrors({
            ...errors,
            emailAddress: "Enter a valid email address",
          });
        } else {
          let newObj = omit(errors, "emailAddress");
          setErrors(newObj);
        }
        break;

      case "password":
        if (
          !new RegExp(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/).test(value)
        ) {
          setErrors({
            ...errors,
            password: `Password should contains atleast \n 1. One Uppercase \n 2. One  Lowercase \n 3. One Number. \n`,
          });
        } else {
          let newObj = omit(errors, "password");
          setErrors(newObj);
        }
        break;

      default:
        break;
    }
  };

  //A method to handle form inputs
  const handleChange = (event, tempObj) => {
    //To stop default events
    event.persist();
    const { name, value } = event.target;
    // console.log("🚀 ~ handleChange ~ value:", value);
    // console.log("🚀 ~ handleChange ~ name:", name);

    validate(event, name, value);

    //Let's set these values in state
    setValues({
      ...values,
      [name]: value,
    });
  };
  return {
    values,
    errors,
    handleChange,
  };
};

export default useForm;
