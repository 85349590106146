export const USER_API = "/api/user/";
export const ACCOUNT_API = "/api/account/";

export const isDev = process.env.REACT_APP_ENV === "development";
export const isLocalHost = process.env.REACT_APP_ENV === "localhost";
export const isTesting = process.env.REACT_APP_ENV === "testing";
export const isProd = process.env.REACT_APP_ENV === "production";

export const HOST = isLocalHost
  ? "http://localhost:8080"
  : isDev
  ? process.env.REACT_APP_DEV_HOST
  : isTesting
  ? process.env.REACT_APP_QA_HOST
  : process.env.REACT_APP_PROD_HOST;

export const SIGNUP = HOST + USER_API + "singup";
export const SIGNIN = HOST + ACCOUNT_API + "signin";
export const SIGNOUT = HOST + ACCOUNT_API + "signout?token=";
export const VERIFY = HOST + ACCOUNT_API + "verify?token=";
export const PROFILE_FETCH = HOST + ACCOUNT_API + "profile/fetch?token=";
export const MEMBER_SEARCH_FETCH =
  HOST + ACCOUNT_API + "profile/searchMemberProfile?token=";
export const PROFILE_EDIT = HOST + ACCOUNT_API + "profile/profileedit";
export const SUBSCRIBE = HOST + USER_API + "subscribe";
export const FORGET_PASSWORD = HOST + USER_API + "forgetPassword";
export const RESET_PASSWORD = HOST + USER_API + "resetPassword/";
export const EMAIL_VERIFICATION = HOST + USER_API + "emailVerification";
export const EMAIL_CONFIRMATION = HOST + USER_API + "emailConfirmation/";
export const COUNT = HOST + USER_API + "counters";
export const DEC_COUNT = HOST + USER_API + "counters/decrement/";
export const GOVT_ID_VERIFICATION = HOST + USER_API + "govtIdVerification";
export const SEARCH_FETCH = HOST + ACCOUNT_API + "profile/searchProfile";
export const SEARCH_FETCH_BY_FILTER =
  HOST + ACCOUNT_API + "profile/searchProfile";
export const FETCH_BY_SEARCH =
  HOST + ACCOUNT_API + "profile/searchProfile/search";
