import { createSlice } from "@reduxjs/toolkit";
import initialState from "./initial-state";

const { actions, reducer } = createSlice({
  name: "profile",
  initialState,
  reducers: {
    setCounterIncrement(state) {
      state.counter += 1;
    },
    setCounterDecrement(state) {
      state.counter -= 1;
    },
    setPersonalInfo(state, actions) {
      state.personalInfo = actions.payload;
    },
    setGovtId(state, actions) {
      state.govtId = actions.payload;
    },
    setRasiKatam(state, actions) {
      state.rasiKatam = actions.payload;
    },
    setRasiPlanets(state, actions) {
      state.rasiPlanets = actions.payload;
    },
    setNavamsaKatam(state, actions) {
      state.navamsaKatam = actions.payload;
    },
    setNavamsaPlanets(state, actions) {
      state.navamsaPlanets = actions.payload;
    },
    setResidentialAddress(state, actions) {
      state.residentialAddress = actions.payload;
    },
    setContactDetails(state, actions) {
      state.contactDetails = actions.payload;
    },
    setOccupationDetails(state, actions) {
      state.occupation = actions.payload;
    },
    setHoroscopeDetails(state, actions) {
      state.horoscopeDetails = actions.payload;
    },
    setFamilyDetails(state, actions) {
      state.familyDetails = actions.payload;
    },
    setBirthDetails(state, actions) {
      state.birthDetails = actions.payload;
    },
    setQualification(state, actions) {
      state.qualification = actions.payload;
    },
    setProfileDetails(state, actions) {
      state.createdBy = actions.payload.createdBy;
      state.horoscopeImage = actions.payload.horoscopeImage;
      state.govtId = actions.payload.govtId ? actions.payload.govtId : "";
      state.lookingFor = actions.payload.lookingFor;
      state.ownHouse = actions.payload.ownHouse;
      state.startDate = actions.payload.startDate;
      state.eatingHabits = actions.payload.eatingHabits;
      state.smookingHabits = actions.payload.smookingHabits;
      state.drinkingHabits = actions.payload.drinkingHabits;
    },
    setVerificationDetails(state, action) {
      state.verificationDetails = action.payload;
    },
    setIsFormChanged(state, action) {
      state.isFormChanged = action.payload;
    },
  },
});

export { actions };
export default reducer;
