import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { actions } from "../../store/slice";
import { useNavigate } from "react-router-dom";
import { KW_LOGO } from "../../constant";

const LayoutPage = ({ content = <></>, title = "" }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(actions.setIsHeaderFooter(false));
  }, [dispatch]);

  return (
    <div className="login-page err-500">
      <div className="container">
        <div className="login-container">
          <div className="row">
            <div className="col-lg-4 col-md-5 col-sm-9 lfh">
              <div className="card login-box">
                <div className="card-body">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginBottom: "10px",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <img
                      onClick={() => navigate("/")}
                      src={KW_LOGO}
                      alt="kalyanawaran"
                      width={225}
                    />
                    <br />
                    <h5 className="card-title">{title}</h5>
                  </div>

                  {content}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LayoutPage;
