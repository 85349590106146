import { createSlice } from "@reduxjs/toolkit";
import initialState from "./initial-state";

const { actions, reducer } = createSlice({
  name: "searchProfile",
  initialState,
  reducers: {
    setCounterIncrement(state) {
      state.counter += 1;
    },
    setCounterDecrement(state) {
      state.counter -= 1;
    },
    setAllProfiles(state, actions) {
      state.profiles = actions.payload;
    },
    setTotalPage(state, actions) {
      state.pagination.totalPage = actions.payload;
    },
    setCurrentPage(state, actions) {
      state.pagination.currentPage = actions.payload;
    },
    setSearch(state, actions) {
      state.search = actions.payload;
    },
    setSort(state, actions) {
      state.sort = actions.payload;
    },
    setFilter(state, actions) {
      state.filter = actions.payload;
    },
    setSelectedRasi(state, actions) {
      state.filter.rasi = actions.payload;
    },
    setProfileAvalability(state, actions) {
      state.filter.profilesAvalabilty = actions.payload;
    }
  },
});

export { actions };
export default reducer;
