import { ICONS } from "../../../constant";

const initialState = {
  counter: 0,
  features: [
    {
      image: ICONS.register,
      alt: "",
      title: "Register Now",
      desc: "Post your account creation and membership fee, your profile will be created automatically and you'll be given an member ID for future references and login to our portal.",
      readMore: "",
      onClick: "",
    },
    {
      image: ICONS.createProfile,
      alt: "create-an-profile",
      title: "My Profile",
      desc: "Create your matrimonial profile with your recent photo. Tell us about your partner preferences. We'll quickly match you with the right profiles which best suits your criteria.",
      readMore: "",
      onClick: "/profileedit",
    },
    {
      image: ICONS.searchProfile,
      alt: "",
      title: "Search Profile",
      desc: "Set your partner preference and we'll do the rest. You'll be immediately able to view prospective and authentic profiles which matches exactly. Apart from this, You can aso perform custom search. Coming soon ...!",
      readMore: "",
      onClick: "/search-profiles",
    },
  ],
  brideProfiles: [
    {
      profileImage: "https://picsum.photos/200",
      intro:
        "Lorem ipsum dolor sit amet, tincidunt vestibulum. Fusce egeabus consectetuer turpis, suspendisse.",
      name: "User 1",
      profileRasi: "Mesham",
      age: 22,
    },
    {
      profileImage: "https://picsum.photos/200",
      intro:
        "Lorem ipsum dolor sit amet, tincidunt vestibulum. Fusce egeabus consectetuer turpis, suspendisse.",
      name: "User 2",
      profileRasi: "Mesham",
      age: 24,
    },
    {
      profileImage: "https://picsum.photos/200",
      intro:
        "Lorem ipsum dolor sit amet, tincidunt vestibulum. Fusce egeabus consectetuer turpis, suspendisse.",
      name: "User 3",
      profileRasi: "Mesham",
      age: 26,
    },
    {
      profileImage: "https://picsum.photos/200",
      intro:
        "Lorem ipsum dolor sit amet, tincidunt vestibulum. Fusce egeabus consectetuer turpis, suspendisse.",
      name: "User 4",
      profileRasi: "Mesham",
      age: 28,
    },
    {
      profileImage: "https://picsum.photos/200",
      intro:
        "Lorem ipsum dolor sit amet, tincidunt vestibulum. Fusce egeabus consectetuer turpis, suspendisse.",
      name: "User 5",
      profileRasi: "Mesham",
      age: 28,
    },
  ],
  groomProfiles: [
    {
      profileImage: "https://picsum.photos/200",
      intro:
        "Lorem ipsum dolor sit amet, tincidunt vestibulum. Fusce egeabus consectetuer turpis, suspendisse.",
      name: "User 1",
      profileRasi: "Mesham",
      age: 22,
    },
    {
      profileImage: "https://picsum.photos/200",
      intro:
        "Lorem ipsum dolor sit amet, tincidunt vestibulum. Fusce egeabus consectetuer turpis, suspendisse.",
      name: "User 2",
      profileRasi: "Mesham",
      age: 24,
    },
    {
      profileImage: "https://picsum.photos/200",
      intro:
        "Lorem ipsum dolor sit amet, tincidunt vestibulum. Fusce egeabus consectetuer turpis, suspendisse.",
      name: "User 3",
      profileRasi: "Mesham",
      age: 26,
    },
    {
      profileImage: "https://picsum.photos/200",
      intro:
        "Lorem ipsum dolor sit amet, tincidunt vestibulum. Fusce egeabus consectetuer turpis, suspendisse.",
      name: "User 4",
      profileRasi: "Mesham",
      age: 28,
    },
    {
      profileImage: "https://picsum.photos/200",
      intro:
        "Lorem ipsum dolor sit amet, tincidunt vestibulum. Fusce egeabus consectetuer turpis, suspendisse.",
      name: "User 5",
      profileRasi: "Mesham",
      age: 28,
    },
  ],
};

export default initialState;
