import Box from "@mui/material/Box";
import React from "react";
import { useSelector } from "react-redux";
import LableWithFormField from "../../../components/label-with-formfield";
import * as config from "../../../config/config";
import { getLang } from "../../../store/selector";
// import { actions } from "../store/slice";

const Professional = () => {
  // const dispatch = useDispatch();
  const lang = useSelector(getLang);
  console.log("🚀 ~ Professional ~ lang:", lang);
  let education, employedIn, occupation, annualIncome;

  const professionalFields = [
    {
      type: "select",
      text: "Preferred Education",
      id: "education",
      defaultValue: education,
      placeholder: "20",
      // onChange: (e) =>
      //   dispatch(
      //     actions.setBirthDetails({
      //       ...birthDetails,
      //       dob: { ...dob, placeOfBirth: e.target.value },
      //     })
      //   ),
      array: config.fieldOfStudy,
    },
    {
      type: "select",
      text: "Employed In",
      id: "employedIn",
      defaultValue: employedIn,
      placeholder: "20",
      // onChange: (e) =>
      //   dispatch(
      //     actions.setBirthDetails({
      //       ...birthDetails,
      //       dob: { ...dob, placeOfBirth: e.target.value },
      //     })
      //   ),
      array: config.employedIn,
    },
    {
      type: "select",
      text: "Occupation",
      id: "occupation",
      defaultValue: occupation,
      placeholder: "20",
      // onChange: (e) =>
      //   dispatch(
      //     actions.setBirthDetails({
      //       ...birthDetails,
      //       dob: { ...dob, placeOfBirth: e.target.value },
      //     })
      //   ),
      array: config.occupation,
    },
    {
      type: "select",
      text: "Preferred Annual Income",
      id: "annualIncome",
      defaultValue: annualIncome,
      placeholder: "20",
      // onChange: (e) =>
      //   dispatch(
      //     actions.setBirthDetails({
      //       ...birthDetails,
      //       dob: { ...dob, placeOfBirth: e.target.value },
      //     })
      //   ),
      array: config.annualIncome,
    },
  ];

  return (
    <Box
      sx={{
        flexGrow: 1,
        padding: "25px",
      }}
    >
      <div className="table-responsive">
        <table className="table">
          <tbody>
            {professionalFields.map((props, index) => (
              <LableWithFormField key={index} {...props} />
            ))}
          </tbody>
        </table>
      </div>
    </Box>
  );
};

export default Professional;
