import Box from "@mui/material/Box";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import LableWithFormField from "../../../components/label-with-formfield/lable-with-formfield";
import { getContactNumber } from "../store/selector";
import { actions } from "../store/slice";

const ContactDetails = () => {
  const contactDetails = useSelector(getContactNumber);
  const { emailId, contactNumber, whatsappNumber, alternativeContactNumber } =
    contactDetails;
  const dispatch = useDispatch();
  const handleChange = (e, fieldName, fieldValue) => {
    dispatch(actions.setIsFormChanged(true));
    if (fieldName && fieldValue) {
      dispatch(
        actions.setContactDetails({
          ...contactDetails,
          [fieldName]: fieldValue,
        })
      );
    } else {
      const { name, value } = e?.target;
      dispatch(
        actions.setContactDetails({
          ...contactDetails,
          [name]: value,
        })
      );
    }
  };

  const contactDetailsFields = [
    {
      type: "phone",
      text: "Whatsapp Number",
      id: "whatsappNumber",
      name: "whatsappNumber",
      defaultValue: whatsappNumber,
      onChange: (whatsappNumber) =>
        handleChange("", "whatsappNumber", whatsappNumber),
    },
    {
      type: "phone",
      text: "Contact Number",
      id: "contactNumber",
      name: "contactNumber",
      defaultValue: contactNumber,
      onChange: (contactNumber) =>
        handleChange("", "contactNumber", contactNumber),
    },
    {
      type: "phone",
      text: "Alternative Contact Number",
      id: "alternativeContactNumber",
      name: "alternativeContactNumber",
      defaultValue: alternativeContactNumber,
      onChange: (alternativeContactNumber) =>
        handleChange("", "alternativeContactNumber", alternativeContactNumber),
    },
    {
      type: "text",
      text: "Email Id",
      id: "emailId",
      name: "emailId",
      placeholder: "example@.com",
      defaultValue: emailId,
      onChange: (e) => handleChange(e),
    },
  ];

  return (
    <Box
      sx={{
        flexGrow: 1,
        padding: "25px",
      }}
    >
      <div className="table-responsive">
        <table className="table">
          <tbody>
            {contactDetailsFields.map((props, index) => (
              <LableWithFormField key={index} {...props} />
            ))}
          </tbody>
        </table>
      </div>
    </Box>
  );
};

export default ContactDetails;
