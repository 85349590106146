import { faAngleDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Slider } from "@mui/material";
import Grid from "@mui/material/Grid";
import React, { useEffect, useState } from "react";
import ReactGA from "react-ga";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import PaginationComponent from "../../../components/pagination";
import ProfileCard from "../../../components/profile-card";
import * as config from "../../../config/config";
import useMediaQuery from "../../../hooks/use-media-query";
import { decrementCount } from "../../../store/services";
import { actions as counterActions } from "../../../store/slice";
import { errorToaster } from "../../../utils/alerts";
import { getItem } from "../../../utils/local-storage";
import { fetchAllProfileByFilter } from "../../profile/store/services";
import {
  getFilter,
  getPagination,
  getProfiles,
  getSearch,
  getSort,
} from "../store/selector";
import { actions } from "../store/slice";

const ProfilesPage = () => {
  const location = useLocation();
  const { isDesktop } = useMediaQuery();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const profile = useSelector(getProfiles);
  const [selectedRasis, setSelectedRasis] = useState([]);
  const [age, setAge] = useState([18, 30]);
  const [rasicollapse, setRasiCollapse] = useState(false);
  const filter = useSelector(getFilter);
  const sort = useSelector(getSort);
  const search = useSelector(getSearch);
  const pagination = useSelector(getPagination);
  const { currentPage } = pagination;
  const { minAge, maxAge, profilesAvalabilty, rasi } = filter;

  const rasis = Array.from(new Set(config.rasi.map((rasi) => rasi.value)));

  const handleRasiSelected = (rasi) => {
    const updateRasi = selectedRasis.includes(rasi)
      ? selectedRasis.filter((selectedRasis) => selectedRasis !== rasi)
      : [...selectedRasis, rasi];

    setSelectedRasis(updateRasi);
    dispatch(actions.setSelectedRasi(updateRasi));
  };
  useEffect(() => {
    fetchAllProfileByFilter(
      getItem("the_main_app"),
      search,
      minAge,
      maxAge,
      rasi,
      sort,
      currentPage,
      profilesAvalabilty
    )
      .then((response) => {
        dispatch(actions.setAllProfiles(response.data.values));
        dispatch(actions.setTotalPage(response.data.total));
        dispatch(actions.setCurrentPage(response.data.page));
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  const handleFetchAPI = () => {
    fetchAllProfileByFilter(
      getItem("the_main_app"),
      search,
      minAge,
      maxAge,
      rasi,
      sort,
      currentPage,
      profilesAvalabilty
    )
      .then((response) => {
        dispatch(actions.setAllProfiles(response.data.values));
        dispatch(actions.setTotalPage(response.data.total));
        dispatch(actions.setCurrentPage(response.data.page));
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    !getItem("the_main_app") && navigate("/login");
  });
  const onClickReadMore = (props) => {
    decrementCount(getItem("the_main_app"), props.userId)
      .then((res) => {
        if (res.data.success) {
          dispatch(counterActions.setCount(res.data.count));
          navigate(`/members/${props.userId}`);
        } else {
          errorToaster(res.message);
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);

  const handleSortOption = (e) => {
    dispatch(actions.setSort(e.target.value));
  };

  return (
    <div
      className="lime-container"
      // style={{ marginTop: 72 }}
    >
      <div className="container-fluid">
        <div className="divider"></div>
        <div className="row">
          <div className="col-lg-3">
            <div className="card">
              <div className="card-body">
                <div className="p-3">
                  <h4>Filter</h4>
                  <div className=" px-3 py-2">
                    <div className="border-2 border-bottom py-3">
                      <h5>Age Limit </h5>
                      <div className="  fs-6">
                        Min : {age[0]} - Max : {age[1]}
                      </div>
                      <Slider
                        size="meidum"
                        aria-label="small"
                        valueLabelDisplay="auto"
                        value={age}
                        onChange={(e) => setAge(e.target.value)}
                        min={18}
                        max={75}
                      />
                    </div>
                    <div className="border-2 border-bottom py-3">
                      <div className=" d-flex align-items-center justify-content-between">
                        <h5>Rasi</h5>
                        <div
                          style={{ cursor: "pointer" }}
                          className=" p-2"
                          onClick={() => setRasiCollapse(!rasicollapse)}
                        >
                          {rasicollapse ? (
                            <FontAwesomeIcon icon={faChevronUp} />
                          ) : (
                            <FontAwesomeIcon icon={faAngleDown} />
                          )}
                        </div>
                      </div>
                      <div
                        className=" py-2"
                        style={{
                          maxHeight: rasicollapse ? "max-content" : "150px",
                          overflow: "hidden",
                        }}
                      >
                        {rasis.map((rasi) => (
                          <div className="py-2" key={rasi}>
                            <input
                              type="checkbox"
                              value={rasi}
                              checked={selectedRasis.includes(rasi)}
                              onChange={() => handleRasiSelected(rasi)}
                            />
                            <label className=" px-2" key={rasi}>
                              {rasi}
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className=" d-flex align-items-center  py-3">
                      <input
                        type="checkbox"
                        checked={profilesAvalabilty}
                        onChange={() =>
                          dispatch(
                            actions.setProfileAvalability(!profilesAvalabilty)
                          )
                        }
                      />
                      <div className=" fs-6  px-2">Picture Availablity</div>
                    </div>
                  </div>
                  <div className=" d-flex align-items-center justify-content-center">
                    <button
                      className=" w-75 btn btn-primary  my-3 fs-5 font-poppins"
                      onClick={handleFetchAPI}
                    >
                      Filter
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-9">
            <div className="card">
              <div className="card-body">
                <div className="mail-container">
                  <div className="mail-header">
                    <div className=" d-flex">
                      <div className="col-lg-6"></div>
                      <div
                        className="col-lg-6"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <select
                          className=" form-control custom-select selectbar"
                          onChange={(e) => handleSortOption(e)}
                        >
                          <option value="name">Name (a-z)</option>
                          <option value="-name">Name (z-a)</option>
                          <option value="-age">Age (9-0)</option>
                          <option value="age">Age (0-9)</option>
                          <option value="-createdAt">Oldest Profiles</option>
                          <option value="createdAt">Newest Profiles</option>
                        </select>
                        <button
                          className=" mx-2 px-4  btn btn-primary"
                          onClick={handleFetchAPI}
                        >
                          Sort
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="divider"></div>
                  <div className="mail-text">
                    <div
                      style={{ minHeight: "80vh" }}
                      className=" w-100 d-flex flex-column align-items-center justify-content-between align-content-center"
                    >
                      {profile?.length > 0 ? (
                        <Grid
                          container
                          style={{
                            padding: "50px",
                            minWidth: "100%",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          {profile.map((props, index) => (
                            <Grid
                              id="profile-card-grid"
                              item
                              key={index}
                              style={{ padding: "10px" }}
                            >
                              <ProfileCard
                                {...props}
                                index={index}
                                onClickReadMore={() => onClickReadMore(props)}
                              />
                            </Grid>
                          ))}
                        </Grid>
                      ) : (
                        <Grid
                          container
                          style={{
                            padding: "50px",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <Grid item xs={12} className="gridItem">
                            <h1
                              className="font-weight-semibold banner-content-text mb-1"
                              style={{
                                fontFamily: "Righteous",
                                fontSize: "48px",
                              }}
                            >
                              No Profiles Available for Preview
                            </h1>
                          </Grid>
                        </Grid>
                      )}
                      <PaginationComponent />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfilesPage;
