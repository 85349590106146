import React from "react";
import { Helmet } from "react-helmet";

const SEO = ({ title, link, imageUrl }) => {
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{title}</title>
      <link rel="canonical" href={link} />
      <meta name="author" content="kalyanawaran - A Matrimony website." />
      <meta name="image" content={imageUrl} />
    </Helmet>
  );
};

export default SEO;
