import React, { useEffect, useState } from "react";
import ReactGA from "react-ga";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import useMediaQuery from "../../../hooks/use-media-query";
import { getCopyLink, getUser } from "../../../store/selector";
import { actions } from "../../../store/slice";
import { successToaster } from "../../../utils/alerts";
import { getItem } from "../../../utils/local-storage";
import { getFeatures } from "../store/selector";

const LandingPage = () => {
  const location = useLocation();
  const features = useSelector(getFeatures);
  const dispatch = useDispatch();
  const { token } = useSelector(getUser);
  const [data, setData] = useState({});
  const copyLink = useSelector(getCopyLink);

  // const groomProfiles = useSelector(getGroomProfiles);
  // const brideProfiles = useSelector(getBrideProfiles);
  const navigate = useNavigate();
  const { isDesktop } = useMediaQuery();

  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
    dispatch(actions.setIsHeaderFooter(true));
  }, [location, dispatch]);

  useEffect(() => {
    if (!getItem("the_main_app")) {
      setData({
        title: "Register in Kalyanawaran",
        subTitle: "Get Married Soon...",
        buttonText: "Register Now",
        onClick: () => navigate("/register"),
      });
    } else {
      setData({
        title: " Share your profile to your Family",
        subTitle: "",
        buttonText: "Share Now",
        onClick: () => {
          navigator.clipboard.writeText(copyLink);
          successToaster("Copied to clipboard");
        },
      });
    }
  }, [copyLink, navigate, token]);

  return (
    <div
      id="home"
      data-spy="scroll"
      data-target=".navbar"
      data-offset="100"
      // style={{ marginTop: isDesktop ? "72px" : "96px" }}
    >
      <div className="banner">
        <div className="banner-content">
          <h1
            className="font-weight-semibold banner-content-text mb-1"
            style={{
              // textTransform: "uppercase",
              fontFamily: "Righteous",
              fontSize: "48px",
            }}
          >
            A Matrimonial Service
          </h1>
          <br />
          <h6 className="font-weight-normal banner-content-text pb-3">
            Aims to provide a centralized platform where brides and grooms can
            meet and communicate with each other.
          </h6>
          {/* <div>
            <button className="btn btn-primary" style={{ marginRight: "4px" }}>
              Get started
            </button>
            <button className="btn btn-secondary ml-1">Learn more</button>
          </div> */}
        </div>
      </div>
      <div className="content-wrapper">
        <div className="container">
          <section className="features-overview" id="features-section">
            <div className="content-header">
              <h2>How does it works</h2>
              <h6 className="section-subtitle">Profile Search Process</h6>
            </div>
            <div
              className="d-md-flex justify-content-between"
              style={{ padding: "30px 0" }}
            >
              {features.map(
                ({ image, alt, title, desc, readMore, onClick }, index) => (
                  <div key={index} className="gridItem flex-col">
                    <img
                      src={image}
                      alt={alt}
                      className="img-icons"
                      onClick={() => navigate(onClick)}
                    />
                    <h5 className="py-3">{title}</h5>
                    <p className="text-muted para-align">{desc}</p>
                    {readMore !== "" && (
                      <a href={() => false}>
                        <p className="readmore-link">Readmore</p>
                      </a>
                    )}
                  </div>
                )
              )}
            </div>
          </section>
          {/* Groom */}
          {/* <section className="customer-feedback" id="feedback-section">
            <div className="row">
              <div className="col-12 text-center pb-5">
                <h2>Groom Profiles</h2>
                <h6 className="section-subtitle m-0">FEATURED</h6>
              </div>
              <div className="owl-carousel owl-theme grid-margin">
                {groomProfiles.map((props, index) => (
                  <ProfileCard {...props} key={index} index={index} />
                ))}
              </div>
            </div>
          </section> */}
          {/* Bride */}
          {/* <section className="customer-feedback" id="feedback-section">
            <div className="row">
              <div className="col-12 text-center pb-5">
                <h2>Bride Profiles</h2>
                <h6 className="section-subtitle m-0">FEATURED</h6>
              </div>
              <div className="owl-carousel owl-theme grid-margin">
                {brideProfiles.map((props, index) => (
                  <ProfileCard {...props} key={index} index={index} />
                ))}
              </div>
            </div>
          </section> */}
          <section className="contact-us" id="contact-section">
            <div
              className="contact-us-bgimage gridItem"
              style={{ padding: "80px", flexDirection: "column" }}
            >
              <div className="pb-4">
                <h4 className="px-3 px-md-0 m-0" data-aos="fade-down">
                  {data.title}
                </h4>
                <h4 className="pt-1" data-aos="fade-down">
                  {data.subTitle}
                </h4>
              </div>
              <div data-aos="fade-up">
                <button
                  className="btn btn-rounded btn-outline-danger"
                  onClick={data.onClick}
                >
                  {data.buttonText}
                </button>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};
export default LandingPage;
