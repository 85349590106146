import Box from "@mui/material/Box";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import LableWithFormField from "../../../components/label-with-formfield";
import * as config from "../../../config/config";
import { IMAGE_FORMAT } from "../../../constant";
import useFileUpload from "../../../hooks/use-file-upload";
import { getPersonalInfo } from "../store/selector";
import { actions } from "../store/slice";

const PersonalInfo = () => {
  // const lang = useSelector(getLang);
  const personalInfo = useSelector(getPersonalInfo);
  const dispatch = useDispatch();
  const { fileUpload } = useFileUpload();
  const successMessage = "Image Uploaded Successfully!!!";

  const {
    name,
    iAm,
    aboutMe,
    bloodGroup,
    complextion,
    maritalStatus,
    weight,
    height,
    profileImage,
  } = personalInfo;

  const handleChange = (e) => {
    const { name, value } = e.target;
    dispatch(actions.setIsFormChanged(true));
    dispatch(
      actions.setPersonalInfo({
        ...personalInfo,
        [name]: value,
      })
    );
  }; //onChange: handleChange}

  const personalInfoFields = [
    {
      type: "text",
      text: "Name",
      id: "name",
      name: "name",
      defaultValue: name,
      placeholder: "Muruga",
      onChange: (e) => handleChange(e),
    },
    {
      type: "text",
      text: "About Me",
      id: "aboutMe",
      name: "aboutMe",
      defaultValue: aboutMe,
      placeholder: "Lorem Ipsum Lorem Ipsum Lorem Ipsum",
      onChange: (e) => handleChange(e),
    },
    {
      type: "select",
      text: "I am",
      id: "iAm",
      name: "iAm",
      value: iAm,
      placeholder: "Groom",
      onChange: (e) => handleChange(e),
      array: config.iAm,
    },
    {
      type: "select",
      text: "Height (in feet)*",
      id: "height",
      name: "height",
      value: height,
      placeholder: "5.1",
      onChange: (e) => handleChange(e),
      array: config.height,
    },
    {
      type: "text",
      text: "Weight (in Kg)*",
      id: "weight",
      name: "weight",
      defaultValue: weight,
      placeholder: "65",
      onChange: (e) => handleChange(e),
    },
    {
      type: "select",
      text: "Blood Group",
      id: "bloodGroup",
      name: "bloodGroup",
      value: bloodGroup,
      placeholder: "A+ ve",
      onChange: (e) => handleChange(e),
      array: config.bloodGroup,
    },
    {
      type: "text",
      text: "Complextion",
      id: "complextion",
      name: "complextion",
      defaultValue: complextion,
      placeholder: "Whitish",
      onChange: (e) => handleChange(e),
    },
    {
      type: "select",
      text: "Marital Status",
      id: "maritalStatus",
      name: "maritalStatus",
      value: maritalStatus,
      placeholder: "A+ ve",
      onChange: (e) => handleChange(e),
      array: config.maritalStatus,
    },
  ];

  return (
    <Box
      sx={{
        flexGrow: 1,
        padding: "25px",
      }}
    >
      <div className="table-responsive">
        <table className="table">
          <tbody>
            <tr>
              <td colSpan={2} style={{ borderTop: "none" }}>
                <div
                  className="media"
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <img
                    src={profileImage || "https://picsum.photos/id/360/200/300"}
                    className="profile-img w mr-3"
                    alt="profile-pic"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td colSpan={2} style={{ borderTop: "none" }}>
                <div className="custom-file">
                  <input
                    type="file"
                    className="custom-file-input form-control"
                    id="customFile"
                    onChange={(e) =>
                      fileUpload(e, successMessage, "personalInfo")
                    }
                    accept={IMAGE_FORMAT}
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td colSpan={2}>
                <p>
                  <small>
                    <b>Note:</b> File size should be less than or equal to 2 MB.
                  </small>
                  <small> Allowed File types are JPEG, PNG, GIF</small>
                </p>
              </td>
            </tr>
            {personalInfoFields.map((props, index) => (
              <LableWithFormField key={index} {...props} />
            ))}
          </tbody>
        </table>
      </div>
    </Box>
  );
};

export default PersonalInfo;
