import axios from "axios";
import {
  PROFILE_FETCH,
  SEARCH_FETCH,
  PROFILE_EDIT,
  FETCH_BY_SEARCH,
} from "../../../config/api-const";

export const fetchProfile = async (token) =>
  token && (await axios.get(PROFILE_FETCH + token));

export const saveProfile = async (token, profile) => {
  const profileObj = profile;

  try {
    return await axios.post(PROFILE_EDIT, {
      profileObj,
      token,
    });
  } catch (err) {
    console.error("Opps, Error in Updating. Try after sometime later \n", err);
  }
};

export const fetchAllProfile = async (token, currentPage) => {
  try {
    return await axios.get(`${SEARCH_FETCH}?token=${token}&page=${currentPage}`);
  } catch (err) {
    console.error(
      "Opps, Error in fetchAllProfile. Try after sometime later \n",
      err
    );
  }
};
export const fetchAllProfileByFilter = async (token, search, minAge, maxAge, selectedRasis, sort, currentPage, profilesAvalabilty) => {
  try {
    return await axios.get(`${SEARCH_FETCH}?token=${token}&search=${search}&minAge=${minAge}&maxAge=${maxAge}&rasi=${selectedRasis}&sort=${sort}&page=${currentPage}&profilesAvalabilty=${profilesAvalabilty}`);
  } catch (err) {
    console.error(
      "Opps, Error in fetchAllProfile. Try after sometime later \n",
      err
    );
  }
};

export const fetchAllProfileBySearch = async (token, search) => {
  try {
    return await axios.get(`${FETCH_BY_SEARCH}?token=${token}&search=${search}`);
  } catch (err) {
    console.error(
      "Opps, Error in fetchAllProfile. Try after sometime later \n",
      err
    );
  }
};