import React, { useEffect } from "react";
import ReactGA from "react-ga";
import { useLocation } from "react-router-dom";
import { officeContactDetails } from "../../config/config";
import { COMPANYNAME, COMPANYSITE } from "../../constant/termConst";

const Terms = () => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);

  return (
    <div style={{ padding: 50 }}>
      <div>
        <h3 className="font-bold">
          TERMS AND CONDITIONS OF MEMBERSHIP IN {COMPANYNAME}
        </h3>
        {/* <h4>Date:</h4> */}
      </div>
      <p>Welcome to {COMPANYSITE} (herein referred as KM).</p>
      <p>
        This document is an electronic record in terms of the Information
        Technology Act, 2000 and rules there under pertaining to electronic
        records as applicable and amended. This electronic record is generated
        by a computer system and does not require any physical or digital
        signatures.
      </p>
      <p>
        This document is published in accordance with the provisions of Rule 3
        (1) of the Information Technology (Intermediaries Guidelines and Digital
        Media Ethics Code) Rules, 2021 that require publishing the rules and
        regulations, privacy policy and the terms and conditions for access or{" "}
        {COMPANYSITE}
        (the "Website").
      </p>
      <p>
        <span>
          PLEASE READ THE FOLLOWING TERMS AND CONDITIONS VERY CAREFULLY BEFORE
          USING THE WEBSITE. ACCESSING, BROWSING OR OTHERWISE USING THE WEBSITE
          IMPLIES YOUR AGREEMENT TO BE BOUND BY ALL THESE TERMS AND CONDITIONS
        </span>{" "}
        ("Agreement"). If you do not want to be bound by the Terms and
        Conditions, you must not use the Website or Matrimony.com Ltd Services.
        The Terms and Conditions also includes the applicable policies which are
        incorporated herein by way of reference and as amended from time to time
        (the "Terms and conditions").
      </p>
      <p>
        In these Terms, references to "KM member" shall mean the end user
        accessing the Website/KM services, its contents or using the KM Services
        offered. KM member also includes persons such as father, mother,
        brother, sister, relative or a friend ("Registrant") of the prospective
        bride/ bridegroom. The terms "You" and "User" shall be interchangeably
        used for "KM member". "KM Services" means a collective reference to the
        {COMPANYNAME} Matrimony service provided by to the {COMPANYNAME}
        Matrimony service provided by Matrimony.com Limited,a company under the
        provisions of companies act, 2013 and having its registered office at
        No.515, Sallivan Street Coimbatore - 641 001.
        {COMPANYNAME} Matrimony service provided by
      </p>
      <p>
        {officeContactDetails[0].emailId}
        <br></br>
        {officeContactDetails[0].phone}
        <br></br>
        {officeContactDetails[0].addressFirstLine}
        <br></br>
        {officeContactDetails[0].city} - {officeContactDetails[0].pinCode}.
      </p>
      <h4>Scope</h4>
      <p>
        You understand and acknowledge that {COMPANYNAME} Matrimony (KM) acts as
        an "Intermediary" as defined under clause (1) sub-clause (w) of Section
        2 of the Information Technology Act, 2000. KM is a brand owned by
        Matrimony.Com Limited (MCL) which owns, retains and has complete rights
        in KM and the KM Website /App/ KM Service.
      </p>
      <p>
        KM acts as a platform to enable any user to themselves register on it
        (by filling the mandatory fields and optional fields, if any) to
        voluntarily search for profile(s) from the database of KM's already
        registered users, for seeking prospective lawful matrimonial alliances
        for themselves. KM retail store may also be able to assist you to create
        your profile, however, you must have a valid/operational mobile phone
        number and an email id. The profiles in the database of KM are
        classified broadly on the basis of language and region for the ease and
        convenience of its member / customer. KM Members are provided with
        free/paid access for searching profiles from the database of KM, as per
        the partner preference set by you (on the Website/Applications -(App)
        and you can shortlist the profiles in which you are interested.
      </p>
      <h4>1. Eligibility :</h4>
      <p>A) KM Membership and rights of admission is reserved solely for :</p>
      <div>
        <p>II. Persons of Indian Origin (PIO).</p>
        <p>III. Non Resident Indians (NRI).</p>
        <p>IV. Persons of Indian Descent or Indian Heritage</p>
        <p>V. Persons who intend to marry persons of Indian Origin.</p>
      </div>
      <p>B) Further in capacity as KM member you confirm that you are :</p>
      <p>
        I. 18 years or above (if you are a woman) or 21 years or above (if you
        are a man);
      </p>
      <p>
        II. If you have applied for Divorce, you may register on our website/App
        by stating "Awaiting Divorce".
      </p>
      <p>
        III. If you are a resident of any other Country, you are legally
        competent to marry as per the local rules applicable to your country and
        you shall comply with the Indian laws for marrying a person of Indian
        Origin
      </p>
      <p>
        C) In case you are a Registrant of the prospective bride/ bridegroom and
        has created profile in KM Website/App on behalf of them or is accessing
        the KM Website/App on behalf of them implies that you have taken their
        consent for their profile creation in KM and for accessing the KM
        Website/App.
      </p>
      <h4>2. Registration</h4>
      <p>
        A. We expect that you would complete the online registration process
        with fairness and honesty in furnishing true, accurate, current,
        complete information and with providing recent photos of you which will
        help you to meet your parameters .We expect you to read the relevant
        column before keying in the details or selecting the option available or
        uploading the photo. You are requested not to key in details of the
        profile in field other than the applicable field (including mentioning
        id's of other platforms/websites/App or repeating your details in
        another fields, after filling them once in the relevant fields or others
        photographs. In order to serve you better if KM requires additional
        details you agree to provide it. You may also give a missed call to any
        of our retail outlets phone number for us to call you back and assist
        you in getting your profile registered on our website/App. Further you
        may fill in your email id and phone number in any of our registration
        campaigns appearing in various websites as part of our advertisements,
        basis which you may receive call from our Customer Service Center and
        assist you in getting your profile registered on our website/App. KM may
        also reproduce your profile information in other matrimonial websites
        owned by MCL including community based websites to provide better
        matching Profiles to you.
      </p>
      <p>
        B. If at any point of time KM comes to know or is so informed by third
        party or has reasons to believe that any information provided by you for
        registration (including photos) or otherwise is found to be untrue,
        inaccurate, or incomplete, KM shall have full right to suspend or
        terminate (without any notice) your KM membership and forfeit any amount
        paid by you towards KM membership fee and refuse to provide KM service
        to you thereafter
      </p>
      <p>
        C. KM also reserves the right to block the registration of your profile
        on Website/App, if any, in the case of your contact details/links being
        entered in irrelevant fields or if there are errors in any data entered
        by the KM members in their profile.
      </p>
      <p>
        D. Registration of duplicate profiles of the same person is not allowed
        in KM Website/App. KM shall have full right to suspend or terminate
        (without any notice) such duplicate profile.
      </p>
      <p>
        E. You acknowledge and confirm that your registration with KM and the
        usage of KM services is with the bonafide intention of marriage and not
        otherwise. KM Membership is restricted strictly to the registered KM
        individual member only. Organizations, companies, businesses and/or
        individuals carrying on similar or competitive business cannot become
        Members of KM and nor use the KM Service or KM members data for any
        commercial purpose, and KM reserves its right to initiate appropriate
        legal action for breach of these obligation.
      </p>
      <h4>3. Account Security</h4>
      <p>
        You are responsible for safeguarding the confidentiality of your KM
        login credentials such as your user id, password, OTP, etc., and for
        restricting access to your computer/mobile to prevent unauthorized
        access to your account. We, as a Company do not ask for Password and you
        are cautioned not to share your password to any persons. You agree to
        accept responsibility for all activities that occur under your account.
      </p>
      <h4>4. Role and Responsibility of KM</h4>
      <p>
        A. KM reproduces your details once you register on our website /App on
        "as is as available" basis and also share your profile with other
        registered KM members within website(s) of MCL.
      </p>
      <p>
        B. KM's obligation is only to provide an interface to its registered
        members to search their prospect themselves without any assistance.
      </p>
      <p>
        C. The profile search conducted by any KM member and the matches shown
        thereof are automatically generated by KM, and based on the partner
        preference set by you. In the event of KM member changing their partner
        preference on the Website/App, then the automated system generated
        prospect results of the Website/App may also undergo corresponding
        change.
      </p>
      <p>
        D. KM does not prohibit any KM member from sending interest to your
        profile or communicating to you based on their partner preference. But
        you are at the liberty to deny their interest or proceed further if you
        are interested.
      </p>
      <p>
        E. KM cannot guarantee or assume responsibility for any specific results
        from the use of the data available from the KM service or from other
        matrimonial websites owned by MCL including community based websites.
      </p>
      <p>
        F. KM shall safeguard sensitive user information using security
        standards, authentication mechanisms, access controls and encryption
        techniques.
      </p>
      <p>
        G. KM cannot guarantee the complete protection of user data while it is
        in transit, or prevent any tampering of the data by a third party with
        malicious intent before the data reaches the KM servers.
      </p>
      <p>
        H. KM has a special safety feature for women, called "Secure Connect",
        which enables women to take control of their privacy and safety while
        searching for their life partner. "Secure Connect" has a calling feature
        that lets the women in KM receive calls from men who have premium
        membership without revealing their contact numbers to them, It is upto
        to the women to respond to the other party and KM has no control over
        the same.
      </p>
      <p>
        I. KM do not authenticate/endorse any information of any profile and
        hence you as a user need to verify the credentials and information
        provided by other users.
      </p>
      <h4>5. Role and Responsibility of KM Member</h4>
      <p>
        A. You, shall safeguard your profile information by creating a strong
        password during profile creation with combination of alphabets, both
        upper and lower case and numbers.
      </p>
      <p>
        B. Any information / data required by KM for using the KM services shall
        be provided by the KM Member, as and when so sought by KM.
      </p>
      <p>
        C. You are requested to verify the credentials of the prospect, exercise
        due care and caution regarding their profile information which includes
        marital status, educational qualifications, financial status,
        occupation, character, health status, etc. and satisfy yourself before
        making a choice of your match. KM shall not be liable for short coming
        due to any misrepresentations made by any of its KM members.
      </p>
      <p>
        D. You are required to verify the credentials of the prospect and we
        shall not be liable for any misbehavior/ misrepresentations made by the
        Prospect to you during video call. Please check "Safe Matrimony" link in
        the website/Apps for guidance.
      </p>
      <p>
        E. To get better search results, KM Members are expected to provide
        latest photograph which should not be more than 3 (three) months old.
        Providing old photographs/ photographs of others, inaccurate / false
        information shall be treated as violation of terms and conditions and KM
        shall retain their right under clause 2 (b) of this terms and
        conditions.
      </p>
      <p>
        F. KM members are expected to disclose their health records during
        profile enrollment which includes any pre-existing illness, physical
        disability etc. Non - disclosure at the time of enrollment shall be
        treated as violation of the terms and conditions and KM shall retain
        their right under clause 2 (b) of this terms and conditions.
      </p>
      <p>G. KM Members are advised to refrain from :</p>
      <p>
        i. Entering into any financial transactions with prospects. KM Members
        shall not seek financial help or provide financial help from / to the
        other KM Members
      </p>
      <p>
        ii. Using abusive language when they communicate with the other KM
        Members
      </p>
      <p>iii. being discriminative or using racial comments etc.</p>
      <p>
        iv. Sharing of confidential and personal data with each other but not
        limited to sharing of bank details, etc.
      </p>
      <p>
        v. Entering into physical relationship with any prospect before
        marriage.
      </p>
      <p>vi. violating any law for the time being in force.</p>
      <p>
        vii. From mentioning details of other matrimonial services while sending
        personalized messages
      </p>
      <p>
        H. KM members are expected to visit URL {COMPANYSITE}/safe-matrimony for
        understanding the safety measures on partner search and awareness
        towards online safety.
      </p>
      <p>
        I. KM Members are expected to be cautious of prospects who ask for
        favours, money etc or call from multiple phone numbers, only interact
        over phone, doesn't come forward for face to face meeting (physically or
        through video calls) and don't involve family and friends in match
        making. Beware of suspended profiles status before you finalize an
        alliance with the prospect.
      </p>
      <p>
        J. The KM members agrees that for getting effective search results of
        prospects on Website/App you will regularly log in to the profile
        maintained in the Website/App and send expression of interest which is
        an automated messages to prospects as a free KM member, and in the event
        you have availed paid KM package, you can send personalized messages to
        prospects to show your expression of interest/replies. KM member also
        may review the expression of interest sent to you / read the messages
        sent to you by prospects and may respond suitably.
      </p>
      <p>
        K. You also agree that while accessing / using the Website/App, you will
        be guided by the terms and conditions of the Website/App including but
        not limited to the use of feature of website /App like chat instantly
        with prospect, viewing horoscope of prospects, enhancing privacy
        settings (photo/horoscope/phone number) or for viewing social and
        professional profile of members on their Facebook, LinkedIn, etc.
      </p>
      <p>
        L. If the KM Member fails to update in KM website/App, any change in the
        particulars furnished in their profile/ then the KM membership may be
        suspended by KM at its sole discretion.
      </p>
      <p>
        M. KM member shall, in case of finalization of his/her/their
        Registrant's marriage, delete their profile by themselves or intimate KM
        for removal/deletion of their profile.
      </p>
      <p>
        N. KM Members agree to use secure devices, software and networks in a
        private place for accessing the KM services.
      </p>
      <p>
        O. KM member shall not use any kind of Bots like web robots, Chatbot or
        any other automated processes/programs/scripts to use, communicate or
        respond in KM Website/App.
      </p>
      <p>
        P. KM Members shall not probe, scan or test the vulnerabilities of the
        KM Website/App or any network connected to the Website/App nor breach
        the security measures instituted by KM.
      </p>
      <p>
        Q. You shall also not make any payment / transfer any amount to account
        of any MCL employees. All payments shall be made only to KM account.
      </p>
      <p>
        R. KM members are expected to approach MCL to resolve their complaints
        and grievances and shall not air their grievance/complaints in social
        media.
      </p>
      <h4>6. Customer Care / Customer Service</h4>
      <p>
        In the event you come across any concerns in our service, then please be
        free to contact us:
      </p>
      <p>contact Details</p>
      <p>
        {officeContactDetails[0].emailId}
        <br></br>
        {officeContactDetails[0].phone}
        <br></br>
        {officeContactDetails[0].addressFirstLine}
        <br></br>
        {officeContactDetails[0].city} - {officeContactDetails[0].pinCode}.
      </p>

      <h4>7. Medium of Communication to KM Members</h4>
      <p>
        A. KM member hereby consents for receiving communication including
        promotional communications through any medium of communications such as
        electronic mails, calls, SMS or through Whatsapp messages from MCL or
        other portals owned by MCL or their alliance partners.
      </p>
      <p>
        B. KM by its automatic system sends to its member (s) profile (s) of the
        prospect by way of images/ documents/ messages / links. KM member
        confirms that the mobile number submitted to it for verification or
        alternative number if any provided, is not registered with the Do Not
        Disturb / National Customer Preference Register and they shall not
        initiate any complaint. KM Member further confirms that even if KM
        Member is registered with the telecom service provider under the
        category Do Not Disturb / National Customer Preference Register the
        calls from KM either to the verified mobile number or alternative number
        if any provided shall not be treated as promotional calls.
      </p>
      <p>
        C. You are also entitled to set up your communications frequency from
        KM, like - only on weekends or fortnightly etc. You may also opt for not
        getting any call from KM by ticking the appropriate box in the
        Website/App, provided that, you agree to receive calls from Relationship
        Manager of Assisted Matrimony/ Elite Matrimony for contacting you as a
        shortlisted profile for their registered members.
      </p>
      <h4>8. Confidentiality</h4>
      <p>
        Any feedback you provide to KM shall be deemed to be non-confidential.
        KM shall be free to use such feedback/information on an unrestricted
        basis. Further, by submitting the feedback, you represent and warrant
        that (i) your feedback does not contain confidential or proprietary
        information of yourself or third parties; (ii) KM member is not under
        any obligation of confidentiality, express or implied, with respect to
        the feedback; (iii) you are not entitled to any compensation or
        reimbursement of any kind from KM for the feedback under any
        circumstances.
      </p>
      <h4>9. Privacy of Membership</h4>
      <p>
        To protect your privacy and understand our practices as amended from
        time to time , please read and follow our Privacy Policy which also
        governs your visit to KM and other websites owned by MCL, the personal
        information / data provided to us by you during the course of usage of
        KM will be treated as confidential and in accordance with the Privacy
        policy and applicable laws and regulations. If you object to your
        information being transferred or used, please do not use the website.
      </p>
      <h4>10. Grievance Cell</h4>
      <p>
        In the event you come across any violation by another user including but
        not limited to having content in the web site that is obscene, menacing,
        grossly offensive, harming minors, infringing copyright, patents, etc.,
        or another user is impersonating etc. you may then please be free to
        provide your concerns in writing or email us with digital signature to:
      </p>
      <p>
        {officeContactDetails[0].emailId}
        <br></br>
        {officeContactDetails[0].phone}
        <br></br>
        {officeContactDetails[0].addressFirstLine}
        <br></br>
        {officeContactDetails[0].city} - {officeContactDetails[0].pinCode}.
      </p>
      <p>
        The Grievance office shall be available between 10 am till 6 pm Indian
        Standard Time from Monday to Saturday excluding Sunday and public
        holidays in India. The Grievance officer is appointed as per Rule 3 (11)
        of The Information Technology (Intermediaries Guidelines) Rules, 2011.
        Any and all complaints to the Grievance Officer shall be in writing The
        Grievance Officer shall handle complaints in relation to the following
        violation by the User of computer resource as the User is not entitled
        to host, display, upload, modify, publish, transmit, store, update or
        share any information on the Website/App that -
      </p>
      <p>
        A. belongs to another person and to which the user does not have any
        right;
      </p>
      <p>
        B. is obscene, pornographic, paedophilic, invasive of another's privacy
        including bodily privacy, insulting or harassing on the basis of gender,
        racially or ethnically objectionable, relating or encouraging money
        laundering or gambling, or promoting enmity between different groups on
        the grounds of religion or caste with the intent to incite violence;
      </p>
      <p>C. is harmful to child;</p>
      <p>
        D. infringes any patent, trademark, copyright or other proprietary
        rights;
      </p>
      <p>
        E. deceives or misleads the addressee about the origin of the message or
        knowingly and intentionally communicates any misinformation or
        information which is patently false and untrue or misleading in nature;
      </p>
      <p>F. impersonates another person;</p>
      <p>
        G. threatens the unity, integrity, defence, security or sovereignty of
        India, friendly relations with foreign States, or public order, or
        causes incitement to the commission of any cognisable offence, or
        prevents investigation of any offence, or is insulting other nation;
      </p>
      <p>
        H. contains software virus or any other computer code, file or program
        designed to interrupt, destroy or limit the functionality of any
        computer resource; (ix) violates any law for the time being in force.
      </p>
      <h4>11. Disputes between Members</h4>
      <p>
        A. KM Members are solely responsible for the communications (through any
        medium) with prospect or vice versa. KM expressly disclaims any
        responsibility or liability for any monetary transaction(s) or
        exchange(s) or interaction(s) or passing of information(s) etc. between
        any KM members interest via e-mail, chat, interaction, Whatsapp or any
        other medium of communication between KM members either using website
        /App or otherwise.
      </p>
      <p>
        /App or otherwise. B. KM has no obligation, to monitor any such disputes
        arising between the KM members, and KM shall not be party to any such
        dispute/litigation etc.
      </p>
      <p>
        C. KM is not a broker or the agent of any KM member, and KM does not
        partake in the exchange of any kind of discussion between the KM members
        and prospects or the results of their discussion.
      </p>
      <h4>12. Content Right</h4>
      <p>
        A. You agree that all content of KM belong to MCL excluding your or
        third party content including advertisement on the Website/App for which
        KM has requisite license/right in terms hereof to display the same on
        our Website/App.
      </p>
      <p>
        B. You acknowledge that you alone are responsible and liable for the
        content (mandatory or optional content you provided on our website) and
        information including the profile details, photograph and other
        content(s) keyed in the relevant field of the Website/App at the time of
        the creation of the KM profile or subsequently modified by you and also
        for all the post and communication (including personalized messages sent
        by KM paid members) with other member(s). You hereby represent and
        warrant that you own all rights, title and interest in your
        content/information or have the necessary licenses, rights, consents,
        and permissions to provide the said content/information. However, by
        submitting the content/information to KM, you hereby grant KM a
        worldwide, non-exclusive, royalty-free, sub-licensable and transferable
        license to use, reproduce, distribute, prepare derivative works of,
        display, publish, communicate to the public, law enforcement agencies,
        courts, and the KM's business and services, including without limitation
        for promoting, communicating to the public and redistributing part or
        all of the content/information (and derivative works thereof) in any
        media formats and through any media channels.
      </p>
      <p>
        C. While KM does not accept any obligation to monitor the
        content/information in your profile, if the content/information is found
        not to be in compliance with these Terms and conditions, KM may delete
        the content/information and / or terminate or suspend your registration
        or (without a refund of any subscription paid by you in terms of clause
        2 (b) of these terms and conditions). You agree that if the situation
        warrants KM may at its sole discretion put restriction on any KM member
        to other member's communications.
      </p>
      <p>
        D. MCL reserves the right to proceed/initiate appropriate steps under
        the prevailing law against infringement by violators of the its
        proprietary rights or for illegally copying, modifying, publishing,
        transmitting, distributing, performing, displaying, or selling of any
        such proprietary information including using it for commercial purpose
        in any manner whatsoever.
      </p>
      <h4>13. Third Party website</h4>
      <p>
        A. KM member understand and agree that KM may allow third parties to put
        up advertisements on the KM application/Website and KM does not control
        the contents of such third party advertisement on the KM
        application/Website. Third party websites to which links are given on
        Website/App are not governed by these Terms and conditions and all such
        third party websites are governed by their own terms and conditions for
        use and privacy policy, and in case of conflict the terms and conditions
        of the third party website shall prevail. The Company does not in any
        manner whatsoever authenticate, endorse, verify or certify these third
        party websites or any contents therein. Please note that a user shall
        click on links to third party websites at his / her sole risk and
        responsibility.
      </p>
      <p>
        B. The KM members agrees and understands that KM may also allow third
        parties to provide KM members services on the website/App like horoscope
        matching etc., and KM may also collect the payment for such third party
        services to you, and KM in this regard can have revenue sharing
        arrangement with such third party, however in no event shall KM be
        liable to you for such third party services.
      </p>
      <p>
        C. KM reserves the right to add/delete/alter/modify/suspend any or all
        the KM Services at any time as it may deem fit, without notice. The KM
        members are expected to see the change on the Website/App.
      </p>
      <h4>14. Limitation of liability</h4>
      <p>
        A. MCL / KM or its Office bearers shall under no circumstances be liable
        or responsible to the KM member or his/her authorized Representative or
        Registrant or any third party for any direct, indirect, special,
        exemplary, incidental, or consequential damages of any character
        including, without limitation, damages resulting from the use of our
        Website/App/Third Party Website/ KM services.
      </p>
      <p>
        B. KM informs you that the exchange of profile(s) through or by KM
        should not in any way be construed as a matrimonial offer and/or
        recommendation and / or advice or guarantee given to the KM member,
        from/ or by KM.
      </p>
      <p>
        C. Notwithstanding anything to the contrary contained herein, KM's
        liability to you for any cause whatsoever, and regardless of the form of
        the action, will at all times be limited to the amount paid, if any, by
        you to KM, for any specific KM paid package, and no further.
      </p>
      <p>
        15. MCL / KM will not be liable in case of any wrong/improper match made
        due to unavailability of profiles from KM/MCL's owned other websites.
      </p>
      <p>
        16. MCL / KM or its office bearers shall under no circumstances be
        liable, if any, for the KM member entering into financial transaction
        with any other KM Member or any third party
      </p>
      <p>
        17. MCL / KM or its office bearers shall under no circumstances be
        liable, if any, for any KM members not responding/reciprocating when you
        approach them for matrimonial alliance
      </p>
      <h4>18. Class Action Suits</h4>
      <p>
        You acknowledge and confirm that you will not bring or participate in
        any class action or other class proceeding in connection with any
        dispute with KM. Further neither you nor KM agrees to class arbitration.
      </p>
      <h4>19. General</h4>
      <p>
        In the event you file a false complaint against another prospect on our
        Website /App and consequently we have suspended/deleted that prospects
        profile based on your complaint, then we reserve our right to initiate
        appropriate legal (Civil/Criminal) action against you and claim any and
        all costs expenses from you, for such
        irresponsible/misrepresentation/illegal/unlawful action. We also reserve
        our right to suspend your profile and forfeit any and all amounts paid
        by you for the KM services as per clause 2 (b) of these terms and
        conditions.
      </p>
      <p>
        20. Notwithstanding anything contained herein, KM reserves the absolute
        right to delete, in any manner as it deems fit, any content of any
        profile listing placed on KM/website/App (once such instance come to KM
        notice) in order to ensure, that proper consent has been obtained by you
        , prima facie accuracy and the prevailing laws in force for the time
        being, especially those relating to providing any obscene, libelous,
        blasphemous, slanderous, defamatory or invasive of another person's
        (deceased or alive) right of privacy or publicity, or that may
        reasonably be deemed to be harmful, vulgar, pornographic, abusive,
        harassing, threatening, hateful, objectionable with respect to race,
        religion, creed, nationality, gender or otherwise unfit for
        reproduction; or suggests or encourages unfair or illegal /indecent,
        unlawful activity. KM also reserves the right to block/ delete / suspend
        the profile which might be offensive, illegal or that might violate the
        rights, harm or threaten the safety of our office bearers/employees
        (including undue communication with any employee) and/or other
        registered prospects or using our KM website/App as a means of
        communication while sending messages to other KM members regarding their
        profile identity in other matrimonial websites.
      </p>
      <p>
        21. Once your paid membership expires, you cannot avail the unexpired
        balance phone call count/ unexpired SMS . Similarly, you cannot access
        the already viewed KM member(s) contact information unless you renew
        your account within 30 days
      </p>
      <p>
        22. However on renewal, the unexpired phone call / SMS shall be carried
        forward to your account from the last day of expiry.
      </p>
      <p>
        23. In case of conflict between the terms and condition of Website/App
        and terms and conditions of any other website including other websites
        of MCL, the terms and condition of KM Website/App shall prevail for the
        service provided through this Website/App.
      </p>
      <h4>24. Disclaimer</h4>
      <p>
        A. Your access to and use of the KM Services or any content is at your
        own risk. YOU UNDERSTAND AND AGREE THAT THE BMSERVICES ARE PROVIDED TO
        YOU ON AN "AS IS" AND "AS AVAILABLE" BASIS. WITHOUT LIMITING THE
        FOREGOING, TO THE FULL EXTENT PERMITTED BY LAW, BMDISCLAIMS ALL
        WARRANTIES, EXPRESS OR IMPLIED, OF MERCHANTABILITY, FITNESS FOR A
        PARTICULAR PURPOSE, OR NON-INFRINGEMENT. KM does not warrant that the
        Website/App, its servers, or e-mail sent from KM are free of viruses or
        other harmful components. KM will not be liable for any damages of any
        kind arising from the use of this KM Website/App, including, but not
        limited to direct, indirect, incidental, punitive, and consequential
        damages.
      </p>
      <p>
        B. KM does not give any implied or explicit guarantee or warranty of
        marriage or alliance by you choosing to register on our Website/App and
        using KM services (both paid and free).
      </p>
      <p>
        C. Notwithstanding anything contrary contained anywhere, under no
        circumstances, KM shall be held responsible or liable whatsoever or
        howsoever, arising out of, relating to or connected with:
      </p>
      <p>
        i. any act or omission done by KM/MCL/payment gateway/alliance partner
        etc.;
      </p>
      <p>
        ii. any untrue or incorrect information submitted by you or on your
        behalf;
      </p>
      <p>
        iii. any decision taken by you or on your behalf or any consequences
        thereof, based on any information provided by any other user (including
        suspension/deletion of the profile from KM);
      </p>
      <p>
        iv. any unauthorized or illegal act done by any third party relating to
        or connected with any information submitted by you or on your behalf;
      </p>
      <p>v. any cyber crime attempted or committed by anyone and</p>
      <p>vi. any incident of force-majeure or 'act of god'.</p>
      <p>
        vii. Any issue already stated in these terms and conditions including
        limitation of liability clause of these terms and conditions.
      </p>
      <p>
        viii. Using/availing of third party services namely horoscope matching
        services etc. and for paying them through the Website.
      </p>
      <p>
        ix. KM shall not be liable for the outcome of during any interaction in
        a meeting, call, sms, chat, email or social media posts at any point of
        time
      </p>
      <p>
        x. Any issues relating to any technical malfunction of any
        telecommunication network, software, hardware failures, network
        congestion, denial of service, failure due to spamming or any
        combination of the above.
      </p>
      <p>
        D. You expressly agree that your use of this Website/App is at your sole
        risk.
      </p>
      <p>
        E. We are not liable to you for any damage or alteration to your
        equipment including but not limited to computer equipment, hand-held
        device or mobile telephones as a result of the installation or use of
        the app. We are not liable to you for any damage or alteration to your
        equipment including but not limited to computer equipment, hand-held
        device or mobile telephones as a result of the installation or use of
        the app.
      </p>
      <h4>25. Indemnity</h4>
      <p>
        By using our KM services you agree to defend, indemnify, and hold
        harmless KM, its subsidiaries, affiliates, Directors, officers, agents,
        and other partners and employees, fully indemnified and harmless from
        any loss, damage, liability, claim, or demand, including reasonable
        attorney's fees, made by any person through improper use of the service
        provided by KM. This defence and indemnification obligation will survive
        in perpetuity
      </p>
      <h4>26. Termination</h4>
      <p>
        A. We/ KM in good faith reserve our right to terminate your KM
        membership or suspend/delete your profile at our sole discretion without
        any notice to you and take any action as mentioned in clause 2 (b) of
        the Terms and Conditions, in case you
      </p>
      <p>
        I. seek physical / financial favors from our other registered members or
      </p>
      <p>II. have provided incorrect information on our website/App or</p>
      <p>
        III. have committed any fraudulent/ unlawful/ illegal activities through
        the use of our website/App or
      </p>
      <p>IV. have violated this Terms and conditions or</p>
      <p>
        V. have misappropriated the name, likeness, email address, contact no or
        other personally identifiable information of another KM member created a
        profile on our website /App by impersonation/ fake/ bogus/ false/
        misrepresentation/ without consent of the person who's profile is being
        registered or use only part information including using photo of third
        parties without the permission of such third parties or act with other
        members/employees in indecent/improper manner,
      </p>
      <p>
        B. If KM receives any complaint against you and consequently we have
        suspended/deleted your profile in good faith, then we shall not be
        liable to you in any manner whatsoever, including for any loss, costs,
        expenses, or consequence, if any.
      </p>
      <p>
        C. Unless otherwise provided in these Terms and Conditions, KM
        membership may be terminated by KM / you without assigning any reasons
        by serving 7 ( seven) days written notice on the other, all amount paid
        till date by you to KM shall be forfeited.
      </p>
      <p>
        D. Upon termination / suspension of KM membership, your right to use or
        access the KM Website/App KM services will cease immediately. All
        provisions of this terms and conditions that by their nature should
        survive termination shall survive termination, including, without
        limitation, disclaimers, and limitations of liability. Termination of
        your access to and use of the KM Services shall not relieve you of any
        obligations arising or accruing prior to such termination.
      </p>
      <h4>27. Jurisdiction and Applicable Law</h4>
      <p>
        A. The registration of KM membership and all KM services are deemed to
        have been entered into within the territorial Jurisdiction of Chennai,
        India.
      </p>
      <p>
        B. The KM Members unconditionally agree that all such disputes and / or
        differences if any shall be governed by the Laws of India and submitting
        to the exclusive Jurisdiction of appropriate court of law in Chennai,
        Tamil Nadu, India
      </p>
      <h4>28. Mode of Payment</h4>
      <p>
        A. Payment made by cash / cheque / Net Banking / Debit / Credit Card/
        Paypal/ RTGS/ NEFT/ would be in accordance with the prevailing RBI
        guidelines. Activation of paid KM service shall be subject to
        realization of the said payment. In case of dishonor of Cheque/demand
        draft; you shall be liable to pay applicable bank's cheque/DD bouncing
        charges. Further KM service to your account will be deactivated /
        suspended until you settle the value of the payment and applicable bank
        charges in cash.
      </p>
      <p>
        B. KM uses third party payment gateways for collection payment through
        credit/debit card or other payment instruments. You agree to provide us
        with your Permanent Account Number / Form 60/61 of the Income Tax Act,
        1961 and rules thereunder in the event you choose any paid package of
        MCL, the value of such paid package being Rs.2,00,000/- [Rupees Two Lakh
        Only] and above from MCL.
      </p>
      <p>
        You agree to provide us with your Permanent Account Number / Form 60/61
        of the Income Tax Act, 1961 and rules thereunder in the event you choose
        any paid package of MCL, the value of such paid package being
        Rs.2,00,000/- [Rupees Two Lakh Only] and above from MCL.
      </p>
      <h4>29. Refund and Assignment</h4>
      <p>
        A. The payments made by any KM member to MCL by way of membership /
        renewal fee / auto renewal are treated as non-refundable.
      </p>
      <p>
        B. KM member shall not assign or transfer your KM membership to any
        other person or entity, and such an act shall be treated as violation of
        terms and conditions and KM shall retain their right under clause 2 (b)
        of this terms and conditions.
      </p>
      <p>
        C. Payment once made for KM services cannot be assigned to any
        person/party or adjusted towards any other product or packages provided
        by the Company.
      </p>
      <h4>30. Renewal</h4>
      <p>
        A. We automatically renew all paid Memberships before the expiry of the
        term of the paid Membership. You agree that your account will be subject
        to this automatic renewal feature
      </p>
      <p>
        B. When you first subscribe for your paid Membership, you acknowledge
        that when your paid Membership expires it will automatically renew for
        the duration and at the last paid price.
      </p>
      <p>
        C. The maximum tenure of any packages will be 12 (Twelve) month. Every
        KM packages post validity period (varies from package to package), shall
        be renewed.
      </p>
      <p>
        D. If you do not want your paid membership to automatically renew, you
        must cancel your paid membership. In case you have to mail for
        cancellation, mail to cancellation.bm@matrimony.com
      </p>
      <h4>
        31. Additional terms and conditions applicable to Non- Resident Indian
        customers.
      </h4>
      <p>A. Jurisdiction</p>
      <p>The laws of India shall be applicable.</p>
      <p>B. Arbitration of Disputes.</p>
      <p>
        The Parties shall discuss among themselves and resolve any disputes
        informally. We are interested in attempting to resolve any Disputes by
        amicable and informal means, and we encourage you to contact us before
        resorting to arbitration. If the dispute is not resolved within 30 (
        thirty ) days from the date of such dispute , then such dispute may only
        be resolved through an individual arbitration conducted in accordance
        with the provisions of The Arbitration and conciliation Act, 1996, by
        sole arbitrator. The seat of Arbitration shall be Chennai, Tamil Nadu,
        India. We shall appoint the sole arbitrator. The arbitration proceedings
        shall be conducted in the English language
      </p>
      <p>
        C. However, Disputes relating to infringement of our intellectual
        property rights, which we may elect to have resolved by means other than
        arbitration.
      </p>
      <p>
        D. In case of Renewal, Customer is charged the current price that
        prevails and renewed is done only for 3 months duration irrespective of
        the past package.
      </p>
      <p>E. Enforcement</p>
      <p>
        Any proceeding to enforce this arbitration agreement may be brought in
        Courts of Chennai, Tamil Nadu India.
      </p>
      <h4>32. Moneyback Guarantee</h4>
      <p>
        Within the first 90 (ninety) days of becoming a premium member, If you
        have sent a maximum of 10 (ten) messages to mutually matching profiles,
        and there has not been a single Acceptance from your contacted profiles,
        we would refund your premium amount paid within a period of 7(seven)
        working days from such intimation to us. The conditions for getting a
        refund also includes that your profile should be at least 95% complete
        and Messages sent by you should be given a minimum of 2(two) weeks
        window for getting a response.
      </p>
      <h4>Acceptance also includes</h4>
      <p>
        A.Users whose contact numbers are viewed by you for getting their
        acceptance over call/ personal message (or)
      </p>
      <p>
        B.Users who have expressed interest for your profile and you have
        responded positively (or)
      </p>
      <p>
        C.Users who have sent a message and you have accepted it or replied to
        it (or)
      </p>
      <p>
        D.Users who have accepted or replied to the message sent by you (or)
      </p>
      <p>E.Users who have viewed your contact number</p>
      <p>
        This Moneyback Guarantee is applicable for the purchase of
        Gold/Diamond/Platinum packs only.
      </p>
      <h4>33. Assisted Service Guarantee is applicable only for the users:</h4>
      <p>A.Who have availed the Assisted Service for the first time.</p>
      <p>
        B.Who have more than 100 matching profiles based on the partner
        preference set by them at the time of the Assisted Welcome Call.
      </p>
      <p>
        C.Who have not narrowed down the partner preference, once the Assisted
        service starts.
      </p>
      <h4>34. Membership Packages</h4>
      <p>
        A.We have various premium membership packages called Gold, Diamond,
        Platinum, Prime Gold, Prime Diamond, Prime Platinum and Assisted.
      </p>
      <p>
        B.You shall opt for any of our premium membership packages varying
        between three months to one year with differing price to access various
        premium features.
      </p>
      <p>
        C.Each premium package has certain limitations on count of Phone number
        view except for Diamond and Platinum package (3,6,12 months). The
        Diamond and Platinum package (3,6,12 months) has unlimited phone number
        view but within the Fair Usage Policy limit. Viewing an accepted
        member's mobile number will not deduct the mobile number count (For
        applicable domains)
      </p>
      <p>
        D.The members of Platinum package (3,6,12 months) can receive
        Personalised Messages (PMs) from Free Members. Only if PP of Platinum
        member matches with the Personal Interest of the free member profile
        this will be allowed (For applicable domains).
      </p>
      <p>
        E.The Fair Usage Policy (FUP) limit on Phone view count and Count on
        Personal message for all the premium membership packages are as follows:
      </p>
      <div>
        <table className="table table-bordered">
          <tr>
            <th>Fair Usage Metrics</th>
            <th>Fair Usage Metrics Personal Message Count</th>
            <th>Phone View Count</th>
          </tr>
          <tr>
            <td>Day Zero Paid Limit</td>
            <td>50</td>
            <td>20</td>
          </tr>
          <tr>
            <td>Daily Limit</td>
            <td>100</td>
            <td>30</td>
          </tr>
          <tr>
            <td>Weekly Limit</td>
            <td>150</td>
            <td>50</td>
          </tr>
          <tr>
            <td>Monthly Limit</td>
            <td>500</td>
            <td>100</td>
          </tr>
          <tr>
            <td>Total Limit</td>
            <td>4000</td>
            <td>750</td>
          </tr>
        </table>
        <p>
          F.To know the complete details on various Membership package and its
          benefits, please visit 'Payment Options' page
        </p>
        <h4>35. Terms & Conditions for Doorstep Services</h4>
        <p>
          {officeContactDetails[0].emailId}
          <br></br>
          {officeContactDetails[0].phone}
          <br></br>
          {officeContactDetails[0].addressFirstLine}
          <br></br>
          {officeContactDetails[0].city} - {officeContactDetails[0].pinCode}.
        </p>
        <p>
          PLEASE READ THE FOLLOWING TERMS & CONDITIONS VERY CAREFULLY BEFORE
          AVAILING OUR DOORSTEP SERVICES. THESE TERMS & CONDITIONS GOVERN THE
          USE OF DOORSTEP SERVICES.
        </p>
        <p>
          BY ACCEPTING THE SERVICES, YOU ACCEPT AND CONSENT TO THE TERMS &
          CONDITIONS FOR DOORSTEP SERVICES.
        </p>
        <h4>Services</h4>
        <p>
          BM’s Doorstep Services shall assist the Customer in registering their
          profile in Bharat Matrimony and / or Community Matrimony (hereinafter
          referred to as KM) website/ app.
        </p>
        <p>
          The Doorstep service agent will guide the Customer through the process
          of creating their profile, usage of KM website/ app, and enlighten the
          Customer about existing packages and offers.
        </p>
        <h4>Eligibility</h4>
        <p>
          All Indian citizens above 18 years residing within the territory of
          Chennai, India can avail the doorstep services.
        </p>
        <h4>Booking & Payment</h4>
        <p>
          The Customers can avail the doorstep services by contacting KM, via
          its doorstep services number (8248255555). Customers are required to
          facilitate basic information including but not limited to name, mobile
          number, email id to avail the services. The Customer hereby explicitly
          gives consent for sharing the above information for the purpose of
          availing Doorstep Services and for registration of their profile in
          the online match making portals of MCL. Once the customer accesses the
          online portals / websites, the terms and conditions and Privacy Policy
          mentioned therein will be binding on the Parties.
        </p>
        <p>
          The doorstep service is a paid service. The Customer is required to
          pay a services fee of Rs. 99/- (Rupees Ninety Nine only) including
          applicable GST. Payment link will be sent to the Customers mobile/
          email id through which payment can be completed either by Debit or
          Credit card/ UPI/ net banking. Confirmation of the doorstep visit will
          be sent to the registered mobile number or email ID upon completion of
          payment.
        </p>
        <p>
          Doorstep service will be initiated within 7 working days from the date
          of receipt of payment and subject to availability of the Customer,
          Door step services will be lined up and provided. The scope of
          Doorstep service is confined only to “One Visit” of the representative
          of MCL and provide guidance on various packages that are offered by
          MCL and enabling the Customer to avail the package suited to the
          requirement of the Customer. If the Customer requires a subsequent
          visit, they have to again pay the service fee and the Service Provider
          will line up doorstep service within 7 working days from the date of
          receipt of payment.
        </p>
        <h4>Cancellation & Rescheduling</h4>
        <p>
          The Customer can cancel the Doorstep service within 24 hours of the
          scheduled visit time & date and seek a refund.
        </p>
        <p>
          Refunds will be processed within 7 working days subject to deduction
          of GST amount.
        </p>
        <p>
          a. Customer can seek for rescheduling the Doorstep service visit and
          based on the request of Customer, Doorstep service visit can be
          rescheduled maximum for three occasions and post which Customer shall
          forfeit the fee paid.
        </p>
        <h4>Roles & Responsibility of KM</h4>
        <p>
          a. KM's obligation is only to assist customers in registration of
          profiles in BM’s website/ app. Post registration, the terms and
          conditions mentioned in the App / Website will govern the contract
          between the Parties.
        </p>
        <p>
          b. KM shall safeguard sensitive user information using security
          standards authentication mechanisms, access controls and encryption
          techniques.
        </p>
        <p>
          c. KM cannot guarantee the complete protection of user data while it
          is in transit, or prevent any tampering of the data by a third party
          with malicious intent before the data reaches the KM servers.
        </p>
        <p>
          d. KM is not responsible for delays due to any factor beyond KM
          control or Force Majeure events.
        </p>
        <p>
          e. KM is not liable for any financial transaction between the BM’s
          representative and Customer. The Customer shall not pay any amount to
          BM’s representative and any payment made towards service fee shall be
          paid only to the account of MCL.
        </p>
        <h4>Roles & Responsibility of Customer</h4>
        <p>
          a. KM prohibits discrimination against service agents including on the
          basis of race, religion, cast, gender, age or any other characteristic
          that may be protected under applicable laws.
        </p>
        <p>
          b. We request customers to treat all service agents with courtesy and
          respect. We reserve our right to withhold access to services at our
          absolute discretion if the customer’s behaviour toward KM service
          agent is in a manner which is disrespectful or abusive or which
          otherwise deem to be inappropriate or unlawful.
        </p>
        <p>
          c. Customers are prohibited to do any kind of financial transaction
          directly to the representatives of MCL / KM.
        </p>
        <h4>Privacy</h4>
        <p>
          To protect your privacy and understand our practices as amended from
          time to time, please read and follow our Privacy Policy which also
          governs your visit to KM and other websites/Apps owned by MCL, the
          personal information / data provided to us by you during the course of
          usage of KM will be treated as confidential and in accordance with the
          Privacy policy and applicable laws and regulations. If you object to
          your information being transferred or used, please do not use the
          website or App.
        </p>
        <h4>Complaints</h4>
        <p>
          If you have any complaints or dispute, please contact our customer
          support either in writing or in email with digital signature to:
        </p>
        <p>
          {officeContactDetails[0].emailId}
          <br></br>
          {officeContactDetails[0].phone}
          <br></br>
          {officeContactDetails[0].addressFirstLine}
          <br></br>
          {officeContactDetails[0].city} - {officeContactDetails[0].pinCode}.
        </p>
        <p>
          The Grievance office shall be available between 10 am till 6 pm Indian
          Standard Time from Monday to Saturday excluding Sunday and public
          holidays in India. The Grievance officer is appointed as per Rule 3
          (11) of The Information Technology (Intermediaries Guidelines) Rules,
          2011. Any and all complaints to the Grievance Officer shall be in
          writing. We shall make reasonable efforts to resolve any issue in a
          timely manner.
        </p>
        <h1>Indemnity</h1>
        <p>
          By using our KM services you agree to defend, indemnify, and hold
          harmless KM, its subsidiaries, affiliates, Directors, officers,
          agents, and other partners and employees, fully indemnified and
          harmless from any loss, damage, liability, claim, or demand, including
          reasonable attorney's fees, made by any person through improper use of
          the service provided by KM. This defence and indemnification
          obligation will survive in perpetuity.
        </p>
        <h4>Termination</h4>
        <p>
          KM reserves the right to terminate the services for any breach of
          these Terms & Conditions. all amount paid till date by you to KM shall
          be forfeited.
        </p>
        <p>
          Upon termination / suspension of KM membership, your right to use or
          access the KM Website/App KM services will cease immediately. All
          provisions of this terms and conditions that by their nature should
          survive termination shall survive termination, including, without
          limitation, disclaimers, and limitations of liability. Termination of
          your access to and use of the KM Services shall not relieve you of any
          obligations arising or accruing prior to such termination.
        </p>
        <h4>Governing Laws</h4>
        <p>
          The Terms & Conditions are exclusively governed by the Laws of Indian
          (Jurisdiction).
        </p>
        <h4>Arbitration of Dispute</h4>
        <p>
          The Parties shall discuss among themselves and resolve any disputes
          informally. We are interested in attempting to resolve any Disputes by
          amicable and informal means, and we encourage you to contact us before
          resorting to arbitration. If the dispute is not resolved within 30 (
          thirty ) days from the date of such dispute , then such dispute may
          only be resolved through an individual arbitration conducted in
          accordance with the provisions of The Arbitration and Conciliation
          Act, 1996, by sole arbitrator. The seat of Arbitration shall be
          Chennai, Tamil Nadu, India.We shall appoint the sole arbitrator. The
          arbitration proceedings shall be conducted in the English language.
        </p>
      </div>
    </div>
  );
};

export default Terms;
