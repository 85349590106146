import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { faClone, faFilePdf, faImage } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import downloadjs from "downloadjs";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import React from "react";
import { useSelector } from "react-redux";
import HoroscopeBoxes from "../../../components/horoscope-boxes";
import MasonryImageList from "../../../components/masonry-image-list";
import { translations } from "../../../config/config";
import { KW_LOGO } from "../../../constant";
import useMediaQuery from "../../../hooks/use-media-query";
import {
  getCopyLink,
  getLang,
  getUserName,
  getWhatsappLink,
} from "../../../store/selector";
import { successToaster } from "../../../utils/alerts";
import {
  getBirthDetails,
  getContactNumber,
  getFamilyDetails,
  getHoroscopeDetails,
  getNavamsaKatam,
  getOccupation,
  getPersonalInfo,
  getQualification,
  getRasiKatam,
  getResidentialDetails,
} from "../store/selector";

const HoroscopePreview = () => {
  const lang = useSelector(getLang);
  const navamsaKatam = useSelector(getNavamsaKatam);
  const rasiKatam = useSelector(getRasiKatam);
  const username = useSelector(getUserName);
  const whatsappLink = useSelector(getWhatsappLink);
  const copyLink = useSelector(getCopyLink);

  const { name, weight, height, iAm, bloodGroup, complextion, profileImage } =
    useSelector(getPersonalInfo);
  const { address, landmark, city, stateName, nationality, pincode } =
    useSelector(getResidentialDetails);
  const { emailId, contactNumber, whatsappNumber, alternativeContactNumber } =
    useSelector(getContactNumber);
  const { degree } = useSelector(getQualification);
  const { designation, annualIncome } = useSelector(getOccupation);
  const {
    rasi,
    nakshatra,
    gothram,
    mathulam,
    lagnam,
    naaligai,
    thithi,
    dasaPlanet,
    dasaBalance,
  } = useSelector(getHoroscopeDetails);
  const {
    siblings,
    fatherName,
    fatherAlive,
    fatherOccupation,
    motherName,
    motherAlive,
    motherOccupation,
  } = useSelector(getFamilyDetails);
  const { dob, tamilYear, tamilMonth, tamilDate } =
    useSelector(getBirthDetails);
  const { dateOfBirth, placeOfBirth, time, day } = dob;
  const printRef = React.useRef();
  const { isDesktop } = useMediaQuery();

  const t = (text) => {
    return translations[lang][text];
  };

  const handleDownloadPdf = async () => {
    const element = printRef.current;
    const canvas = await html2canvas(element, { useCORS: true });
    const data = canvas.toDataURL("image/png");

    const pdf = new jsPDF();
    const imgProperties = pdf.getImageProperties(data);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;

    pdf.addImage(data, "PNG", 0, 0, pdfWidth, pdfHeight);
    pdf.save(`${username}.pdf`);
  };

  const handleDownloadImage = async () => {
    const element = document.getElementById("capture");
    const canvas = await html2canvas(element, { useCORS: true, logging: true });
    const dataURL = canvas.toDataURL("image/png");
    downloadjs(dataURL, "download.png", "image/png");
  };

  return (
    <div>
      <div
        style={{
          marginBottom: "10px",
          display: "flex",
          justifyContent: "space-around",
          flexDirection: isDesktop ? "row" : "column",
        }}
        className={!isDesktop ? "pos-center" : ""}
      >
        <button
          onClick={() => {
            navigator.clipboard.writeText(copyLink);
            successToaster("Copied to clipboard");
          }}
          className={!isDesktop ? "btn btn-primary btn-lg" : "btn btn-primary"}
        >
          <FontAwesomeIcon icon={faClone} /> Copy Link
        </button>
        <a
          href={whatsappLink}
          target="_blank"
          rel="noopener noreferrer"
          className={!isDesktop ? "btn btn-primary btn-lg" : "btn btn-primary"}
          style={{ marginTop: isDesktop ? "" : "10px" }}
        >
          <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
        </a>
        <button
          type="button"
          className={!isDesktop ? "btn btn-primary btn-lg" : "btn btn-primary"}
          onClick={handleDownloadPdf}
          style={{ marginTop: isDesktop ? "" : "10px" }}
        >
          <FontAwesomeIcon icon={faFilePdf} /> {"  "}Download PDF
        </button>
        <button
          type="button"
          className={!isDesktop ? "btn btn-primary btn-lg" : "btn btn-primary"}
          onClick={handleDownloadImage}
          style={{ marginTop: isDesktop ? "" : "10px" }}
          disabled={false}
        >
          <FontAwesomeIcon icon={faImage} />
          {"  "} Download Image
        </button>
      </div>
      <div ref={printRef} id="capture">
        <Paper
          style={{
            width: "595px",
            minHeight: "500px",
            padding: "20px 40px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            filter: isDesktop ? "blur(0px)" : "blur(4px)",
            zoom: isDesktop ? "100%" : "65%",
          }}
        >
          <MasonryImageList
            itemData={[
              {
                img:
                  profileImage !== ""
                    ? profileImage
                    : "https://images.unsplash.com/photo-1549388604-817d15aa0110",
                title: "Bed",
              },
            ]}
          />
          <img src={KW_LOGO} alt="kalyanawaran" width={225} />
        </Paper>
        <Paper
          className="horoscope-bg A472"
          style={{
            color: "#9B0B00",
            filter: isDesktop ? "blur(0px)" : "blur(4px)",
            zoom: isDesktop ? "100%" : "65%",
          }}
        >
          <Grid
            container
            spacing={2}
            alignContent="center"
            justifyContent="center"
            flexDirection="column"
            margin="10px"
          >
            <Grid item xs={12} className="gridItem" style={{ padding: 0 }}>
              <img
                src="https://res.cloudinary.com/dc3dylylv/image/upload/v1671261696/pillyar-sulipng_skoxx7.png"
                alt="pillayar-suli"
                width={18}
              />
            </Grid>
            <Grid
              item
              xs={12}
              className="gridItem"
              style={{ padding: "5px 0" }}
            >
              <p className="para subtext1">
                Vasavi Sahayam
                <br />
                Angala Parameshwari Amman Thunai
              </p>
            </Grid>
            <Grid item xs={12} className="gridItem" style={{ padding: 0 }}>
              <p className="para subtext1">
                Janani Janma Sowkyanam Varathanee Kulasumbathaam Padhavi
                <br />
                Pooorva Punyanaam Likyathe Janma Pathrika
              </p>
            </Grid>
          </Grid>
          <Grid container alignContent="center" justifyContent="center">
            <Grid item className="gridItem">
              <table
                style={{
                  borderRight: "1px solid #9b0b00",
                  borderTop: "1px solid #9b0b00",
                }}
              >
                <tbody>
                  <tr>
                    <th className="pibox">{t("birthDate")}</th>
                    <td className="pibox">{dateOfBirth}</td>
                    <th className="pibox">{t("naligai")}</th>
                    <td className="pibox">{naaligai}</td>
                  </tr>
                  <tr>
                    <th className="pibox">{t("tamilYear")}</th>
                    <td className="pibox">{tamilYear}</td>
                    <th className="pibox">{t("birthStar")}</th>
                    <td className="pibox">{nakshatra}</td>
                  </tr>
                  <tr>
                    <th className="pibox">{t("tamilMonth")}</th>
                    <td className="pibox">{tamilMonth}</td>
                    <th className="pibox">{t("birthRasi")}</th>
                    <td className="pibox">{rasi}</td>
                  </tr>
                  <tr>
                    <th className="pibox">{t("tamilDate")}</th>
                    <td className="pibox">{tamilDate}</td>
                    <th className="pibox">{t("thithi")}</th>
                    <td className="pibox">{thithi}</td>
                  </tr>
                  <tr>
                    <th className="pibox">{t("day")}</th>
                    <td className="pibox">{day}</td>
                    <th className="pibox">{t("lagnam")}</th>
                    <td className="pibox">{lagnam}</td>
                  </tr>
                  <tr>
                    <th className="pibox">{t("birthTime")}</th>
                    <td className="pibox">{time}</td>
                    <th className="pibox">{t("birthPlace")}</th>
                    <td className="pibox">{placeOfBirth}</td>
                  </tr>
                </tbody>
              </table>
            </Grid>
          </Grid>
          <Grid
            container
            alignContent="center"
            justifyContent="center"
            margin="10px"
          >
            <Grid item className="gridItem" lg={12} md={12} sm={12}>
              <p className="para">
                S/o. {fatherAlive === "No" && "Late."} Sri.{" "}
                <strong>{fatherName}</strong> -{" "}
                {!motherAlive === "No" && "Late"} Smt.{" "}
                <strong>{motherName}</strong>
              </p>
            </Grid>
            <Grid item className="gridItem" lg={12} md={12} sm={12}>
              <p className="para">
                <span className="subtext2">
                  Shri. Mt. Puniya. {iAm === "Groom" ? "Kumaaran" : "Kumaari"}
                </span>{" "}
                <span className="title3">{name}</span>
              </p>
            </Grid>
            <Grid item className="gridItem">
              <p className="para">
                <span style={{ marginRight: "10px" }}>
                  <strong>Gotharam :</strong> {gothram}
                </span>
                <span>
                  <strong>Mathulam :</strong> {mathulam}
                </span>
              </p>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={2}
            alignContent="center"
            justifyContent="center"
          >
            {/* Rasi Katam */}
            <Grid item lg={6} className="gridItem">
              <HoroscopeBoxes size={"60px"} katam={rasiKatam} title="rasi" />
            </Grid>
            {/* Navamsa Katam */}
            <Grid item lg={6} className="gridItem">
              <HoroscopeBoxes
                size={"60px"}
                katam={navamsaKatam}
                title="navamsa"
              />
            </Grid>
          </Grid>
          <Grid container alignContent="center" justifyContent="center">
            <Grid item lg={12} className="gridItem" margin="10px">
              <p className="para">
                {dasaPlanet} <strong>Dasa Balance :</strong> {dasaBalance}
              </p>
            </Grid>
            <Grid item lg={12} className="gridItem" style={{ padding: "10px" }}>
              <table
                style={{
                  borderRight: "1px solid #9b0b00",
                  borderTop: "1px solid #9b0b00",
                }}
              >
                <tbody>
                  <tr>
                    <td className="pibox">
                      <strong>Father :</strong> {fatherAlive}
                    </td>
                    <td className="pibox">
                      <strong>Mother :</strong> {motherAlive}
                    </td>
                    <td className="pibox">
                      <strong>Blood Group :</strong> {bloodGroup}
                    </td>
                  </tr>
                  <tr>
                    <td className="pibox">
                      <strong>Brother : </strong>
                      {siblings.brother.number} <strong>Married : </strong>
                      {siblings.brother.marriageNum}
                    </td>
                    <td className="pibox">
                      <strong>Sister : </strong>
                      {siblings.sister.number} <strong>Married : </strong>
                      {siblings.sister.marriageNum}
                    </td>
                    <td className="pibox">
                      {fatherOccupation && (
                        <span>
                          <strong>Father Occupation : </strong>
                          {fatherOccupation}
                        </span>
                      )}
                      {motherOccupation && (
                        <span>
                          <strong>Mother Occupation : </strong>
                          {motherOccupation}
                        </span>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td className="pibox">
                      <strong>Education :</strong> {degree}
                    </td>
                    <td className="pibox">
                      <strong>Profession :</strong> {designation}
                    </td>
                    <td className="pibox">
                      <strong>Income :</strong> {annualIncome}
                    </td>
                  </tr>
                  <tr>
                    <td className="pibox">
                      <strong>Height :</strong> {height}
                    </td>
                    <td className="pibox">
                      <strong>Weight :</strong> {weight}
                    </td>
                    <td className="pibox">
                      <strong>Complexion :</strong> {complextion}
                    </td>
                  </tr>
                </tbody>
              </table>
            </Grid>
            <Grid item margin="10px">
              <p className="para">
                <strong>Address:</strong> {address}, {landmark},
                <br />
                {city} - {pincode}. <strong>State:</strong> {stateName}{" "}
                <strong>Nationality:</strong> {nationality}{" "}
                <strong>Mobile:</strong> {contactNumber} |{" "}
                {alternativeContactNumber !== "" && alternativeContactNumber}{" "}
                <br />
                <strong>Whatsapp:</strong> {whatsappNumber}{" "}
                <strong>Email ID:</strong> {emailId}
              </p>
            </Grid>
          </Grid>
        </Paper>
      </div>
    </div>
  );
};

export default HoroscopePreview;
