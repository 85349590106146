export const validator = (value, rules) => {
  return;
};

export const passwordValidator = (password) => {
  if (!/[A-Z]/.test(password)) {
    return {
      message: "Contains at least one uppercase letter",
      errorType: "error",
    };
  }
  if (!/[a-z]/.test(password)) {
    return {
      message: "Contains at least one lowercase letter",
      errorType: "error",
    };
  }
  if (!/\d/.test(password)) {
    return { message: "Contains at least one digit", errorType: "error" };
  }
  if (!/[!@#$%^&*]/.test(password)) {
    return {
      message: "Contains at least one special character",
      errorType: "error",
    };
  }
  if (password.length < 8) {
    return { message: "Contain Minium 8 characters", errorType: "error" };
  }
  return null;
};

export const confirmPasswordValidator = (password, confirmPassword) => {
  if (password !== confirmPassword) {
    return { message: "Password Not Matching", errorType: "error" };
  }
  return { message: "Password Matching", errorType: "success" };
};
