import Box from "@mui/material/Box";
import React from "react";
import { useSelector } from "react-redux";
import LableWithFormField from "../../../components/label-with-formfield";
import * as config from "../../../config/config";
import { getLang } from "../../../store/selector";
// import { actions } from "../store/slice";

const Loc = () => {
  // const dispatch = useDispatch();
  const lang = useSelector(getLang);
  let country, state, city;

  const locFields = [
    {
      type: "select",
      text: "Residing Country",
      id: "country",
      defaultValue: country,
      placeholder: "20",
      array: config.ageLimit,
    },
    {
      type: "select",
      text: "Residing State",
      id: "state",
      defaultValue: state,
      placeholder: "20",
      array: config.ageLimit,
    },
    {
      type: "select",
      text: "Residing City",
      id: "city",
      defaultValue: city,
      placeholder: "20",
      array: config.ageLimit,
    },
  ];

  return (
    <Box
      sx={{
        flexGrow: 1,
        padding: "25px",
      }}
    >
      <div className="table-responsive">
        <table className="table">
          <tbody>
            {locFields.map((props, index) => (
              <LableWithFormField key={index} {...props} />
            ))}
          </tbody>
        </table>
      </div>
    </Box>
  );
};

export default Loc;
