import styled from "styled-components";

const Td = styled.td`
  height: ${(props) => props.size};
  width: ${(props) => props.size};
  // border: 1px solid #9b0b00;
  border-bottom: 1px solid #9b0b00;
  border-left: 1px solid #9b0b00;
  padding: 5px;
`;

export { Td };
