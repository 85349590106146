import React from "react";

const ComingSoon = () => {
  return (
    <div className="container">
      <div className="error-container">
        <div className="error-info">
          <p
            style={{
              fontSize: "80px",
              fontWeight: 100,
              textTransform: "uppercase",
            }}
          >
            Under
            <br />
            Construction
          </p>
          <p>
            This page is being coded.
            <br />
            We are working to make it live as fast as possible.
          </p>
        </div>

        <div className="error-image"></div>
      </div>
    </div>
  );
};

export default ComingSoon;
