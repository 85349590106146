import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Avatar, Badge, Tooltip } from "@mui/material";
import { faCircleCheck, faCircleXmark, faTriangleExclamation, faBan } from "@fortawesome/free-solid-svg-icons";

function VerificationIcons({ name, iconName, verificationStatus }) {
    const status = name === "Email" ? verificationStatus.emailVerificationDetails.status : name === "Mobile" ? verificationStatus.govtIdVerificationDetails.status : verificationStatus.mobileVerificationDetails.status
    const icon = status === "Approval" ? faCircleCheck : status === "Rejected" ? faCircleXmark : status === "Pending" ? faTriangleExclamation : faBan;
    const color = status === "Approval" ? "#4caf50" : status === "Rejected" ? "#f44336" : status === "Pending" ? "#2196f3" : "#ff9800";
    return (
        <Tooltip title={`${name} ${status}`}>
            <Badge
                overlap="circular"
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                badgeContent={
                    <FontAwesomeIcon icon={icon} size='2x' style={{ background: "#fff", borderRadius: "50%", color: color }} />
                }
            >
                <Avatar className="bg-primary">
                    <FontAwesomeIcon icon={iconName} />
                </Avatar>
            </Badge>
        </Tooltip >
    )
}
export default VerificationIcons;