import React, { useEffect, useState } from "react";
import ReactGA from "react-ga";
import { useLocation, useNavigate } from "react-router-dom";
import { forgetPassword } from "../../store/services";
import { errorToaster, successToaster } from "../../utils/alerts";
import LayoutPage from "../layout-page/";

const ForgetPassword = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [emailAddress, setEmailAddress] = useState();

  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);

  const handleForgetPassword = (e) => {
    e.preventDefault();
    forgetPassword(emailAddress)
      .then((res) => {
        if (res.data.success) {
          successToaster(res.data.message, 4000);
        } else {
          errorToaster(res.data.message, 4000);
        }
      })
      .catch((err) => {
        errorToaster(err.message, 4000);
      });
  };

  return (
    <LayoutPage
      content={
        <form>
          <div className="form-group">
            <label htmlFor="exampleInputEmail1">Email Address</label>
            <input
              type="email"
              className="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              placeholder="Enter email"
              onChange={(e) => setEmailAddress(e.target.value)}
            />
            <small id="emailHelp" className="form-text text-muted mb-3">
              We'll never share your email with anyone else.
            </small>
          </div>

          <div className="  form-group">
            <button
              type="submit"
              className="btn btn-primary"
              onClick={(e) => handleForgetPassword(e)}
            >
              Send my details
            </button>
            <button
              onClick={() => navigate("/login")}
              className="btn btn-secondary"
              style={{ marginLeft: 5 }}
            >
              Login
            </button>
          </div>
        </form>
      }
      title="Forget Password"
    />
  );
};

export default ForgetPassword;
