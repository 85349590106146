import React from "react";

const Card = ({
  title = "Card Title",
  subTitle,
  content = <></>,
  footerContent,
}) => {
  return (
    <div className="card">
      <div className="card text-center">
        <div className="card-header">{title}</div>
        <div className="card-body">
          {subTitle && <h5 className="card-title">{subTitle}</h5>}
          {content}
        </div>
        {footerContent && (
          <div className="card-footer text-muted">{footerContent}</div>
        )}
      </div>
    </div>
  );
};

export default Card;
