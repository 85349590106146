import { createSelector } from "reselect";
import { getItem } from "../../../utils/local-storage";

const currentUser = getItem("current_user");

const searchProfileState = (state) => state.searchProfile;

const getCounter = createSelector(
  searchProfileState,
  (searchProfile) => searchProfile?.counter
);

const getProfiles = createSelector(
  searchProfileState,
  (searchProfile) =>
    searchProfile &&
    searchProfile?.profiles?.filter(
      (value) => value?.userId !== currentUser?.userId
    )
);
const getCurrentPage = createSelector(
  searchProfileState,
  (searchProfile) => searchProfile?.currentPage
)
const getTotalPage = createSelector(
  searchProfileState,
  (searchProfile) => searchProfile?.totalPage
)
const getFilter = createSelector(
  searchProfileState,
  (searchProfile) => searchProfile?.filter
)
const getSearch = createSelector(
  searchProfileState,
  (searchProfile) => searchProfile?.search
);
const getSort = createSelector(
  searchProfileState,
  (searchProfile) => searchProfile?.sort
)
const getSelectedRasi = createSelector(
  searchProfileState,
  (searchProfile) => searchProfile?.rasi
)
const getPagination = createSelector(
  searchProfileState,
  (searchProfile) => searchProfile?.pagination
)

export { getCounter, getProfiles, getTotalPage, getSearch, getPagination, getFilter, getSort, getCurrentPage, getSelectedRasi };
