import React from "react";
import CustomTabs from "../../../components/custom-tabs";
import AboutPartner from "../about-partner";
import Basic from "../basic";
import Loc from "../loc";
import Professional from "../professional";
import Religious from "../religious";

const PreferencePage = () => {
  const tabs = [
    "Basic",
    "Location",
    "Religious",
    "Professional",
    "About Partner",
  ];
  const tabPanel = [
    { label: "Basic", content: <Basic /> },
    { label: "Location", content: <Loc /> },
    { label: "Religious", content: <Religious /> },
    { label: "Professional", content: <Professional /> },
    { label: "About Partner", content: <AboutPartner /> },
  ];

  return (
    <div
    // style={{ marginTop: "75px" }}
    >
      <CustomTabs tabs={tabs} tabPanel={tabPanel} />
    </div>
  );
};

export default PreferencePage;
