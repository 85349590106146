import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { actions } from "../../store/slice";
import ReactGA from "react-ga";
import { Link, useLocation, useNavigate } from "react-router-dom";

const NotFound = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(actions.setIsHeaderFooter(false));
  }, [dispatch]);

  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);

  return (
    <div className="container">
      <div className="error-container">
        <div className="error-info">
          <h1>404</h1>
          <p>
            It seems that the page you are looking for no longer exists.
            <br />
            Please contact our{" "}
            <Link to="/" onClick={() => navigate("/")}>
              help center
            </Link>{" "}
            or go to the{" "}
            <Link to="/" onClick={() => navigate("/")}>
              homepage
            </Link>
            .
          </p>
        </div>
        <div className="error-image" style={{ paddingRight: "15px" }}></div>
      </div>
    </div>
  );
};

export default NotFound;
