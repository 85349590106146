import { Stack } from "@mui/material";
import Pagination from "@mui/material/Pagination";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getItem } from "../../utils/local-storage";
import { fetchAllProfileByFilter } from "../../view/profile/store/services";
import {
  getFilter,
  getPagination,
  getSearch,
  getSort,
} from "../../view/search-profile/store/selector";
import { actions } from "../../view/search-profile/store/slice";

const PaginationComponent = () => {
  const dispatch = useDispatch();
  const filter = useSelector(getFilter);
  const { minAge, maxAge, rasi } = filter;
  const sort = useSelector(getSort);
  const search = useSelector(getSearch);
  const pagination = useSelector(getPagination);
  const { totalPage } = pagination;

  const handlePageChange = (event, value) => {
    dispatch(actions.setCurrentPage(value));
    fetchAllProfileByFilter(
      getItem("the_main_app"),
      search,
      minAge,
      maxAge,
      rasi,
      sort,
      value
    )
      .then((response) => {
        dispatch(actions.setAllProfiles(response.data.values));
        dispatch(actions.setTotalPage(response.data.total));
        dispatch(actions.setCurrentPage(response.data.page));
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <div className=" my-3">
      <Stack spacing={2}>
        <Pagination
          count={totalPage || 1}
          showFirstButton
          showLastButton
          shape="rounded"
          color="primary"
          onChange={handlePageChange}
        />
      </Stack>
    </div>
  );
};

export default PaginationComponent;
