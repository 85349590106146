import { Box } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import LableWithFormField from "../../../components/label-with-formfield";
import * as config from "../../../config/config";
import { getOtherDetails } from "../store/selector";
import { actions } from "../store/slice";

function OtherDetails() {
  const dispatch = useDispatch();
  const otherDetails = useSelector(getOtherDetails);
  const {
    lookingFor,
    createdBy,
    ownHouse,
    eatingHabits,
    smookingHabits,
    drinkingHabits,
  } = otherDetails;
  const handleChange = (e) => {
    const { name, value } = e.target;
    dispatch(actions.setIsFormChanged(true));
    dispatch(
      actions.setProfileDetails({
        ...otherDetails,
        [name]: value,
      })
    );
  };

  const otherDetailsFields = [
    {
      type: "select",
      text: "Looking For",
      id: "lookingFor",
      name: "lookingFor",
      value: lookingFor,
      onChange: (e) => handleChange(e),
      array: config.lookingFor,
    },
    {
      type: "select",
      text: "Created By",
      id: "createdBy",
      name: "createdBy",
      value: createdBy,
      onChange: (e) => handleChange(e),
      array: config.createdBy,
    },
    {
      type: "select",
      text: "Own House",
      id: "ownHouse",
      name: "ownHouse",
      value: ownHouse,
      onChange: (e) => handleChange(e),
      array: config.yesOrNo,
    },
    {
      type: "select",
      text: "Eating Habits",
      id: "eatingHabits",
      name: "eatingHabits",
      value: eatingHabits,
      onChange: (e) => handleChange(e),
      array: config.eatingHabits,
    },
    {
      type: "select",
      text: "Smooking Habits",
      id: "smookingHabits",
      name: "smookingHabits",
      value: smookingHabits,
      onChange: (e) => handleChange(e),
      array: config.smokingHabits,
    },
    {
      type: "select",
      text: "Drinking Habits",
      id: "drinkingHabits",
      name: "drinkingHabits",
      value: drinkingHabits,
      onChange: (e) => handleChange(e),
      array: config.drinkingHabits,
    },
  ];

  return (
    <Box
      sx={{
        flexGrow: 1,
        padding: "25px",
      }}
    >
      <div className="table-responsive">
        <table className="table">
          <tbody>
            {otherDetailsFields?.map((props, index) => (
              <LableWithFormField key={index} {...props} />
            ))}
          </tbody>
        </table>
      </div>
    </Box>
  );
}

export default OtherDetails;
