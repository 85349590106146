import React, { useState } from "react";
import { useNavigate, useParams } from "react-router";
import LayoutPage from "../layout-page";
import TextInput from "../text-input";
import { errorToaster, successToaster } from "../../utils/alerts";
import { resetPassword } from "../../store/services";
import { confirmPasswordValidator, passwordValidator } from "../../utils/password-validator";

function ResetPassword() {
  const navigate = useNavigate();
  const { token } = useParams();
  const [password, setPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const [passwordError, setPasswordError] = useState({})
  const [confirmPasswordError, setConfirmPasswordError] = useState({})


  const resetPasswordInputs = [
    {
      type: "password",
      placeholder: "New Password",
      value: "",
      name: "Password",
      onChange: (e) => {
        setPassword(e.target.value);
        setPasswordError(passwordValidator(e.target.value));
        handleDisableButton()
      },
      errorMessage: passwordError?.message,
      errorType: passwordError?.errorType

    },
    {
      type: "password",
      placeholder: "Confirm Password",
      name: "Confirm Password",
      value: "",
      onChange: (e) => {
        setConfirmPassword(e.target.value);
        setConfirmPasswordError(confirmPasswordValidator(password, e.target.value));
        handleDisableButton();
      },
      errorMessage: confirmPasswordError?.message,
      errorType: confirmPasswordError?.errorType,
    },
  ];

  const handleDisableButton = () => {
    if (password === confirmPassword) {
      if (!passwordError) {
        return false
      }
    }
    else if (passwordError) {
      return true
    }
    else if (password.length >= 8 && confirmPassword >= 8) {
      return false
    }
    else {
      return true
    }
  }

  const handleResetPassword = () => {
    resetPassword(token, password)
      .then((res) => {
        if (res.data.success) {
          successToaster(res.data.message, 4000);
          navigate("/login");
        } else {
          errorToaster(res.data.message, 4000);
          navigate("/forgetpassword");
        }
      })
      .catch((err) => {
        errorToaster(err.message, 4000)
        navigate("/forgetpassword");
      });
  };

  return (
    <LayoutPage
      content={
        <div>
          {resetPasswordInputs.map(
            ({ type, placeholder, name, value, onChange, errorMessage, errorType }, index) => (
              <div div key={index} >
                <TextInput
                  type={type}
                  className="input-field"
                  placeholder={placeholder}
                  name={name}
                  value={value}
                  onChange={onChange}
                />
                <p className={errorType === "error" ? "text-danger" : "text-success"}>{errorMessage}</p>
              </div>
            )
          )}
          <button
            className="btn btn-primary float-right m-l-xxs"
            style={{ marginRight: "6px" }}
            onClick={(e) => handleResetPassword(e)}
            disabled={handleDisableButton()}
          >
            Reset Password
          </button>
        </div>
      }
      title="ResetPassword"
    />
  );
}

export default ResetPassword;
