import React from "react";
import Message from "../message";

const TextInput = ({
  type = "text",
  name = "name",
  value = "",
  onChange,
  placeholder = "",
  className = "",
  onKeyDown,
  error,
}) => (
  <>
    <input
      type={type}
      className={"form-control " + className}
      id={name}
      aria-describedby={name}
      placeholder={placeholder}
      name={name}
      onChange={onChange}
      onKeyDown={onKeyDown}
      defaultValue={value}
    />
    {error && <Message message={error} type={"danger"} />}
  </>
);

export default TextInput;
