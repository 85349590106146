import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import HoroscopeBoxes from "../../../components/horoscope-boxes";
import { twelveNumbers } from "../../../config/config";
import { getRasiKatam, getRasiPlanets } from "../store/selector";
import { actions } from "../store/slice";

const RasiKatamDetails = () => {
  const dispatch = useDispatch();
  const rasiKatam = useSelector(getRasiKatam);
  const planets = useSelector(getRasiPlanets);

  const handleChange = (event, planetName) => {
    const { value } = event.target;
    dispatch(actions.setRasiPlanets({ ...planets, [planetName]: value }));
  };

  useEffect(() => {
    let updatedData = [[], [], [], [], [], [], [], [], [], [], [], []];
    Object.entries(planets).forEach(([key, value]) => {
      if (value !== "Select") updatedData[value - 1].push(`${key} `);
    });
    dispatch(actions.setRasiKatam([...updatedData]));
  }, [planets]);

  return (
    <Box
      sx={{
        flexGrow: 1,
        padding: "25px",
      }}
    >
      <Grid container alignContent="center" justifyContent="center">
        <Grid item lg={7} md={7} xs={7} className="gridItem">
          <HoroscopeBoxes
            size={"80px"}
            pillNum
            title="Rasi"
            katam={rasiKatam}
          />
        </Grid>
        <Grid
          item
          lg={5}
          md={5}
          xs={5}
          className="gridItem"
          style={{ flexDirection: "column" }}
        >
          {Object.keys(planets).map((ele, index) => (
            <div
              className="form-group"
              style={{ display: "flex", alignItems: "center" }}
              key={index}
            >
              <Grid
                container
                style={{
                  alignItems: "center",
                  marginBottom: "4px",
                }}
                id="rasi-katam-table"
              >
                <Grid item xs={6}>
                  <label htmlFor={"exampleFormControlSelect" + index}>
                    {ele}
                  </label>
                </Grid>
                <Grid item xs={6}>
                  <select
                    className="form-control custom-select"
                    id={"exampleFormControlSelect1-rasi-planet" + index}
                    name="rasi"
                    value={planets[ele]}
                    style={{ width: "auto" }}
                    onChange={(e) => handleChange(e, ele)}
                  >
                    <option value="Select">Select</option>
                    {twelveNumbers.map((val, index) => (
                      <option key={index} value={val}>
                        {val}
                      </option>
                    ))}
                  </select>
                </Grid>
              </Grid>
            </div>
          ))}
        </Grid>
      </Grid>
    </Box>
  );
};

export default RasiKatamDetails;
