import Box from "@mui/material/Box";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import LableWithFormField from "../../../components/label-with-formfield/lable-with-formfield";
import * as config from "../../../config/config";
import { getQualification } from "../store/selector";
import { actions } from "../store/slice";

const Education = () => {
  const dispatch = useDispatch();
  const qualification = useSelector(getQualification);
  const { fieldOfStudy, degree } = qualification;

  useEffect(() => {
    dispatch(actions.setQualification({ fieldOfStudy, degree }));
  }, [fieldOfStudy, degree, dispatch]);

  const handleChange = (e) => {
    const { name, value } = e?.target;
    dispatch(actions.setIsFormChanged(true));
    dispatch(
      actions.setQualification({
        ...qualification,
        [name]: value,
      })
    );
  };

  const educationFormFields = [
    {
      type: "select",
      text: "Education",
      id: "education",
      name: "fieldOfStudy",
      defaultValue: fieldOfStudy,
      onChange: (e) => handleChange(e),
      array: config.fieldOfStudy,
    },
    {
      type: "text",
      text: "Degree",
      id: "degree",
      name: "degree",
      defaultValue: degree,
      placeholder: "Computer Science,.....",
      onChange: (e) => handleChange(e),
    },
  ];
  return (
    <Box
      sx={{
        flexGrow: 1,
        padding: "25px",
      }}
    >
      <div className="table-responsive">
        <table className="table">
          <tbody>
            {educationFormFields?.map((props, index) => (
              <LableWithFormField key={index} {...props} />
            ))}
          </tbody>
        </table>
      </div>
    </Box>
  );
};

export default Education;
