import {
  faFile,
  //faFileInvoice,
  faFilePen,
  //faGear,
  faHome,
  faMagnifyingGlass,
  // faSliders,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Chip from "@mui/material/Chip";
import Tooltip from "@mui/material/Tooltip";
import React from "react";
import { useSelector } from "react-redux";
import {
  // useLocation,
  useNavigate,
} from "react-router-dom";
import { getUser } from "../../store/selector";
import { getItem } from "../../utils/local-storage";

const Sidebar = () => {
  // const location = useLocation();
  const { token } = useSelector(getUser);
  const navigate = useNavigate();

  const menu = [
    {
      isVisible: true,
      name: "Home",
      link: "/",
      icon: faHome,
      onClick: () => navigate("/"),
      beta: false,
    },
    {
      isVisible: !token,
      name: "Register",
      link: "/register",
      icon: faFilePen,
      onClick: () => navigate("/register"),
      beta: false,
    },
    {
      isVisible: !token,
      name: "Login",
      link: "/login",
      icon: faFile,
      onClick: () => navigate("/login"),
      beta: false,
    },
    {
      isVisible: token,
      name: "My Profile",
      link: "/profileedit",
      icon: faUser,
      onClick: () => navigate("/profileedit"),
      beta: false,
    },
    {
      isVisible: token,
      name: "Search Profile",
      link: "/search-profiles",
      icon: faMagnifyingGlass,
      onClick: () => navigate("/search-profiles"),
      beta: false,
    },
    // {
    //   isVisible: token,
    //   name: "Set Preference",
    //   link: "/preference",
    //   icon: faSliders,
    //   onClick: () => navigate("/preference"),
    //   beta: true,
    // },
    // {
    //   isVisible: token,
    //   name: "Account",
    //   link: "/account",
    //   icon: faFileInvoice,
    //   onClick: null,
    // },
    // {
    //   isVisible: token,
    //   name: "Settings",
    //   link: "/settings",
    //   icon: faGear,
    //   onClick: null,
    // },
    // {
    //   isVisible: token,
    //   name: "Verification",
    //   link: "/verify",
    //   icon: faGear,
    //   onClick: null,
    // },
    // {
    //   isVisible: token,
    //   name: "Forget Password",
    //   link: "/forgetpassword",
    //   icon: faGear,
    //   onClick: null,
    // },
  ];

  return (
    <div>
      {token && (
        <div style={{ padding: "15px 15px 0px 15px" }}>
          <h5 className="sidebar-title">User Info</h5>
          <h6>
            User Name:{" "}
            <span style={{ fontStyle: "italic", opacity: ".5" }}>
              {getItem("current_user")?.username || ""}
            </span>
          </h6>
          <h6>
            User Id:{" "}
            <span style={{ fontStyle: "italic", opacity: ".5" }}>
              {getItem("current_user")?.userId || ""}
            </span>
          </h6>
        </div>
      )}
      <div
        className="accordion-menu"
        style={{
          marginTop: !token ? "" : "10px",
          padding: "15px 15px 0px 15px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {menu.map(({ isVisible, onClick, name, icon, link, beta }, index) => (
          <div
            key={index}
            style={{
              marginBottom: "5px",
            }}
            hidden={!isVisible}
          >
            {isVisible ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <button
                  // href={link}
                  className="btn btn-link"
                  style={{
                    textDecoration: "none",
                    padding: "5px 0",
                    marginBottom: "5px",
                  }}
                  onClick={onClick}
                >
                  <FontAwesomeIcon icon={icon} />
                  {`  ${name}`}
                </button>
                {beta && (
                  <Tooltip title="This Feature is Under Development.">
                    <Chip label="Beta" size="small" variant="outlined" />
                  </Tooltip>
                )}
              </div>
            ) : (
              <></>
            )}
          </div>
        ))}

        {/* <div style={{ position: "absolute", bottom: 0, marginBottom: "15px" }}>
          <p className="sidebar-title">Other</p>
          <button
            className="btn btn-link btn-sm"
            style={{
              textDecoration: "none",
              cursor: "pointer",
            }}
          >
            <p className="m-0 pb-2">
              <FontAwesomeIcon icon={faClock} /> Release Note
            </p>
          </button>
        </div> */}
      </div>
    </div>
  );
};

export default Sidebar;
