import Box from "@mui/material/Box";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import LableWithFormField from "../../../components/label-with-formfield";
import { getResidentialDetails } from "../store/selector";
import { actions } from "../store/slice";

const ResidentialDetails = () => {
  const residentialAddress = useSelector(getResidentialDetails);
  const dispatch = useDispatch();
  const { address, landmark, hometown, city, stateName, nationality, pincode } =
    residentialAddress;

  const handleChange = (e) => {
    const { name, value } = e.target;
    dispatch(actions.setIsFormChanged(true));
    dispatch(
      actions.setResidentialAddress({
        ...residentialAddress,
        [name]: value,
      })
    );
  };

  const residentialAddressFields = [
    {
      type: "text",
      text: "Address",
      id: "address",
      name: "address",
      defaultValue: address,
      placeholder: "123, Xyz Street...",
      onChange: (e) => handleChange(e),
    },
    {
      type: "text",
      text: "Landmark",
      id: "landmark",
      name: "landmark",
      defaultValue: landmark,
      placeholder: "Near ...",
      onChange: (e) => handleChange(e),
    },
    {
      type: "text",
      text: "Hometown",
      id: "hometown",
      name: "hometown",
      defaultValue: hometown,
      placeholder: "Home Town",
      onChange: (e) => handleChange(e),
    },
    {
      type: "text",
      text: "City",
      id: "city",
      name: "city",
      defaultValue: city,
      placeholder: "Chennai",
      onChange: (e) => handleChange(e),
    },
    {
      type: "text",
      text: "State Name",
      id: "stateName",
      name: "stateName",
      defaultValue: stateName,
      placeholder: "Tamil Nadu",
      onChange: (e) => handleChange(e),
    },
    {
      type: "text",
      text: "Nationality",
      id: "nationality",
      name: "nationality",
      defaultValue: nationality,
      placeholder: "Indian",
      onChange: (e) => handleChange(e),
    },
    {
      type: "text",
      text: "Pincode",
      id: "pincode",
      name: "pincode",
      defaultValue: pincode,
      placeholder: "600 001",
      onChange: (e) => handleChange(e),
    },
  ];

  return (
    <Box
      sx={{
        flexGrow: 1,
        padding: "25px",
      }}
    >
      <div className="table-responsive">
        <table className="table">
          <tbody>
            {residentialAddressFields.map((props, index) => (
              <LableWithFormField key={index} {...props} />
            ))}
          </tbody>
        </table>
      </div>
    </Box>
  );
};

export default ResidentialDetails;
