import swal from "sweetalert2";

export const successToaster = (msg, timer) => {
  swal.fire({
    title: "Success",
    text: msg,
    icon: "success",
    // buttons: false,
    timer: timer ? timer : 2000,
    showConfirmButton: false,
    showCloseButton: true,
  });
};

export const warningToaster = (msg, timer) => {
  swal.fire({
    title: "Warning",
    text: msg,
    icon: "warning",
    // buttons: false,
    timer: timer ? timer : 2000,
    showConfirmButton: false,
    showCloseButton: true,
  });
};

export const errorToaster = (msg, timer) => {
  const error = msg || "Something went wrong";
  swal.fire({
    title: "Error",
    text: error,
    icon: "error",
    // buttons: false,
    timer: timer ? timer : 2000,
    showConfirmButton: false,
    showCloseButton: true,
  });
};

export const infoToaster = (msg, timer) => {
  const error = msg || "Something went wrong";
  swal.fire({
    title: "Info",
    text: error,
    icon: "info",
    // buttons: false,
    timer: timer ? timer : 2000,
    showConfirmButton: false,
    showCloseButton: true,
  });
};

export const questionToaster = (msg, timer) => {
  const error = msg || "Something went wrong";
  swal.fire({
    title: "Question",
    text: error,
    icon: "question",
    // buttons: false,
    timer: timer ? timer : 2000,
    showConfirmButton: false,
    showCloseButton: true,
  });
};
