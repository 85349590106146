import { createSelector } from "reselect";

const homeState = (state) => state.home;

const getCounter = createSelector(homeState, (home) => home.counter);
const getFeatures = createSelector(homeState, (home) => home.features);
const getBrideProfiles = createSelector(
  homeState,
  (home) => home.brideProfiles
);
const getGroomProfiles = createSelector(
  homeState,
  (home) => home.groomProfiles
);

export { getCounter, getFeatures, getBrideProfiles, getGroomProfiles };
