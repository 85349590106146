import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { confirmEmail } from "../../store/services";
import { errorToaster, successToaster } from "../../utils/alerts";

const VerifyEmail = () => {
  const { token } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    confirmEmail(token)
      .then((res) => {
        if (res.data.success) {
          successToaster(res.data.message, 4000);
          navigate("/profileedit");
        } else {
          errorToaster(res.data.message, 4000);
          navigate("/profileedit");
        }
      })
      .catch((err) => console.error("err", err));
  }, [navigate, token]);

  return <></>;
};

export default VerifyEmail;
