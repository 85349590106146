import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import LableWithFormField from "../../../components/label-with-formfield/lable-with-formfield";
import * as config from "../../../config/config";
import { getFamilyDetails } from "../store/selector";
import { actions } from "../store/slice";

const FamilyDetails = () => {
  const familyDetails = useSelector(getFamilyDetails);
  const dispatch = useDispatch();
  const {
    siblings,
    fatherName,
    fatherOccupation,
    fatherAlive,
    motherName,
    motherOccupation,
    motherAlive,
    religion,
    caste,
  } = familyDetails;
  const { brother, sister } = siblings;

  const handleChange = (e) => {
    const { name, value } = e.target;
    dispatch(actions.setIsFormChanged(true));
    if (name === "brotherNum") {
      dispatch(
        actions.setFamilyDetails({
          ...familyDetails,
          siblings: {
            brother: { ...brother, number: value },
            sister: { ...sister },
          },
        })
      );
    } else if (name === "sisterNum") {
      dispatch(
        actions.setFamilyDetails({
          ...familyDetails,
          siblings: {
            brother: { ...brother },
            sister: {
              ...sister,
              number: e.target.value,
            },
          },
        })
      );
    } else if (name === "brotherMarriageNum") {
      dispatch(
        actions.setFamilyDetails({
          ...familyDetails,
          siblings: {
            brother: {
              ...brother,
              marriageNum: e.target.value,
            },
            sister: { ...sister },
          },
        })
      );
    } else if (name === "sisterMarriedNum") {
      dispatch(
        actions.setFamilyDetails({
          ...familyDetails,
          siblings: {
            brother: { ...brother },
            sister: {
              ...sister,
              marriageNum: e.target.value,
            },
          },
        })
      );
    } else {
      dispatch(
        actions.setFamilyDetails({
          ...familyDetails,
          [name]: value,
        })
      );
    }
  };

  const FamilyDetailsFields = [
    {
      type: "text",
      text: "Father's Name",
      id: "fatherName",
      name: "fatherName",
      defaultValue: fatherName,
      placeholder: "father's name",
      onChange: (e) => handleChange(e),
    },
    {
      type: "select",
      text: "Father's Alive",
      id: "exampleFormControlSelect1-father_Alive",
      name: "fatherAlive",
      defaultValue: fatherAlive,
      onChange: (e) => handleChange(e),
      array: config.yesOrNo,
    },
    // Conditionally render Father's Occupation
    fatherAlive === "Yes" && {
      type: "text",
      text: "Father's Occupation",
      id: "fatherOccupation",
      name: "fatherOccupation",
      defaultValue: fatherOccupation,
      placeholder: "...",
      onChange: (e) => handleChange(e),
    },
    {
      type: "text",
      text: "Mother's Name",
      id: "motherName",
      name: "motherName",
      defaultValue: motherName,
      placeholder: "mother's name",
      onChange: (e) => handleChange(e),
    },
    {
      type: "select",
      text: "Mother's Alive",
      id: "exampleFormControlSelect1-mother_Alive",
      name: "motherAlive",
      defaultValue: motherAlive,
      onChange: (e) => handleChange(e),
      array: config.yesOrNo,
    },
    // Conditionally render Mother's Occupation
    motherAlive === "Yes" && {
      type: "text",
      text: "Mother's Occupation",
      id: "motherOccupation",
      name: "motherOccupation",
      defaultValue: motherOccupation,
      placeholder: "...",
      onChange: (e) => handleChange(e),
    },
    {
      type: "select",
      text: "Caste",
      id: "exampleFormControlSelect1-caste",
      name: "caste",
      defaultValue: caste,
      onChange: (e) => handleChange(e),
      array: config.caste,
    },
    {
      type: "select",
      text: "Religion",
      id: "exampleFormControlSelect1-religion",
      name: "religion",
      defaultValue: religion,
      onChange: (e) => handleChange(e),
      array: config.religion,
    },
  ];
  const familyDetailsFilter = FamilyDetailsFields.filter(Boolean);

  return (
    <Box
      sx={{
        flexGrow: 1,
        padding: "25px",
      }}
    >
      <div className="table-responsive">
        <Grid container spacing={2} mb={2}>
          <Grid
            item
            xs={2}
            style={{ alignItems: "center", display: "flex", fontWeight: 700 }}
          >
            Brother
          </Grid>
          <Grid item xs={5}>
            <div>Number</div>
            <div>
              <input
                type="text"
                className="form-control"
                id="brotherNum"
                aria-describedby="brotherNum"
                placeholder=""
                value={brother.number}
                name="brotherNum"
                onChange={(e) => handleChange(e)}
              />
            </div>
          </Grid>
          <Grid item xs={5}>
            <div>Married</div>
            <div>
              <input
                type="text"
                className="form-control"
                id="brotherMarriageNum"
                aria-describedby="brotherMarriageNum"
                placeholder=""
                value={brother.marriageNum}
                name="brotherMarriageNum"
                onChange={(e) => handleChange(e)}
              />
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={2} mb={2}>
          <Grid
            item
            xs={2}
            style={{ alignItems: "center", display: "flex", fontWeight: 700 }}
          >
            Sister
          </Grid>
          <Grid item xs={5}>
            <div>Number</div>
            <div>
              <input
                type="text"
                className="form-control"
                id="sisterNum"
                aria-describedby="sisterNum"
                placeholder=""
                value={sister.number}
                name="sisterNum"
                onChange={(e) => handleChange(e)}
              />
            </div>
          </Grid>
          <Grid item xs={5}>
            <div>Married</div>
            <div>
              <input
                type="text"
                className="form-control"
                id="sisterMarriedNum"
                aria-describedby="sisterMarriedNum"
                placeholder=""
                value={sister.marriageNum}
                name="sisterMarriedNum"
                onChange={(e) => handleChange(e)}
              />
            </div>
          </Grid>
        </Grid>
        <table className="table">
          <tbody>
            {familyDetailsFilter?.map((props, index) => (
              <LableWithFormField key={index} {...props} />
            ))}
          </tbody>
        </table>
      </div>
    </Box>
  );
};

export default FamilyDetails;
