import React from "react";
import { COLORS } from "../../constant";

const Message = ({ message, type }) => {
  return (
    <span style={{ color: COLORS?.[type], whiteSpace: "pre-line" }}>
      <small>{message}</small>
    </span>
  );
};

export default Message;
