import { createSlice } from "@reduxjs/toolkit";
import initialState from "./initial-state";

const { actions, reducer } = createSlice({
  name: "memberReducer",
  initialState,
  reducers: {
    setCounterIncrement(state) {
      state.counter += 1;
    },
    setCounterDecrement(state) {
      state.counter -= 1;
    },
    setMember(state, actions) {
      state.member = actions.payload;
    },
    setMemPersonalInfo(state, actions) {
      state.member.personalInfo = actions.payload;
    },
    setMemRasiKatam(state, actions) {
      state.member.rasiKatam = actions.payload;
    },
    setMemRasiPlanets(state, actions) {
      state.member.rasiPlanets = actions.payload;
    },
    setMemNavamsaKatam(state, actions) {
      state.member.navamsaKatam = actions.payload;
    },
    setMemNavamsaPlanets(state, actions) {
      state.member.navamsaPlanets = actions.payload;
    },
    setMemResidentialAddress(state, actions) {
      state.member.residentialAddress = actions.payload;
    },
    setMemContactDetails(state, actions) {
      state.member.contactDetails = actions.payload;
    },
    setMemOccupationDetails(state, actions) {
      state.member.occupation = actions.payload;
    },
    setMemHoroscopeDetails(state, actions) {
      state.member.horoscopeDetails = actions.payload;
    },
    setMemFamilyDetails(state, actions) {
      state.member.familyDetails = actions.payload;
    },
    setMemBirthDetails(state, actions) {
      state.member.birthDetails = actions.payload;
    },
    setMemQualification(state, actions) {
      state.member.qualification = actions.payload;
    },
    setVerificationDetails(state, actions) {
      state.member.verificationDetails = actions.payload;
    },
    setMemProfileDetails(state, actions) {
      state.createdBy = actions.payload.createdBy;
      state.horoscopeImage = actions.payload.horoscopeImage;
      state.lookingFor = actions.payload.lookingFor;
      state.ownHouse = actions.payload.ownHouse;
      state.startDate = actions.payload.startDate;
    },
  },
});

export { actions };
export default reducer;
