import axios from "axios";
import {
  COUNT,
  DEC_COUNT,
  EMAIL_CONFIRMATION,
  EMAIL_VERIFICATION,
  FORGET_PASSWORD,
  GOVT_ID_VERIFICATION,
  RESET_PASSWORD,
  SIGNIN,
  SIGNOUT,
  SIGNUP,
  SUBSCRIBE,
  VERIFY,
} from "../config/api-const";

export const onSignUp = async (userObj) =>
  await axios.post(SIGNUP, { userObj });

export const onSignIn = async ({ emailAddress, password }) =>
  await axios.post(SIGNIN, {
    emailAddress: emailAddress,
    password: password,
  });

export const onSignOut = async (token) =>
  token && (await axios.get(SIGNOUT + token));

export const verifyToken = async (token) =>
  token && (await axios.get(VERIFY + token));

export const subscribe = async (emailAddress) =>
  emailAddress && (await axios.post(SUBSCRIBE, { emailAddress: emailAddress }));

export const forgetPassword = async (emailAddress) =>
  emailAddress && (await axios.post(FORGET_PASSWORD, { emailAddress }));

export const resetPassword = async (token, password) =>
  token && password && (await axios.post(RESET_PASSWORD + token, { password }));

export const verifyEmail = async (id) =>
  id && (await axios.post(EMAIL_VERIFICATION, { id }));

export const confirmEmail = async (token) =>
  token && (await axios.post(EMAIL_CONFIRMATION + token));

export const createCount = async (id) =>
  id && (await axios.post(COUNT, { id }));

export const decrementCount = async (id, profileId) =>
  id && (await axios.put(`${DEC_COUNT}${id}/${profileId}`));

export const govtIdVerification = async (id) =>
  id && (await axios.put(GOVT_ID_VERIFICATION, { id }));
