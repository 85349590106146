export const setItem = (key, value) =>
  !key
    ? console.error("Error: Key is missing")
    : localStorage.setItem(key, JSON.stringify(value));

export const getItem = (key) =>
  !key
    ? console.log("Error: Key is missing")
    : JSON.parse(localStorage.getItem(key));

export const removeItem = (key) =>
  key === "" || !key
    ? console.log("Error: Key is missing")
    : localStorage.removeItem(key);
