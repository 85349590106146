import { createSlice } from "@reduxjs/toolkit";
import initialState from "./initial-state";

const { actions, reducer } = createSlice({
  name: "home",
  initialState,
  reducers: {
    setCounterIncrement(state) {
      state.counter += 1;
    },
    setCounterDecrement(state) {
      state.counter -= 1;
    },
  },
});

export { actions };
export default reducer;
