import Box from "@mui/material/Box";
import React from "react";
import { useSelector } from "react-redux";
import LableWithFormField from "../../../components/label-with-formfield";
import { getLang } from "../../../store/selector";
// import { actions } from "../store/slice";
const AboutPartner = () => {
  // const dispatch = useDispatch();
  const lang = useSelector(getLang);
  let lookingFor;

  const aboutMyPartnerFields = [
    {
      type: "text",
      text: "What we are looking for",
      id: "lookingFor",
      defaultValue: lookingFor,
      placeholder: "20",
    },
  ];

  return (
    <Box
      sx={{
        flexGrow: 1,
        padding: "25px",
      }}
    >
      <div className="table-responsive">
        <table className="table">
          <tbody>
            {aboutMyPartnerFields.map((props, index) => (
              <LableWithFormField key={index} {...props} />
            ))}
          </tbody>
        </table>
      </div>
    </Box>
  );
};

export default AboutPartner;
