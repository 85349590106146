import React from "react";
import { Td } from "./horoscope-boxes.styled";

const HoroscopeBoxes = ({
  size = "90px",
  katam,
  pillNum = false,
  title = "",
}) => {
  return (
    <table
      id="rasi"
      style={{
        borderRight: "1px solid #9b0b00",
        borderTop: "1px solid #9b0b00",
      }}
    >
      <tbody>
        <tr>
          <Td id="12" size={size}>
            {katam[11]}
            {pillNum && <span className="pill">12</span>}
          </Td>
          <Td id="1" size={size}>
            {katam[0]}
            {pillNum && <span className="pill">1</span>}
          </Td>
          <Td id="2" size={size}>
            {katam[1]}
            {pillNum && <span className="pill">2</span>}
          </Td>
          <Td id="3" size={size}>
            {katam[2]}
            {pillNum && <span className="pill">3</span>}
          </Td>
        </tr>
        <tr>
          <Td id="11" size={size}>
            {katam[10]}
            {pillNum && <span className="pill">11</span>}
          </Td>
          <Td
            colSpan={2}
            rowSpan={2}
            className="pbox_center"
            id="rasi_katam"
            size={size}
          >
            {pillNum && <span>{title}</span>}
          </Td>
          <Td id="4" size={size}>
            {katam[3]}
            {pillNum && <span className="pill">4</span>}
          </Td>
        </tr>
        <tr>
          <Td id="10" size={size}>
            {katam[9]}
            {pillNum && <span className="pill">10</span>}
          </Td>
          <Td id="5" size={size}>
            {katam[4]}
            {pillNum && <span className="pill">5</span>}
          </Td>
        </tr>
        <tr>
          <Td id="9" size={size}>
            {katam[8]}
            {pillNum && <span className="pill">9</span>}
          </Td>
          <Td id="8" size={size}>
            {katam[7]}
            {pillNum && <span className="pill">8</span>}
          </Td>
          <Td id="7" size={size}>
            {katam[6]}
            {pillNum && <span className="pill">7</span>}
          </Td>
          <Td id="6" size={size}>
            {katam[5]}
            {pillNum && <span className="pill">6</span>}
          </Td>
        </tr>
      </tbody>
    </table>
  );
};
export default HoroscopeBoxes;
