import axios from "axios";
import { MEMBER_SEARCH_FETCH } from "../../../config/api-const";

export const searchMemberProfile = async (params) => {
  try {
    return await axios.get(MEMBER_SEARCH_FETCH + params.token, {
      params,
    });
  } catch (err) {
    console.error(
      "Opps, Error in search Member Profile. Try after sometime later \n",
      err
    );
  }
};
