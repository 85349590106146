import Box from "@mui/material/Box";
import React from "react";
import LableWithFormField from "../../../components/label-with-formfield";
import * as config from "../../../config/config";
// import { actions } from "../store/slice";

const Religious = () => {
  // const dispatch = useDispatch();
  let religion, caste, dosham, star;

  const religiousFields = [
    {
      type: "select",
      text: "Religion",
      id: "religion",
      defaultValue: religion,
      placeholder: "20",
      array: config.religion,
    },
    {
      type: "select",
      text: "Caste",
      id: "caste",
      defaultValue: caste,
      placeholder: "20",
      array: config.caste,
    },
    {
      type: "select",
      text: "Dosham",
      id: "dosham",
      defaultValue: dosham,
      placeholder: "20",
      array: config.yesOrNo,
    },
    {
      type: "select",
      text: "Preferred Star",
      id: "star",
      defaultValue: star,
      placeholder: "20",
      array: config.nakshatra,
    },
  ];

  return (
    <Box
      sx={{
        flexGrow: 1,
        padding: "25px",
      }}
    >
      <div className="table-responsive">
        <table className="table">
          <tbody>
            {religiousFields.map((props, index) => (
              <LableWithFormField key={index} {...props} />
            ))}
          </tbody>
        </table>
      </div>
    </Box>
  );
};

export default Religious;
