export const languages = [
  {
    name: "English",
    code: "en",
    isComingSoon: false,
  },
  {
    name: "Assamese",
    family: "Indo-Aryan",
    code: "as",
    isComingSoon: true,
  },
  {
    name: "Bengali (Bangla)",
    family: "Indo-Aryan",
    code: "bn",
    isComingSoon: true,
  },
  {
    name: "Bodo",
    family: "Sino-Tibetan",
    code: "brx",
    isComingSoon: true,
  },
  {
    name: "Dogri",
    family: "Indo-Aryan",
    code: "doi",
    isComingSoon: true,
  },
  {
    name: "Gujarati",
    family: "Indo-Aryan",
    code: "gu",
    isComingSoon: true,
  },
  {
    name: "Hindi",
    family: "Indo-Aryan",
    code: "hi",
    isComingSoon: true,
  },
  {
    name: "Kannada",
    family: "Dravidian",
    code: "kn",
    isComingSoon: true,
  },
  {
    name: "Kashmiri",
    family: "Indo-Aryan",
    code: "ks",
    isComingSoon: true,
  },
  {
    name: "Konkani",
    family: "Indo-Aryan",
    code: "gom",
    isComingSoon: true,
  },
  {
    name: "Maithili",
    family: "Indo-Aryan",
    code: "mai",
    isComingSoon: true,
  },
  {
    name: "Malayalam",
    family: "Dravidian",
    code: "ml",
    isComingSoon: true,
  },
  {
    name: "Meitei (Manipuri)",
    family: "Sino-Tibetan",
    code: "mni",
    isComingSoon: true,
  },
  {
    name: "Marathi",
    family: "Indo-Aryan",
    code: "mr",
    isComingSoon: true,
  },
  {
    name: "Nepali",
    family: "Indo-Aryan",
    code: "ne",
    isComingSoon: true,
  },
  {
    name: "Odia",
    family: "Indo-Aryan",
    code: "or",
    isComingSoon: true,
  },
  {
    name: "Punjabi",
    family: "Indo-Aryan",
    code: "pa",
    isComingSoon: true,
  },
  {
    name: "Sanskrit",
    family: "Indo-Aryan",
    code: "sa",
    isComingSoon: true,
  },
  {
    name: "Santali",
    family: "Austroasiatic",
    code: "sat",
    isComingSoon: true,
  },
  {
    name: "Sindhi",
    family: "Indo-Aryan",
    code: "sd",
    isComingSoon: true,
  },
  {
    name: "Tamil",
    family: "Dravidian",
    code: "ta",
    isComingSoon: true,
  },
  {
    name: "Telugu",
    family: "Dravidian",
    code: "te",
    isComingSoon: true,
  },
  {
    name: "Urdu",
    family: "Indo-Aryan",
    code: "ur",
    isComingSoon: true,
  },
];

export const physicalStatus = [
  { value: "Normal" },
  { value: "Physically challenged" },
  { value: "Doesn't matter" },
];

export const eatingHabits = [
  { value: "Doesn't matter" },
  { value: "Vegetarian" },
  { value: "Non Vegetarian" },
  { value: "Eggetarian" },
  { value: "Vegan" },
];

export const drinkingHabits = [
  { value: "Doesn't matter" },
  { value: "Drinks a lot" },
  { value: "Drinks a little / Socially" },
  { value: "Doesn't drink at all" },
];

export const smokingHabits = [
  { value: "Doesn't matter" },
  { value: "Never smokes" },
  { value: "Smokes occasionally" },
  { value: "Smokes regularly" },
];

export const motherTongue = [
  { value: "Arunachali" },
  { value: "Assamese" },
  { value: "Awadhi" },
  { value: "Bengali" },
  { value: "Bhojpuri" },
  { value: "Brij" },
  { value: "Bihari" },
  { value: "Badaga" },
  { value: "Chatisgarhi" },
  { value: "Dogri" },
  { value: "English" },
  { value: "French" },
  { value: "Garhwali" },
  { value: "Garo" },
  { value: "Gujarati" },
  { value: "Haryanvi" },
  { value: "Himachali/Pahari" },
  { value: "Hindi" },
  { value: "Kanauji" },
  { value: "Kannada" },
  { value: "Kashmiri" },
  { value: "Khandesi" },
  { value: "Khasi" },
  { value: "Konkani" },
  { value: "Koshali" },
  { value: "Kumaoni" },
  { value: "Kutchi" },
  { value: "Lepcha" },
  { value: "Ladacki" },
  { value: "Magahi" },
  { value: "Maithili" },
  { value: "Malayalam" },
  { value: "Manipuri" },
  { value: "Marathi" },
  { value: "Marwari" },
  { value: "Miji" },
  { value: "Mizo" },
  { value: "Monpa" },
  { value: "Nicobarese" },
  { value: "Nepali" },
  { value: "Oriya" },
  { value: "Punjabi" },
  { value: "Rajasthani" },
  { value: "Sanskrit" },
  { value: "Santhali" },
  { value: "Sindhi" },
  { value: "Sourashtra" },
  { value: "Tamil" },
  { value: "Telugu" },
  { value: "Tripuri" },
  { value: "Tulu" },
  { value: "Urdu" },
  { value: "Bagri Rajasthani" },
  { value: "Dhundhari/Jaipuri" },
  { value: "Gujari/Gojari" },
  { value: "Harauti" },
  { value: "Lambadi" },
  { value: "Malvi" },
  { value: "Mewari" },
  { value: "Mewati/Ahirwati" },
  { value: "Nimadi" },
  { value: "Shekhawati" },
  { value: "Wagdi" },
];

export const twelveNumbers = [
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
];

export const officeContactDetails = [
  {
    title: "Location 1 - Head Office",
    emailId: "info@kalyanawaran.com",
    phone: "+919842393234",
    addressFirstLine: "515, Sallivan Street",
    addressSecondLine: "",
    city: "Coimbatore",
    pinCode: "641 001",
    isComingSoon: true,
  },
];

export const socialLinks = [];

export const Links = {
  terms: "/terms",
  privacy: "/privacy-policy",
  cookie: "/cookie-policy",
};

export const sortByValue = [
  { value: "None" },
  { value: "Name(A-Z)" },
  { value: "Name(Z-A)" },
  { value: "Age (0-9)" },
  { value: "Age (9-0)" },
  { value: "Created Date (latest)" },
  { value: "Created Date (oldest)" },
  { value: "Profile Availability" },
];

export const translations = {
  en: {
    birthDate: "Birth Date",
    birthPlace: "Birth Place",
    birthTime: "Birth Time",
    naligai: "Naligai",
    birthStar: "Birth Star",
    birthRasi: "Birth Rasi",
    thithi: "Thithi",
    lagnam: "Lagnam",
    day: "Day",
    tamilYear: "Tamil Year",
    tamilMonth: "Tamil Month",
    tamilDate: "Tamil Date",
  },
  ta: {
    birthDate: "ஆங்கில தேதி",
    tamilYear: "தமிழ் வருடம்",
    tamilMonth: "தமிழ் மாதம்",
    birthPlace: "பிறந்த ஊர்",
    birthTime: "பிறந்த நேரம்",
    naligai: "நாழிகை",
    birthStar: "நட்சித்திரம்",
    birthRasi: "ராசி",
    thithi: "திதி",
    lagnam: "லக்கினம்",
    day: "நாள்",
    tamilDate: "தமிழ் தேதி",
  },
};

export const ageLimit = [
  { value: 18 },
  { value: 19 },
  { value: 20 },
  { value: 21 },
  { value: 22 },
  { value: 23 },
  { value: 24 },
  { value: 25 },
  { value: 26 },
  { value: 27 },
  { value: 28 },
  { value: 29 },
  { value: 30 },
  { value: 31 },
  { value: 32 },
  { value: 33 },
  { value: 34 },
  { value: 35 },
  { value: 36 },
  { value: 37 },
  { value: 38 },
  { value: 39 },
  { value: 40 },
  { value: 41 },
  { value: 42 },
  { value: 43 },
  { value: 44 },
  { value: 45 },
  { value: 46 },
  { value: 47 },
  { value: 48 },
  { value: 49 },
  { value: 50 },
  { value: 51 },
  { value: 52 },
  { value: 53 },
  { value: 54 },
  { value: 55 },
  { value: 56 },
  { value: 57 },
  { value: 58 },
  { value: 59 },
  { value: 60 },
  { value: 61 },
  { value: 62 },
  { value: 63 },
  { value: 64 },
  { value: 65 },
  { value: 66 },
  { value: 67 },
  { value: 68 },
  { value: 69 },
  { value: 70 },
];

export const days = [
  { value: 1 },
  { value: 2 },
  { value: 3 },
  { value: 4 },
  { value: 5 },
  { value: 6 },
  { value: 7 },
  { value: 8 },
  { value: 9 },
  { value: 10 },
  { value: 11 },
  { value: 12 },
  { value: 13 },
  { value: 14 },
  { value: 15 },
  { value: 16 },
  { value: 17 },
  { value: 18 },
  { value: 19 },
  { value: 20 },
  { value: 21 },
  { value: 22 },
  { value: 23 },
  { value: 24 },
  { value: 25 },
  { value: 26 },
  { value: 27 },
  { value: 28 },
  { value: 29 },
  { value: 30 },
  { value: 31 },
];

export const tamilMonth = {
  en: [
    { value: "Chittirai" },
    { value: "Vaikaasi" },
    { value: "Aani" },
    { value: "Aadi" },
    { value: "Aavani" },
    { value: "Purattaasi" },
    { value: "Aippasi" },
    { value: "Kaartikai" },
    { value: "Maargali" },
    { value: "Tai" },
    { value: "Maagha" },
    { value: "Panguni" },
  ],
  ta: [
    { value: "சித்திரை" },
    { value: "வைகாசி" },
    { value: "ஆனி" },
    { value: "ஆடி" },
    { value: "ஆவணி" },
    { value: "புரட்டாசி" },
    { value: "ஐப்பசி" },
    { value: "கார்த்திகை" },
    { value: "மார்கழி" },
    { value: "தை" },
    { value: "மாசி" },
    { value: "பங்குனி" },
  ],
};

export const getTranslation = (lang, text) => {
  return translations[lang][text];
};

export const iAm = [{ value: "Groom" }, { value: "Bride" }];
export const lookingFor = [{ value: "Groom" }, { value: "Bride" }];
export const gender = [{ genderValue: "Male" }, { genderValue: "Female" }];
export const yesOrNo = [{ value: "Select" }, { value: "Yes" }, { value: "No" }];
export const dayOrder = [
  "sunday",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
];
export const weekday = {
  en: {
    sunday: "Sunday",
    monday: "Monday",
    tuesday: "Tuesday",
    wednesday: "Wednesday",
    thursday: "Thursday",
    friday: "Friday",
    saturday: "Saturday",
  },
  ta: {
    sunday: "ஞாயிற்றுக்கிழமை",
    monday: "திங்கட்கிழமை",
    tuesday: "செவ்வாய்க்கிழமை",
    wednesday: "புதன்கிழமை",
    thursday: "வியாழக்கிழமை",
    friday: "வெள்ளிக்கிழமை",
    saturday: "சனிக்கிழமை",
  },
};

export const tamilYear = {
  en: [
    { value: "Prabhāva" },
    { value: "Vibhāva" },
    { value: "Śuklā" },
    { value: "Pramadutā" },
    { value: "Prachopati" },
    { value: "Āṅgirasa" },
    { value: "Śrīmukha" },
    { value: "Bhava" },
    { value: "Yuva" },
    { value: "Dhātu" },
    { value: "Īśvara" },
    { value: "Vehudānya" },
    { value: "Pramāti" },
    { value: "Vikrama" },
    { value: "Viṣu" },
    { value: "Citrabhānu" },
    { value: "Subhānu" },
    { value: "Dhārana" },
    { value: "Partibhā" },
    { value: "Viya" },
    { value: "Sarvajit" },
    { value: "Sarvadhārī" },
    { value: "Virodhī" },
    { value: "Vikṛti" },
    { value: "Kara" },
    { value: "Nandhana" },
    { value: "Vijaya" },
    { value: "Jaya" },
    { value: "Manmatha" },
    { value: "Dhuṇmūkī" },
    { value: "Hēvilaṃbi" },
    { value: "Vilaṃbi" },
    { value: "Vikāri" },
    { value: "Śarvarī" },
    { value: "Plava" },
    { value: "Śubhakṛt" },
    { value: "Śobhakṛt" },
    { value: "Krodhī" },
    { value: "Viśvāvasuva" },
    { value: "Parapāva" },
    { value: "Plavaṅga" },
    { value: "Kīlaka" },
    { value: "Saumya" },
    { value: "Sādhāraṇa" },
    { value: "Virodhikṛti" },
    { value: "Paritapi" },
    { value: "Pramādīca" },
    { value: "Ānanda" },
    { value: "Rākṣasaḥ" },
    { value: "Nala" },
    { value: "Piṅgāla" },
    { value: "Kālayukti" },
    { value: "Siddhidātrī" },
    { value: "Rautrī" },
    { value: "Dhūnmatī" },
    { value: "Dundubhi" },
    { value: "Rudhirōtgāri" },
    { value: "Rākṣasī" },
    { value: "Krodhanā" },
    { value: "Akṣayā" },
  ],
  ta: [
    { value: "பிரபவ" },
    { value: "விபவ" },
    { value: "சுக்ல" },
    { value: "பிரமோதூத" },
    { value: "பிரசோற்பத்தி" },
    { value: "ஆங்கீரச" },
    { value: "ஸ்ரீமுக" },
    { value: "பவ" },
    { value: "யுவ" },
    { value: "தாது" },
    { value: "ஈஸ்வர" },
    { value: "வெகுதானிய" },
    { value: "பிரமாதி" },
    { value: "விக்ரம" },
    { value: "விஷு" },
    { value: "சித்திரபானு" },
    { value: "சுபானு" },
    { value: "தாரண" },
    { value: "பார்த்திப" },
    { value: "விய" },
    { value: "சர்வஜித்" },
    { value: "சர்வதாரி" },
    { value: "விரோதி" },
    { value: "விக்ருதி" },
    { value: "கர" },
    { value: "நந்தன" },
    { value: "விஜய" },
    { value: "ஜய" },
    { value: "மன்மத" },
    { value: "துன்முகி" },
    { value: "ஹேவிளம்பி" },
    { value: "விளம்பி" },
    { value: "விகாரி" },
    { value: "சார்வரி" },
    { value: "பிலவ" },
    { value: "சுபகிருது" },
    { value: "சோபக்ருத்" },
    { value: "க்ரோதி" },
    { value: "விசுவாசுவ" },
    { value: "பரபாவ" },
    { value: "ப்லவங்க" },
    { value: "கீலக" },
    { value: "சௌம்ய" },
    { value: "சாதாரண" },
    { value: "விரோதகிருது" },
    { value: "பரிதாபி" },
    { value: "பிரமாதீச" },
    { value: "ஆனந்த" },
    { value: "ராட்சச" },
    { value: "நள" },
    { value: "பிங்கள" },
    { value: "காளயுக்தி" },
    { value: "சித்தார்த்தி" },
    { value: "ரௌத்திரி" },
    { value: "துன்மதி" },
    { value: "துந்துபி" },
    { value: "ருத்ரோத்காரி" },
    { value: "ரக்தாட்சி" },
    { value: "க்ரோதன" },
    { value: "அட்சய" },
  ],
};

export const siblings = [
  { siblingsValue: "Brother" },
  { siblingsValue: "Sister" },
];

export const maritalStatus = [
  { value: "Never Married" },
  { value: "Select" },
  { value: "Widowed" },
  { value: "Married" },
  { value: "Divorced" },
  { value: "Awaiting Divorced" },
  { value: "Others" },
];

export const religion = [
  { value: "Religion - Any" },
  { value: "Hindu" },
  { value: "Muslim - All" },
  { value: "Muslim - Shia" },
  { value: "Muslim - Sunni" },
  { value: "Muslim - Others" },
  { value: "Christian" },
  { value: "Sikh" },
  { value: "Jain - All" },
  { value: "Jain - Digambar" },
  { value: "Jain - Shwetambar" },
  { value: "Jain - Others" },
  { value: "Parsi" },
  { value: "Buddhist" },
  { value: "Inter-Religion" },
];

export const createdBy = [
  { value: "Self" },
  { value: "Parent" },
  { value: "Others" },
];

export const rasi = [
  { value: "Mesham" },
  { value: "Rishabam" },
  { value: "Midhunam" },
  { value: "Kadagam" },
  { value: "Simam" },
  { value: "Kanni" },
  { value: "Tulam" },
  { value: "Viruchagam" },
  { value: "Dhanusu" },
  { value: "Magaram" },
  { value: "Kumbam" },
  { value: "Meenam" },
];

export const height = [
  { value: "4.5" },
  { value: "4.6" },
  { value: "4.7" },
  { value: "4.8" },
  { value: "4.9" },
  { value: "4.10" },
  { value: "4.11" },
  { value: "5" },
  { value: "5.1" },
  { value: "5.2" },
  { value: "5.3" },
  { value: "5.4" },
  { value: "5.5" },
  { value: "5.6" },
  { value: "5.7" },
  { value: "5.8" },
  { value: "5.9" },
  { value: "5.10" },
  { value: "5.11" },
  { value: "6" },
  { value: "6.1" },
  { value: "6.2" },
  { value: "6.3" },
  { value: "6.4" },
  { value: "6.5" },
  { value: "6.6" },
  { value: "6.7" },
  { value: "6.8" },
  { value: "6.9" },
  { value: "6.10" },
  { value: "6.11" },
  { value: "7" },
];

export const fieldOfStudy = [
  { value: "- Select education category -" },
  { value: "Aeronautical Engineering" },
  { value: "B.Arch" },
  { value: "BCA" },
  { value: "BE" },
  { value: "B.Plan" },
  { value: "B.Sc IT/ Computer Science" },
  { value: "B.Tech." },
  { value: "Other Bachelor Degree in Engineering / Computers" },
  { value: "B.S.(Engineering)" },
  { value: "M.Arch." },
  { value: "MCA" },
  { value: "ME" },
  { value: "M.Sc. IT / Computer Science" },
  { value: "M.S.(Engg.)" },
  { value: "M.Tech." },
  { value: "PGDCA" },
  { value: "Other Masters Degree in Engineering / Computers" },
  { value: "Aviation Degree" },
  { value: "B.A." },
  { value: "B.Com." },
  { value: "B.Ed." },
  { value: "BFA" },
  { value: "BFT" },
  { value: "BLIS" },
  { value: "B.M.M." },
  { value: "B.Sc." },
  { value: "B.S.W" },
  { value: "B.Phil." },
  { value: "Other Bachelor Degree in Arts / Science / Commerce" },
  { value: "M.A." },
  { value: "MCom" },
  { value: "M.Ed." },
  { value: "MFA" },
  { value: "MLIS" },
  { value: "M.Sc." },
  { value: "MSW" },
  { value: "M.Phil." },
  { value: "Other Master Degree in Arts / Science / Commerce" },
  { value: "BBA" },
  { value: "BFM (Financial Management)" },
  { value: "BHM (Hotel Management)" },
  { value: "Other Bachelor Degree in Management" },
  { value: "BHA / BHM (Hospital Administration)" },
  { value: "MBA" },
  { value: "MFM (Financial Management)" },
  { value: "MHM  (Hotel Management)" },
  { value: "MHRM (Human Resource Management)" },
  { value: "PGDM" },
  { value: "Other Master Degree in Management" },
  { value: "MHA / MHM (Hospital Administration)" },
  { value: "B.A.M.S." },
  { value: "BDS" },
  { value: "BHMS" },
  { value: "BSMS" },
  { value: "BUMS" },
  { value: "BVSc" },
  { value: "MBBS" },
  { value: "MDS" },
  { value: "MD / MS (Medical)" },
  { value: "MVSc " },
  { value: "MCh" },
  { value: "DNB" },
  { value: "BPharm" },
  { value: "BPT" },
  { value: "B.Sc. Nursing" },
  { value: "Other Bachelor Degree in Medicine" },
  { value: "M.Pharm" },
  { value: "MPT" },
  { value: "Other Master Degree in Medicine" },
  { value: "BGL" },
  { value: "B.L." },
  { value: "LL.B." },
  { value: "Other Bachelor Degree in Legal" },
  { value: "LL.M." },
  { value: "M.L." },
  { value: "Other Master Degree in  Legal" },
  { value: "CA" },
  { value: "CFA (Chartered Financial Analyst)" },
  { value: "CS" },
  { value: "ICWA" },
  { value: "Other Degree in Finance" },
  { value: "IAS" },
  { value: "IES" },
  { value: "IFS" },
  { value: "IRS" },
  { value: "IPS" },
  { value: "Other Degree in Service" },
  { value: "Ph.D." },
  { value: "DM" },
  { value: "Postdoctoral fellow" },
  { value: "Fellow of National Board (FNB)" },
  { value: "Diploma" },
  { value: "Polytechnic" },
  { value: "Trade School" },
  { value: "Others" },
  { value: "Higher Secondary School / High School" },
];

export const employedIn = [
  { value: "Any" },
  { value: "Private" },
  { value: "Government/PSU" },
  { value: "Business" },
  { value: "Defence" },
  { value: "Self Employed" },
  { value: "Not Working" },
];

export const occupation = [
  { value: "Administration" },
  { value: "Any" },
  { value: "Agriculture" },
  { value: "Airline" },
  { value: "Architecture & design" },
  { value: "Banking & finance" },
  { value: "Beauty & fashion" },
  { value: "Bpo & customer service" },
  { value: "Civil services" },
  { value: "Corporate professionals" },
  { value: "Defence" },
  { value: "Doctor" },
  { value: "Education & training" },
  { value: "Engineering" },
  { value: "Hospitality" },
  { value: "It & software" },
  { value: "Legal" },
  { value: "Media & entertainment" },
  { value: "Medical & healthcare-others" },
  { value: "Merchant navy" },
  { value: "Others" },
  { value: "Police / law enforcement" },
  { value: "Scientist" },
  { value: "Senior management" },
];

export const annualIncome = [
  { value: "Less than Rs.50 thousand" },
  { value: "Rs.50 thousand" },
  { value: "Rs.1 Lakh" },
  { value: "Rs.2 Lakhs" },
  { value: "Rs.3 Lakhs" },
  { value: "Rs.4 Lakhs" },
  { value: "Rs.5 Lakhs" },
  { value: "Rs.6 Lakhs" },
  { value: "Rs.7 Lakhs" },
  { value: "Rs.8 Lakhs" },
  { value: "Rs.9 Lakhs" },
  { value: "Rs.10 Lakhs" },
  { value: "Rs.12 Lakhs" },
  { value: "Rs.14 Lakhs" },
  { value: "Rs.16 Lakhs" },
  { value: "Rs.18 Lakhs" },
  { value: "Rs.20 Lakhs" },
  { value: "Rs.25 Lakhs" },
  { value: "Rs.30 Lakhs" },
  { value: "Rs.35 Lakhs" },
  { value: "Rs.40 Lakhs" },
  { value: "Rs.45 Lakhs" },
  { value: "Rs.50 Lakhs" },
  { value: "Rs.60 Lakhs" },
  { value: "Rs.70 Lakhs" },
  { value: "Rs.80 Lakhs" },
  { value: "Rs.90 Lakhs" },
  { value: "Rs.1 Crore" },
  { value: "Rs.1 Crore & Above" },
];

export const nakshatra = [
  { value: "Any" },
  { value: "Ashwini" },
  { value: "Bharani" },
  { value: "Krittika" },
  { value: "Rohini" },
  { value: "Mrigashirsha" },
  { value: "Ardra/Thiruvathirai" },
  { value: "Punarvasu" },
  { value: "Pushya" },
  { value: "Ashlesha/Ayilum" },
  { value: "Magha" },
  { value: "Purva Phalguni/Pooram" },
  { value: "Uttara Phalguni/Uthram" },
  { value: "Hasta" },
  { value: "Chitra" },
  { value: "Swati" },
  { value: "Vishakha" },
  { value: "Anuradha/Anusham" },
  { value: "Jyeshta/Kaetai" },
  { value: "Mula" },
  { value: "Purvashadha/Pooradam" },
  { value: "Uttarashadha/Uttradam" },
  { value: "Shravana/Thiruvonam" },
  { value: "Dhanistha/Avitam" },
  { value: "Shatabhishak/Sadhayam" },
  { value: "Purva Bhadrapada/Pooratadhi" },
  { value: "Uttara Bhadrapada/Uttratadhi" },
  { value: "Revati" },
];

export const bloodGroup = [
  { value: "A positive (A+)" },
  { value: "A negative (A-)" },
  { value: "B positive (B+)" },
  { value: "B negative (B-)" },
  { value: "O positive (O+)" },
  { value: "O negative (O-)" },
  { value: "AB positive (AB+)" },
  { value: "AB negative (AB-)" },
];

export const dosham = [{ value: "" }];

export const caste = [
  { value: "Caste / Division - Any" },
  { value: "24 Manai Telugu Chettiar" },
  {
    value: "96 Kuli Maratha",
  },
  {
    value: "Aaru Nattu Vellala",
  },
  {
    value: "Achirapakkam Chettiar",
  },
  {
    value: "Ad Dharmi",
  },
  {
    value: "Adi Andhra",
  },
  {
    value: "Adi Dravidar",
  },
  {
    value: "Adi Karnataka",
  },
  {
    value: "Adventist",
  },
  {
    value: "Agamudayar / Arcot / Thuluva Vellala",
  },
  {
    value: "Agaram Vellan Chettiar",
  },
  {
    value: "Agarwal",
  },
  {
    value: "Agnikula Kshatriya",
  },
  {
    value: "Agrahari",
  },
  {
    value: "Agri",
  },
  {
    value: "Aguri / Ugra Kshatriya",
  },
  {
    value: "Aheriya",
  },
  {
    value: "Ahir Shimpi",
  },
  {
    value: "Ahirwar",
  },
  {
    value: "Ahom",
  },
  {
    value: "Ambalavasi",
  },
  {
    value: "Amil Sindhi",
  },
  {
    value: "Amma Kodava",
  },
  {
    value: "Anavil Brahmin",
  },
  {
    value: "Anglican / Episcopal",
  },
  {
    value: "Anglo-Indian",
  },
  {
    value: "Anjana (Chowdary) Patel",
  },
  {
    value: "Apostolic",
  },
  {
    value: "Arakh / Arakvanshiya",
  },
  {
    value: "Aramari / Gabit",
  },
  {
    value: "Arekatica",
  },
  {
    value: "Arora",
  },
  {
    value: "Arunthathiyar",
  },
  {
    value: "Arya Vysya",
  },
  {
    value: "Asathi",
  },
  {
    value: "Assembly of God (AG)",
  },
  {
    value: "Audichya Brahmin",
  },
  {
    value: "Ayira Vysya",
  },
  {
    value: "Ayodhyavasi",
  },
  {
    value: "Ayyaraka",
  },
  {
    value: "Badaga",
  },
  {
    value: "Bagdi",
  },
  {
    value: "Baibhand Sindhi",
  },
  {
    value: "Baidya",
  },
  {
    value: "Bairwa",
  },
  {
    value: "Baishnab",
  },
  {
    value: "Baishya",
  },
  {
    value: "Bajantri",
  },
  {
    value: "Balai",
  },
  {
    value: "Balija",
  },
  {
    value: "Balija Naidu",
  },
  {
    value: "Balija Reddy",
  },
  {
    value: "Banayat Oriya",
  },
  {
    value: "Banik",
  },
  {
    value: "Baniya",
  },
  {
    value: "Baniya - Bania",
  },
  {
    value: "Baniya - Kumuti",
  },
  {
    value: "Banjara",
  },
  {
    value: "Baptist",
  },
  {
    value: "Barai",
  },
  {
    value: "Barendra Brahmin",
  },
  {
    value: "Bari",
  },
  {
    value: "Baria",
  },
  {
    value: "Barnwal",
  },
  {
    value: "Barujibi",
  },
  {
    value: "Beri Chettiar",
  },
  {
    value: "Besta",
  },
  {
    value: "Bhandari",
  },
  {
    value: "Bhanusali Sindhi",
  },
  {
    value: "Bhatia",
  },
  {
    value: "Bhatia Sindhi",
  },
  {
    value: "Bhatraju",
  },
  {
    value: "Bhatt Brahmin",
  },
  {
    value: "Bhavasar Kshatriya",
  },
  {
    value: "Bhil",
  },
  {
    value: "Bhoi",
  },
  {
    value: "Bhovi",
  },
  {
    value: "Bhoyar",
  },
  {
    value: "Bhulia / Meher",
  },
  {
    value: "Bhumihar Brahmin",
  },
  {
    value: "Billava",
  },
  {
    value: "Bishnoi/Vishnoi",
  },
  {
    value: "Bondili",
  },
  {
    value: "Born Again",
  },
  {
    value: "Boyar",
  },
  {
    value: "Brahmakshtriya",
  },
  {
    value: "Brahmbatt",
  },
  {
    value: "Brahmin - All",
  },
  {
    value: "Brahmin - Anavil",
  },
  {
    value: "Brahmin - Anaviln Desai",
  },
  {
    value: "Brahmin - Audichya",
  },
  {
    value: "Brahmin - Baidhiki/Vaidhiki",
  },
  {
    value: "Brahmin - Bardai",
  },
  {
    value: "Brahmin - Barendra",
  },
  {
    value: "Brahmin - Bhargav",
  },
  {
    value: "Brahmin - Bhatt",
  },
  {
    value: "Brahmin - Bhumihar",
  },
  {
    value: "Brahmin - Dadhich",
  },
  {
    value: "Brahmin - Daivadnya",
  },
  {
    value: "Brahmin - Danua",
  },
  {
    value: "Brahmin - Deshastha",
  },
  {
    value: "Brahmin - Dhiman",
  },
  {
    value: "Brahmin - Dravida",
  },
  {
    value: "Brahmin - Embrandiri",
  },
  {
    value: "Brahmin - Garhwali",
  },
  {
    value: "Brahmin - Gaur",
  },
  {
    value: "Brahmin - Goswami/Gosavi",
  },
  {
    value: "Brahmin - Gujar Gaur",
  },
  {
    value: "Brahmin - Gurukkal",
  },
  {
    value: "Brahmin - Halua",
  },
  {
    value: "Brahmin - Havyaka",
  },
  {
    value: "Brahmin - Hoysala",
  },
  {
    value: "Brahmin - Iyengar",
  },
  {
    value: "Brahmin - Iyer",
  },
  {
    value: "Brahmin - Jangid",
  },
  {
    value: "Brahmin - Jhadua",
  },
  {
    value: "Brahmin - Jyotish",
  },
  {
    value: "Brahmin - Kanyakubj",
  },
  {
    value: "Brahmin - Karhade",
  },
  {
    value: "Brahmin - Khadayata",
  },
  {
    value: "Brahmin - Khandelwal",
  },
  {
    value: "Brahmin - Khedaval",
  },
  {
    value: "Brahmin - Kokanastha",
  },
  {
    value: "Brahmin - Kota",
  },
  {
    value: "Brahmin - Koteshwara / Kota (Madhwa )",
  },
  {
    value: "Brahmin - Kulin",
  },
  {
    value: "Brahmin - Kumaoni",
  },
  {
    value: "Brahmin - Madhwa",
  },
  {
    value: "Brahmin - Maithil",
  },
  {
    value: "Brahmin - Mevada",
  },
  {
    value: "Brahmin - Modh",
  },
  {
    value: "Brahmin - Mohyal",
  },
  {
    value: "Brahmin - Nagar",
  },
  {
    value: "Brahmin - Namboodiri",
  },
  {
    value: "Brahmin - Narmadiya",
  },
  {
    value: "Brahmin - Niyogi",
  },
  {
    value: "Brahmin - Others",
  },
  {
    value: "Brahmin - Paliwal",
  },
  {
    value: "Brahmin - Panda",
  },
  {
    value: "Brahmin - Pandit",
  },
  {
    value: "Brahmin - Panicker",
  },
  {
    value: "Brahmin - Pareek",
  },
  {
    value: "Brahmin - Pushkarna",
  },
  {
    value: "Brahmin - Rajgor",
  },
  {
    value: "Brahmin - Rarhi",
  },
  {
    value: "Brahmin - Rarhi/Radhi",
  },
  {
    value: "Brahmin - Rigvedi",
  },
  {
    value: "Brahmin - Rudraj",
  },
  {
    value: "Brahmin - Sakaldwipi",
  },
  {
    value: "Brahmin - Sanadya",
  },
  {
    value: "Brahmin - Sanketi",
  },
  {
    value: "Brahmin - Saraswat",
  },
  {
    value: "Brahmin - Sarua",
  },
  {
    value: "Brahmin - Saryuparin",
  },
  {
    value: "Brahmin - Shivalli (Madhva)",
  },
  {
    value: "Brahmin - Shivhalli",
  },
  {
    value: "Brahmin - Shri Gaud",
  },
  {
    value: "Brahmin - Shri Mali",
  },
  {
    value: "Brahmin - Shrimali",
  },
  {
    value: "Brahmin - Shukla Yajurvedi",
  },
  {
    value: "Brahmin - Sikhwal",
  },
  {
    value: "Brahmin - Smartha",
  },
  {
    value: "Brahmin - Sri Vaishnava",
  },
  {
    value: "Brahmin - Stanika",
  },
  {
    value: "Brahmin - Tapodhan",
  },
  {
    value: "Brahmin - Tyagi",
  },
  {
    value: "Brahmin - Vaidiki",
  },
  {
    value: "Brahmin - Vaikhanasa",
  },
  {
    value: "Brahmin - Valam",
  },
  {
    value: "Brahmin - Velanadu",
  },
  {
    value: "Brahmin - Vyas",
  },
  {
    value: "Brahmin - Zalora",
  },
  {
    value: "Brahmin Jijhotia",
  },
  {
    value: "Brahmin Malviya",
  },
  {
    value: "Brajastha Maithil",
  },
  {
    value: "Brajastha Maithil",
  },
  {
    value: "Brethren",
  },
  {
    value: "Bunt (Shetty)",
  },
  {
    value: "CKP",
  },
  {
    value: "Calvinist",
  },
  {
    value: "Chakkala Nair",
  },
  {
    value: "Chalawadi and Holeya",
  },
  {
    value: "Chaldean Syrian (Assyrian)",
  },
  {
    value: "Chambhar",
  },
  {
    value: "Chandravanshi Kahar",
  },
  {
    value: "Charan",
  },
  {
    value: "Chasa",
  },
  {
    value: "Chattada Sri Vaishnava",
  },
  {
    value: "Chaturth",
  },
  {
    value: "Chaudary",
  },
  {
    value: "Chaurasia",
  },
  {
    value: "Chennadasar",
  },
  {
    value: "Cherakula Vellalar",
  },
  {
    value: "Chettiar",
  },
  {
    value: "Chhapru Sindhi",
  },
  {
    value: "Chhetri",
  },
  {
    value: "Chippolu (Mera)",
  },
  {
    value: "Choudhary",
  },
  {
    value: "Christian - Others",
  },
  {
    value: "Church of Christ",
  },
  {
    value: "Church of God",
  },
  {
    value: "Church of North India",
  },
  {
    value: "Church of South India",
  },
  {
    value: "Congregational",
  },
  {
    value: "Coorgi",
  },
  {
    value: "Dadu Sindhi",
  },
  {
    value: "Daivadnya Brahmin",
  },
  {
    value: "Danua Brahmin",
  },
  {
    value: "Darji",
  },
  {
    value: "Dasapalanjika / Kannada Saineegar",
  },
  {
    value: "Deshastha Brahmin",
  },
  {
    value: "Deshmukh",
  },
  {
    value: "Desikar",
  },
  {
    value: "Desikar Thanjavur",
  },
  {
    value: "Devadiga",
  },
  {
    value: "Devandra Kula Vellalar",
  },
  {
    value: "Devang Koshthi",
  },
  {
    value: "Devanga",
  },
  {
    value: "Devanga Chettiar",
  },
  {
    value: "Devar/Thevar/Mukkulathor",
  },
  {
    value: "Devrukhe Brahmin",
  },
  {
    value: "Dhanak",
  },
  {
    value: "Dhangar",
  },
  {
    value: "Dheevara",
  },
  {
    value: "Dhiman",
  },
  {
    value: "Dhiman Brahmin",
  },
  {
    value: "Dhoba",
  },
  {
    value: "Dhobi",
  },
  {
    value: "Dhor / Kakkayya",
  },
  {
    value: "Dommala",
  },
  {
    value: "Dosar / Dusra",
  },
  {
    value: "Dravida Brahmin",
  },
  {
    value: "Dumal",
  },
  {
    value: "Dusadh (Paswan)",
  },
  {
    value: "Ediga",
  },
  {
    value: "Ediga /Goud (Balija)",
  },
  {
    value: "Elur Chetty",
  },
  {
    value: "Embrandiri Brahmin",
  },
  {
    value: "Evangelist",
  },
  {
    value: "Ezhava",
  },
  {
    value: "Ezhava Panicker",
  },
  {
    value: "Ezhava Thandan",
  },
  {
    value: "Ezhuthachan",
  },
  {
    value: "Gabit",
  },
  {
    value: "Gahoi",
  },
  {
    value: "Gajula / Kavarai",
  },
  {
    value: "Ganda",
  },
  {
    value: "Gandha Vanika",
  },
  {
    value: "Gandla",
  },
  {
    value: "Gandla / Ganiga",
  },
  {
    value: "Ganiga",
  },
  {
    value: "Garhwali",
  },
  {
    value: "Garhwali Brahmin",
  },
  {
    value: "Gatti",
  },
  {
    value: "Gaur Brahmin",
  },
  {
    value: "Gavandi",
  },
  {
    value: "Gavara",
  },
  {
    value: "Gawali",
  },
  {
    value: "Ghisadi",
  },
  {
    value: "Ghumar",
  },
  {
    value: "Goala",
  },
  {
    value: "Goan",
  },
  {
    value: "Gomantak",
  },
  {
    value: "Gond",
  },
  {
    value: "Gondhali",
  },
  {
    value: "Gopal / Gopala",
  },
  {
    value: "Goswami/Gosavi Brahmin",
  },
  {
    value: "Goud",
  },
  {
    value: "Gounder",
  },
  {
    value: "Gounder - Kongu Vellala Gounder",
  },
  {
    value: "Gounder - Nattu Gounder",
  },
  {
    value: "Gounder - Others",
  },
  {
    value: "Gounder - Urali Gounder",
  },
  {
    value: "Gounder - Vanniya Kula Kshatriyar",
  },
  {
    value: "Gounder - Vettuva Gounder",
  },
  {
    value: "Gowda",
  },
  {
    value: "Gowda - Kuruba Gowda",
  },
  {
    value: "Gramani",
  },
  {
    value: "Gudia",
  },
  {
    value: "Gujar Gaur Brahmin",
  },
  {
    value: "Gujjar",
  },
  {
    value: "Gulahre",
  },
  {
    value: "Gupta",
  },
  {
    value: "Guptan",
  },
  {
    value: "Gurav",
  },
  {
    value: "Gurjar",
  },
  {
    value: "Gurukkal Brahmin",
  },
  {
    value: "Haihaivanshi",
  },
  {
    value: "Halba Koshti",
  },
  {
    value: "Halua Brahmin",
  },
  {
    value: "Havyaka Brahmin",
  },
  {
    value: "Heggade",
  },
  {
    value: "Helava",
  },
  {
    value: "Holar",
  },
  {
    value: "Hoysala Brahmin",
  },
  {
    value: "Hugar (Jeer)",
  },
  {
    value: "Hyderabadi Sindhi",
  },
  {
    value: "Illaththu Pillai",
  },
  {
    value: "Intercaste",
  },
  {
    value: "Irani",
  },
  {
    value: "Isai Vellalar",
  },
  {
    value: "Iyengar Brahmin",
  },
  {
    value: "Iyer Brahmin",
  },
  {
    value: "Jaalari",
  },
  {
    value: "Jacobite",
  },
  {
    value: "Jain - Agarwal",
  },
  {
    value: "Jain - Asati",
  },
  {
    value: "Jain - Ayodhyavasi",
  },
  {
    value: "Jain - Bagherwal",
  },
  {
    value: "Jain - Bania",
  },
  {
    value: "Jain - Barhiya",
  },
  {
    value: "Jain - Charanagare",
  },
  {
    value: "Jain - Chaturtha",
  },
  {
    value: "Jain - Dhakada",
  },
  {
    value: "Jain - Gahoi / Grihapati",
  },
  {
    value: "Jain - Golalare / Kharaua",
  },
  {
    value: "Jain - Golapurva",
  },
  {
    value: "Jain - Golsinghare",
  },
  {
    value: "Jain - Harada",
  },
  {
    value: "Jain - Humad / Humbad",
  },
  {
    value: "Jain - Intercaste",
  },
  {
    value: "Jain - Jaiswal",
  },
  {
    value: "Jain - KVO",
  },
  {
    value: "Jain - Kambhoja",
  },
  {
    value: "Jain - Kasar",
  },
  {
    value: "Jain - Kathanere",
  },
  {
    value: "Jain - Khandelwal",
  },
  {
    value: "Jain - Kharaua",
  },
  {
    value: "Jain - Kutchi",
  },
  {
    value: "Jain - Lamechu",
  },
  {
    value: "Jain - Nema",
  },
  {
    value: "Jain - Oswal",
  },
  {
    value: "Jain - Others",
  },
  {
    value: "Jain - Padmavati Porwal",
  },
  {
    value: "Jain - Palliwal",
  },
  {
    value: "Jain - Panchama",
  },
  {
    value: "Jain - Parmar",
  },
  {
    value: "Jain - Parwar / Paravara",
  },
  {
    value: "Jain - Porwad / Porwal",
  },
  {
    value: "Jain - Porwal",
  },
  {
    value: "Jain - Saitwal",
  },
  {
    value: "Jain - Samanar / Nayinar",
  },
  {
    value: "Jain - Samiya",
  },
  {
    value: "Jain - Sarak",
  },
  {
    value: "Jain - Shrimal",
  },
  {
    value: "Jain - Unspecified",
  },
  {
    value: "Jain - Upadhyaya",
  },
  {
    value: "Jain - Vaishya",
  },
  {
    value: "Jain - Veerwal",
  },
  {
    value: "Jaiswal",
  },
  {
    value: "Jandra",
  },
  {
    value: "Jangam",
  },
  {
    value: "Jangid Brahmin",
  },
  {
    value: "Jangra - Brahmin",
  },
  {
    value: "Jat",
  },
  {
    value: "Jatav",
  },
  {
    value: "Jeer",
  },
  {
    value: "Jehovah's Witnesses",
  },
  {
    value: "Jetty/Malla",
  },
  {
    value: "Jhadav",
  },
  {
    value: "Jhadua Brahmin",
  },
  {
    value: "Jijhotia Brahmin",
  },
  {
    value: "Jogi (Nath)",
  },
  {
    value: "Julaha",
  },
  {
    value: "Kachara",
  },
  {
    value: "Kadava Patel",
  },
  {
    value: "Kadia",
  },
  {
    value: "Kahar",
  },
  {
    value: "Kaibarta",
  },
  {
    value: "Kaikaala",
  },
  {
    value: "Kalal",
  },
  {
    value: "Kalanji",
  },
  {
    value: "Kalar",
  },
  {
    value: "Kalinga",
  },
  {
    value: "Kalinga Vysya",
  },
  {
    value: "Kalita",
  },
  {
    value: "Kalwar",
  },
  {
    value: "Kamboj",
  },
  {
    value: "Kamma",
  },
  {
    value: "Kamma Naidu",
  },
  {
    value: "Kanakkan Padanna",
  },
  {
    value: "Kandara",
  },
  {
    value: "Kansari",
  },
  {
    value: "Kansyakaar",
  },
  {
    value: "Kanyakubj Brahmin",
  },
  {
    value: "Kanykubj Bania",
  },
  {
    value: "Kapu",
  },
  {
    value: "Kapu Naidu",
  },
  {
    value: "Kapu Reddy",
  },
  {
    value: "Karakala Bhakthula",
  },
  {
    value: "Karana",
  },
  {
    value: "Karhade Brahmin",
  },
  {
    value: "Karkathar",
  },
  {
    value: "Karmakar",
  },
  {
    value: "Karni Bhakthula",
  },
  {
    value: "Karuneegar",
  },
  {
    value: "Kasar",
  },
  {
    value: "Kasaundhan",
  },
  {
    value: "Kashyap",
  },
  {
    value: "Kasukara",
  },
  {
    value: "Katiya",
  },
  {
    value: "Kavara",
  },
  {
    value: "Kavuthiyya/Ezhavathy",
  },
  {
    value: "Kayastha",
  },
  {
    value: "Kayastha (Bengali)",
  },
  {
    value: "Kerala Mudali",
  },
  {
    value: "Keshri (Kesarwani)",
  },
  {
    value: "Khandayat",
  },
  {
    value: "Khandelwal",
  },
  {
    value: "Kharwa",
  },
  {
    value: "Kharwar",
  },
  {
    value: "Khatik",
  },
  {
    value: "Khatri",
  },
  {
    value: "Kirar",
  },
  {
    value: "Knanaya",
  },
  {
    value: "Knanaya Catholic",
  },
  {
    value: "Knanaya Jacobite",
  },
  {
    value: "Kodava",
  },
  {
    value: "Kodikal Pillai",
  },
  {
    value: "Koiri",
  },
  {
    value: "Kokanastha Brahmin",
  },
  {
    value: "Kokanastha Maratha",
  },
  {
    value: "Koli",
  },
  {
    value: "Koli Mahadev",
  },
  {
    value: "Koli Patel",
  },
  {
    value: "Komti /Arya Vaishya",
  },
  {
    value: "Kongu Chettiar",
  },
  {
    value: "Kongu Nadar",
  },
  {
    value: "Kongu Vellala Gounder",
  },
  {
    value: "Konkani",
  },
  {
    value: "Korama",
  },
  {
    value: "Kori",
  },
  {
    value: "Kori/Koli",
  },
  {
    value: "Kosthi",
  },
  {
    value: "Kota Brahmin",
  },
  {
    value: "Krishnavaka",
  },
  {
    value: "Kshatriya",
  },
  {
    value: "Kshatriya Kurmi",
  },
  {
    value: "Kshatriya Raju",
  },
  {
    value: "Kudumbi",
  },
  {
    value: "Kulal",
  },
  {
    value: "Kulalar",
  },
  {
    value: "Kulin Brahmin",
  },
  {
    value: "Kulita",
  },
  {
    value: "Kumaoni Brahmin",
  },
  {
    value: "Kumaoni Rajput",
  },
  {
    value: "Kumawat",
  },
  {
    value: "Kumbhakar",
  },
  {
    value: "Kumbhar",
  },
  {
    value: "Kumhar",
  },
  {
    value: "Kummari",
  },
  {
    value: "Kunbi",
  },
  {
    value: "Kunbi Lonari",
  },
  {
    value: "Kunbi Maratha",
  },
  {
    value: "Kunbi Tirale",
  },
  {
    value: "Kuravan",
  },
  {
    value: "Kurmi",
  },
  {
    value: "Kurmi/Kurmi Kshatriya",
  },
  {
    value: "Kurni",
  },
  {
    value: "Kuruba",
  },
  {
    value: "Kuruhina Shetty",
  },
  {
    value: "Kuruhini Chetty",
  },
  {
    value: "Kurumbar",
  },
  {
    value: "Kuruva",
  },
  {
    value: "Kushwaha (Koiri)",
  },
  {
    value: "Kutchi",
  },
  {
    value: "Lad",
  },
  {
    value: "Lambadi",
  },
  {
    value: "Larai Sindhi",
  },
  {
    value: "Larkana Sindhi",
  },
  {
    value: "Latin Catholic",
  },
  {
    value: "Latter day saints",
  },
  {
    value: "Laxminarayan gola",
  },
  {
    value: "Leva patel",
  },
  {
    value: "Leva patil",
  },
  {
    value: "Linga Balija",
  },
  {
    value: "Lingayath",
  },
  {
    value: "Lodhi Rajput",
  },
  {
    value: "Lohana",
  },
  {
    value: "Lohana Sindhi",
  },
  {
    value: "Lohar",
  },
  {
    value: "Loniya",
  },
  {
    value: "Lubana",
  },
  {
    value: "Lutheran",
  },
  {
    value: "Madhesiya/Kanu/Halwai",
  },
  {
    value: "Madhwa Brahmin",
  },
  {
    value: "Madiga",
  },
  {
    value: "Mahajan",
  },
  {
    value: "Mahar",
  },
  {
    value: "Mahendra",
  },
  {
    value: "Maheshwari",
  },
  {
    value: "Maheshwari / Meshri",
  },
  {
    value: "Mahishya",
  },
  {
    value: "Mahor",
  },
  {
    value: "Mahuri",
  },
  {
    value: "Mair Rajput Swarnkar",
  },
  {
    value: "Maithil Brahmin",
  },
  {
    value: "Majabi",
  },
  {
    value: "Mala",
  },
  {
    value: "Malabar Independent Syrian Church",
  },
  {
    value: "Malankara Catholic",
  },
  {
    value: "Mali",
  },
  {
    value: "Mallah",
  },
  {
    value: "Malviya Brahmin",
  },
  {
    value: "Malwani",
  },
  {
    value: "Mangalorean",
  },
  {
    value: "Manipuri",
  },
  {
    value: "Manjapudur Chettiar",
  },
  {
    value: "Mannadiyar",
  },
  {
    value: "Mannan / Velan / Vannan",
  },
  {
    value: "Mapila",
  },
  {
    value: "Maratha",
  },
  {
    value: "Maratha Kshatriya",
  },
  {
    value: "Marthoma",
  },
  {
    value: "Maruthuvar",
  },
  {
    value: "Matang",
  },
  {
    value: "Mathur",
  },
  {
    value: "Mathur Vaishya",
  },
  {
    value: "Matia Patel",
  },
  {
    value: "Maurya / Shakya",
  },
  {
    value: "Meena",
  },
  {
    value: "Meenavar",
  },
  {
    value: "Meghwal",
  },
  {
    value: "Mehra",
  },
  {
    value: "Melkite",
  },
  {
    value: "Mennonite",
  },
  {
    value: "Mera",
  },
  {
    value: "Meru Darji",
  },
  {
    value: "Methodist",
  },
  {
    value: "Mochi",
  },
  {
    value: "Modak",
  },
  {
    value: "Modh Brahmin",
  },
  {
    value: "Modh Ghanchi",
  },
  {
    value: "Modi",
  },
  {
    value: "Modikarlu",
  },
  {
    value: "Mogaveera",
  },
  {
    value: "Mohyal Brahmin",
  },
  {
    value: "Moravian",
  },
  {
    value: "Mudaliyar",
  },
  {
    value: "Mudiraj",
  },
  {
    value: "Mukkulathor",
  },
  {
    value: "Munnuru Kapu",
  },
  {
    value: "Muslim - Ansari",
  },
  {
    value: "Muslim - Arain",
  },
  {
    value: "Muslim - Awan",
  },
  {
    value: "Muslim - Bohra",
  },
  {
    value: "Muslim - Dekkani",
  },
  {
    value: "Muslim - Dudekula",
  },
  {
    value: "Muslim - Hanafi",
  },
  {
    value: "Muslim - Jat",
  },
  {
    value: "Muslim - Khoja",
  },
  {
    value: "Muslim - Lebbai",
  },
  {
    value: "Muslim - Malik",
  },
  {
    value: "Muslim - Mapila",
  },
  {
    value: "Muslim - Maraicar",
  },
  {
    value: "Muslim - Memon",
  },
  {
    value: "Muslim - Mughal",
  },
  {
    value: "Muslim - Others",
  },
  {
    value: "Muslim - Pathan",
  },
  {
    value: "Muslim - Qureshi",
  },
  {
    value: "Muslim - Rajput",
  },
  {
    value: "Muslim - Rowther",
  },
  {
    value: "Muslim - Shafi",
  },
  {
    value: "Muslim - Sheikh",
  },
  {
    value: "Muslim - Siddiqui",
  },
  {
    value: "Muslim - Syed",
  },
  {
    value: "Muslim - UnSpecified",
  },
  {
    value: "Musukama",
  },
  {
    value: "Muthuraja",
  },
  {
    value: "Naagavamsam",
  },
  {
    value: "Nabit",
  },
  {
    value: "Nadar",
  },
  {
    value: "Nagar Brahmin",
  },
  {
    value: "Nagaralu",
  },
  {
    value: "Nai / Nayi Brahmin",
  },
  {
    value: "Naicker",
  },
  {
    value: "Naicker - Others",
  },
  {
    value: "Naicker - Vanniya Kula Kshatriyar",
  },
  {
    value: "Naidu",
  },
  {
    value: "Naik",
  },
  {
    value: "Naika",
  },
  {
    value: "Nair",
  },
  {
    value: "Namasudra / Namassej",
  },
  {
    value: "Nambiar",
  },
  {
    value: "Namboodiri Brahmin",
  },
  {
    value: "Namdarlu",
  },
  {
    value: "Nanjil Mudali",
  },
  {
    value: "Nanjil Nattu Vellalar",
  },
  {
    value: "Nanjil Vellalar",
  },
  {
    value: "Nanjil pillai",
  },
  {
    value: "Nankudi Vellalar",
  },
  {
    value: "Napit",
  },
  {
    value: "Narmadiya Brahmin",
  },
  {
    value: "Nath",
  },
  {
    value: "Nattu Gounder",
  },
  {
    value: "Nattukottai Chettiar",
  },
  {
    value: "Nayaka",
  },
  {
    value: "Neeli",
  },
  {
    value: "Neeli Saali",
  },
  {
    value: "Nema",
  },
  {
    value: "Neo Buddhist",
  },
  {
    value: "Nepali",
  },
  {
    value: "Nessi",
  },
  {
    value: "Nhavi",
  },
  {
    value: "Niari",
  },
  {
    value: "Niyogi Brahmin",
  },
  {
    value: "Not Specified",
  },
  {
    value: "Odan",
  },
  {
    value: "Ontari",
  },
  {
    value: "Oraon",
  },
  {
    value: "Orthodox",
  },
  {
    value: "Oswal",
  },
  {
    value: "Otari",
  },
  {
    value: "Others",
  },
  {
    value: "Othuvaar",
  },
  {
    value: "Padmasali",
  },
  {
    value: "Padmashali",
  },
  {
    value: "Padmavati Porwal",
  },
  {
    value: "Pagadala",
  },
  {
    value: "Pal",
  },
  {
    value: "Pallan / Devandra Kula Vellalan",
  },
  {
    value: "Panan",
  },
  {
    value: "Panchal",
  },
  {
    value: "Panda Brahmin",
  },
  {
    value: "Pandaram",
  },
  {
    value: "Pandit Brahmin",
  },
  {
    value: "Pandiya Vellalar",
  },
  {
    value: "Panicker",
  },
  {
    value: "Pannirandam Chettiar",
  },
  {
    value: "Paravan / Bharatar",
  },
  {
    value: "Parit",
  },
  {
    value: "Parkavakulam / Udayar",
  },
  {
    value: "Parsi",
  },
  {
    value: "Partraj",
  },
  {
    value: "Parvatha Rajakulam",
  },
  {
    value: "Pasi",
  },
  {
    value: "Paswan",
  },
  {
    value: "Paswan / Dusadh",
  },
  {
    value: "Patel",
  },
  {
    value: "Pathare Prabhu",
  },
  {
    value: "Patil",
  },
  {
    value: "Patnaick/Sistakaranam",
  },
  {
    value: "Patra",
  },
  {
    value: "Pattinavar",
  },
  {
    value: "Pattusali",
  },
  {
    value: "Patwa",
  },
  {
    value: "Pentecost",
  },
  {
    value: "Perika",
  },
  {
    value: "Perika/Puragiri Kshatriya",
  },
  {
    value: "Pillai",
  },
  {
    value: "Poddar",
  },
  {
    value: "Poosala",
  },
  {
    value: "Porwal",
  },
  {
    value: "Porwal / Porwar",
  },
  {
    value: "Poundra",
  },
  {
    value: "Prajapati",
  },
  {
    value: "Presbyterian",
  },
  {
    value: "Protestant",
  },
  {
    value: "Pulaya / Cheruman",
  },
  {
    value: "Pushkarna Brahmin",
  },
  {
    value: "Radhi / Niari",
  },
  {
    value: "Raigar",
  },
  {
    value: "Rajaka / Vannar",
  },
  {
    value: "Rajastani",
  },
  {
    value: "Rajbhar",
  },
  {
    value: "Rajbonshi",
  },
  {
    value: "Rajpurohit",
  },
  {
    value: "Rajput",
  },
  {
    value: "Raju / Kshatriya Raju",
  },
  {
    value: "Ramanandi",
  },
  {
    value: "Ramdasia",
  },
  {
    value: "Ramgariah",
  },
  {
    value: "Ramoshi",
  },
  {
    value: "Rarhi Brahmin",
  },
  {
    value: "Rastogi",
  },
  {
    value: "Rathi",
  },
  {
    value: "Rauniar",
  },
  {
    value: "Ravidasia",
  },
  {
    value: "Rawat",
  },
  {
    value: "Reddy",
  },
  {
    value: "Reformed Baptist",
  },
  {
    value: "Reformed Presbyterian",
  },
  {
    value: "Relli",
  },
  {
    value: "Rigvedi Brahmin",
  },
  {
    value: "Rohiri Sindhi",
  },
  {
    value: "Rohit / Chamar",
  },
  {
    value: "Roman Catholic",
  },
  {
    value: "Ror",
  },
  {
    value: "Rudraj Brahmin",
  },
  {
    value: "SC",
  },
  {
    value: "SKP",
  },
  {
    value: "ST",
  },
  {
    value: "Sadgope",
  },
  {
    value: "Sadhu Chetty",
  },
  {
    value: "Sagara (Uppara)",
  },
  {
    value: "Saha",
  },
  {
    value: "Sahiti Sindhi",
  },
  {
    value: "Sahu",
  },
  {
    value: "Saini",
  },
  {
    value: "Saiva Pillai Thanjavur",
  },
  {
    value: "Saiva Pillai Tirunelveli",
  },
  {
    value: "Saiva Vellan chettiar",
  },
  {
    value: "Sakaldwipi Brahmin",
  },
  {
    value: "Sakkhar Sindhi",
  },
  {
    value: "Saliya",
  },
  {
    value: "Saliyar",
  },
  {
    value: "Samagar",
  },
  {
    value: "Sambava",
  },
  {
    value: "Sanadya Brahmin",
  },
  {
    value: "Sanketi Brahmin",
  },
  {
    value: "Saraswat Brahmin",
  },
  {
    value: "Saryuparin Brahmin",
  },
  {
    value: "Sathwara",
  },
  {
    value: "Satnami",
  },
  {
    value: "Savji",
  },
  {
    value: "Sehwani Sindhi",
  },
  {
    value: "Senai Thalaivar",
  },
  {
    value: "Senguntha Mudaliyar",
  },
  {
    value: "Sengunthar/Kaikolar",
  },
  {
    value: "Settibalija",
  },
  {
    value: "Setty Balija",
  },
  {
    value: "Seventh-day Adventist",
  },
  {
    value: "Shah",
  },
  {
    value: "Shaw / Sahu/Teli",
  },
  {
    value: "Shettigar",
  },
  {
    value: "Shetty",
  },
  {
    value: "Shikarpuri Sindhi",
  },
  {
    value: "Shilpkar",
  },
  {
    value: "Shimpi/Namdev",
  },
  {
    value: "Shivhalli Brahmin",
  },
  {
    value: "Shrimali Brahmin",
  },
  {
    value: "Sikh - Ahluwalia",
  },
  {
    value: "Sikh - Arora",
  },
  {
    value: "Sikh - Bhatia",
  },
  {
    value: "Sikh - Bhatra",
  },
  {
    value: "Sikh - Ghumar",
  },
  {
    value: "Sikh - Intercaste",
  },
  {
    value: "Sikh - Jat",
  },
  {
    value: "Sikh - Kamboj",
  },
  {
    value: "Sikh - Khatri",
  },
  {
    value: "Sikh - Kshatriya",
  },
  {
    value: "Sikh - Lubana",
  },
  {
    value: "Sikh - Majabi",
  },
  {
    value: "Sikh - Nai",
  },
  {
    value: "Sikh - Others",
  },
  {
    value: "Sikh - Rai",
  },
  {
    value: "Sikh - Rajput",
  },
  {
    value: "Sikh - Ramdasia",
  },
  {
    value: "Sikh - Ramgharia",
  },
  {
    value: "Sikh - Ravidasia",
  },
  {
    value: "Sikh - Saini",
  },
  {
    value: "Sikh - Tonk Kshatriya",
  },
  {
    value: "Sikh - Unspecified",
  },
  {
    value: "Sikhwal Brahmin",
  },
  {
    value: "Sindhi",
  },
  {
    value: "Sindhi-Amil",
  },
  {
    value: "Sindhi-Baibhand",
  },
  {
    value: "Sindhi-Bhanusali",
  },
  {
    value: "Sindhi-Bhatia",
  },
  {
    value: "Sindhi-Chhapru",
  },
  {
    value: "Sindhi-Dadu",
  },
  {
    value: "Sindhi-Hyderabadi",
  },
  {
    value: "Sindhi-Larai",
  },
  {
    value: "Sindhi-Larkana",
  },
  {
    value: "Sindhi-Lohana",
  },
  {
    value: "Sindhi-Rohiri",
  },
  {
    value: "Sindhi-Sahiti",
  },
  {
    value: "Sindhi-Sakkhar",
  },
  {
    value: "Sindhi-Sehwani",
  },
  {
    value: "Sindhi-Shikarpuri",
  },
  {
    value: "Sindhi-Thatai",
  },
  {
    value: "Smartha Brahmin",
  },
  {
    value: "Sonar",
  },
  {
    value: "Soni",
  },
  {
    value: "Sonkar",
  },
  {
    value: "Sourashtra",
  },
  {
    value: "Sozhia Chetty",
  },
  {
    value: "Sozhiya Vellalar",
  },
  {
    value: "Sri Vaishnava Brahmin",
  },
  {
    value: "Srisayana",
  },
  {
    value: "St. Thomas Evangelical",
  },
  {
    value: "Stanika Brahmin",
  },
  {
    value: "Sugali (Naika)",
  },
  {
    value: "Sunari",
  },
  {
    value: "Sundhi",
  },
  {
    value: "Surya Balija",
  },
  {
    value: "Suthar",
  },
  {
    value: "Swakula Sali",
  },
  {
    value: "Swarnakar",
  },
  {
    value: "Syrian Catholic",
  },
  {
    value: "Syrian Jacobite",
  },
  {
    value: "Syrian Orthodox",
  },
  {
    value: "Syro Malabar",
  },
  {
    value: "Tamboli",
  },
  {
    value: "Tammali",
  },
  {
    value: "Tanti",
  },
  {
    value: "Tantubai",
  },
  {
    value: "Telaga",
  },
  {
    value: "Teli",
  },
  {
    value: "Telugupatti",
  },
  {
    value: "Thakkar",
  },
  {
    value: "Thakore",
  },
  {
    value: "Thakur",
  },
  {
    value: "Thandan",
  },
  {
    value: "Tharakan",
  },
  {
    value: "Thatai Sindhi",
  },
  {
    value: "Thigala",
  },
  {
    value: "Thiyya",
  },
  {
    value: "Thiyya Thandan",
  },
  {
    value: "Thogata Veera Kshatriya",
  },
  {
    value: "Thogata Veerakshathriya",
  },
  {
    value: "Thondai Mandala Vellalar",
  },
  {
    value: "Thota",
  },
  {
    value: "Tibetan Buddhist",
  },
  {
    value: "Tili",
  },
  {
    value: "Togata",
  },
  {
    value: "Tonk Kshatriya",
  },
  {
    value: "Turupu Kapu",
  },
  {
    value: "Tyagi Brahmin",
  },
  {
    value: "Ummar / Umre / Bagaria",
  },
  {
    value: "Urali Gounder",
  },
  {
    value: "Urs",
  },
  {
    value: "Vada Balija",
  },
  {
    value: "Vadambar",
  },
  {
    value: "Vadar",
  },
  {
    value: "Vaddera",
  },
  {
    value: "Vadugan",
  },
  {
    value: "Vaidiki Brahmin",
  },
  {
    value: "Vaikhanasa Brahmin",
  },
  {
    value: "Vaish",
  },
  {
    value: "Vaishnav",
  },
  {
    value: "Vaishnav Dishaval",
  },
  {
    value: "Vaishnav Kapol",
  },
  {
    value: "Vaishnav Khadyata",
  },
  {
    value: "Vaishnav Lad",
  },
  {
    value: "Vaishnav Modh",
  },
  {
    value: "Vaishnav Porvad",
  },
  {
    value: "Vaishnav Shrimali",
  },
  {
    value: "Vaishnav Sorathaiya",
  },
  {
    value: "Vaishnav Vania",
  },
  {
    value: "Vaishnava",
  },
  {
    value: "Vaishya",
  },
  {
    value: "Vaishya Vani",
  },
  {
    value: "Valar",
  },
  {
    value: "Valluvan",
  },
  {
    value: "Valmiki",
  },
  {
    value: "Valsad",
  },
  {
    value: "Vani",
  },
  {
    value: "Vani / Vaishya",
  },
  {
    value: "Vania",
  },
  {
    value: "Vanika Vyshya",
  },
  {
    value: "Vaniya",
  },
  {
    value: "Vaniya Chettiar",
  },
  {
    value: "Vanjara",
  },
  {
    value: "Vanjari",
  },
  {
    value: "Vankar",
  },
  {
    value: "Vannar",
  },
  {
    value: "Vannia Kula Kshatriyar",
  },
  {
    value: "Vanyakulakshatriya",
  },
  {
    value: "Variar",
  },
  {
    value: "Varshney",
  },
  {
    value: "Varshney (Barahseni)",
  },
  {
    value: "Veera Saivam",
  },
  {
    value: "Veerakodi Vellala",
  },
  {
    value: "Velaan",
  },
  {
    value: "Velama",
  },
  {
    value: "Velanadu Brahmin",
  },
  {
    value: "Vellala Pillai",
  },
  {
    value: "Vellalar",
  },
  {
    value: "Vellan Chettiars",
  },
  {
    value: "Veluthedathu Nair",
  },
  {
    value: "Vettuva Gounder",
  },
  {
    value: "Vettuvan",
  },
  {
    value: "Vijayvargia",
  },
  {
    value: "Vilakithala Nair",
  },
  {
    value: "Vilakkithala Nair",
  },
  {
    value: "Vishwakarma",
  },
  {
    value: "Viswabrahmin",
  },
  {
    value: "Vokkaliga",
  },
  {
    value: "Vyas Brahmin",
  },
  {
    value: "Vysya",
  },
  {
    value: "Yadav",
  },
  {
    value: "Yadava Naidu",
  },
  {
    value: "Yellapu",
  },
  {
    value: "Caste no bar",
  },
];
