import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { errorToaster, successToaster } from "../utils/alerts";
import { getPersonalInfo } from "../view/profile/store/selector";
import { actions } from "../view/profile/store/slice";

const useFileUpload = () => {
  const dispatch = useDispatch();
  const personalInfo = useSelector(getPersonalInfo);

  const fileUpload = async (e, successMessage, fieldName) => {
    const file = e.target.files[0];
    const validImageTypes = ["image/jpeg", "image/png", "image/gif"];
    if (!validImageTypes.includes(file.type)) {
      errorToaster("Please select a valid image file (JPEG, PNG, GIF).");
      return (e.target.value = "");
    }

    const fileSizeInMB = file.size / 1024 / 1024;
    if (fileSizeInMB > 2) {
      errorToaster("File size exceeds 2MB");
      return (e.target.value = "");
    }

    const url = `${process.env.REACT_APP_CLOUD_URL}`;
    const preset = `${process.env.REACT_APP_CLOUD_PRESET}`;
    var formData = new FormData();
    formData.append("file", e.target.files[0]);
    formData.append("upload_preset", preset);
    await axios({
      url: url,
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: formData,
    })
      .then((res) => {
        successToaster(successMessage);
        dispatch(actions.setIsFormChanged(true));
        if (fieldName === "horoscopeImage") {
          dispatch(
            actions.setProfileDetails({ horoscopeImage: res.data.secure_url })
          );
        }
        if (fieldName === "personalInfo") {
          dispatch(
            actions.setPersonalInfo({
              ...personalInfo,
              profileImage: res.data.secure_url,
            })
          );
        }
        if (fieldName === "govtId") {
          dispatch(actions.setGovtId(res.data.secure_url));
        }
      })
      .catch((err) => {
        console.log(
          "🚀 ~ file: personal-info.js:59 ~ uploadToCloudForProfileImage ~ err:",
          err
        );
        errorToaster("Sorry,Error in Uploading image...Try again later", err);
      });
  };
  return { fileUpload };
};

export default useFileUpload;
