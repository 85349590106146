import {
  faEnvelope,
  faIdCard,
  faMobileScreen,
} from "@fortawesome/free-solid-svg-icons";
import { Card } from "@mui/material";
import React, { useEffect } from "react";
import ReactGA from "react-ga";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import SEO from "../../../components/seo";
import VerificationIcons from "../../../components/verification-icon/verification-icon";
import { PLACEHOLDER_IMAGE } from "../../../constant";
import { getCopyLink } from "../../../store/selector";
import { getItem } from "../../../utils/local-storage";
import { getMemberDetails } from "../store/selector";
import { searchMemberProfile } from "../store/services";
import { actions } from "../store/slice";

const MembersPage = (props) => {
  const memberObj = useSelector(getMemberDetails);
  const dispatch = useDispatch();
  const location = useLocation();
  const copyLink = useSelector(getCopyLink);
  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);

  const {
    personalInfo,
    birthDetails,
    residentialAddress,
    qualification,
    occupation,
    horoscopeDetails,
    familyDetails,
    contactDetails,
    horoscopeImage,
    lookingFor,
    verificationDetails,
    // ownHouse,
    // rasiKatam,
    // rasiPlanets,
    // navamsaKatam,
    // navamsaPlanets,
    createdBy,
    // startDate,
  } = memberObj;
  const {
    name,
    iAm,
    // aboutMe,
    height,
    weight,
    profileImage,
    bloodGroup,
    complextion,
    maritalStatus,
  } = personalInfo;
  const {
    dob,
    // tamilYear, tamilMonth, tamilDate
  } = birthDetails;
  const {
    dateOfBirth,
    placeOfBirth,
    age,
    // time, day
  } = dob;
  const {
    address,
    landmark,
    hometown,
    // city,
    stateName,
    nationality,
    pincode,
  } = residentialAddress;
  const { fieldOfStudy, degree } = qualification;
  const {
    emailVerificationDetails,
    mobileVerificationDetails,
    govtIdVerificationDetails,
  } = verificationDetails;
  const { companyName, designation, annualIncome, cityOfWorking } = occupation;
  const {
    rasi,
    nakshatra,
    gothram,
    // mathulam,
    lagnam,
    naaligai,
    // thithi,
    // dasaPlanet,
    // dasaBalance,
  } = horoscopeDetails;
  const {
    fatherName,
    fatherOccupation,
    // fatherAlive,
    motherName,
    motherOccupation,
    // motherAlive,
    religion,
    caste,
    // siblings,
  } = familyDetails;
  // const { brother, sister } = siblings;
  const {
    // emailId,
    contactNumber,
    whatsappNumber,
    // alternativeContactNumber
  } = contactDetails;

  useEffect(() => {
    searchMemberProfile({
      token: getItem("the_main_app"),
      memberId: location.pathname.split("/")[2],
    })
      .then((response) => {
        const {
          birthDetails,
          createdBy,
          contactDetails,
          familyDetails,
          horoscopeDetails,
          horoscopeImage,
          lookingFor,
          navamsaKatam,
          navamsaPlanets,
          occupation,
          ownHouse,
          personalInfo,
          qualification,
          verificationDetails,
          rasiKatam,
          rasiPlanets,
          residentialAddress,
          startDate,
        } = response?.data?.values[0];

        dispatch(actions.setMemBirthDetails(birthDetails));
        dispatch(actions.setMemContactDetails(contactDetails));
        dispatch(actions.setMemFamilyDetails(familyDetails));
        dispatch(actions.setMemHoroscopeDetails(horoscopeDetails));
        dispatch(actions.setMemPersonalInfo(personalInfo));
        dispatch(actions.setMemOccupationDetails(occupation));
        dispatch(actions.setMemQualification(qualification));
        dispatch(actions.setVerificationDetails(verificationDetails));
        dispatch(actions.setMemRasiKatam(rasiKatam));
        dispatch(actions.setMemRasiPlanets(rasiPlanets));
        dispatch(actions.setMemNavamsaKatam(navamsaKatam));
        dispatch(actions.setMemNavamsaPlanets(navamsaPlanets));
        dispatch(actions.setMemResidentialAddress(residentialAddress));
        dispatch(
          actions.setMemProfileDetails({
            createdBy,
            horoscopeImage,
            lookingFor,
            ownHouse,
            startDate,
          })
        );
      })
      .catch((err) => console.log(err));
  }, [dispatch, location.pathname]);
  const verificationIconsDetails = [
    {
      name: "Email",
      iconName: faEnvelope,
      isverify: emailVerificationDetails?.status,
    },
    {
      name: "Mobile",
      iconName: faMobileScreen,
      isverify: mobileVerificationDetails?.status,
    },
    {
      name: "GovtId",
      iconName: faIdCard,
      isverify: govtIdVerificationDetails?.status,
    },
  ];
  return (
    <div>
      <SEO
        title={`${name}-members-page`}
        link={copyLink}
        imageUrl={profileImage || PLACEHOLDER_IMAGE[150]}
      />
      <div className="lime-container">
        <div className="lime-body">
          <div className="container-fluid">
            <div className="row">
              <div className="col-xl-12">
                <div className="profile-cover"></div>
                <div className="profile-header">
                  <div className="profile-img">
                    <img
                      src={profileImage || PLACEHOLDER_IMAGE[150]}
                      alt="profile-img"
                    />
                  </div>
                  <div className="profile-name">
                    <h3>{name}</h3>
                  </div>
                  <div className="profile-header-menu">
                    <ul className="list-unstyled">
                      {/* <li>
                        <a href="#" className="active">
                          Feed
                        </a>
                      </li>
                      <li>
                        <a href="#">About</a>
                      </li>
                      <li>
                        <a href="#">Friends</a>
                      </li>
                      <li>
                        <a href="#">Photos</a>
                      </li>
                      <li>
                        <a href="#">Videos</a>
                      </li>
                      <li>
                        <a href="#">Music</a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fas fa-share"></i>&nbsp;&nbsp;Share
                        </a>
                      </li> */}
                      <Card className=" p-2">
                        {verificationIconsDetails?.map((props) => (
                          <li key={props.name}>
                            <VerificationIcons
                              name={props.name}
                              iconName={props.iconName}
                              verificationStatus={verificationDetails}
                            />
                          </li>
                        ))}
                      </Card>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title">About Me</h5>
                    <div className="table-responsive">
                      <table className="table">
                        <tbody>
                          <tr>
                            <td>I am</td>
                            <td>: {iAm}</td>
                          </tr>
                          <tr>
                            <td>Date of Birth</td>
                            <td>: {dateOfBirth}</td>
                          </tr>
                          <tr>
                            <td>Place of Birth</td>
                            <td>: {placeOfBirth}</td>
                          </tr>
                          <tr>
                            <td>Naaligai</td>
                            <td>: {naaligai}</td>
                          </tr>
                          <tr>
                            <td>Age</td>
                            <td>: {age}</td>
                          </tr>
                          <tr>
                            <td>Blood Group</td>
                            <td>: {bloodGroup}</td>
                          </tr>
                          <tr>
                            <td>Looking For</td>
                            <td>: {lookingFor}</td>
                          </tr>
                          <tr>
                            <td>Created By</td>
                            <td>: {createdBy}</td>
                          </tr>
                          <tr>
                            <td>Marital Status</td>
                            <td>: {maritalStatus}</td>
                          </tr>
                          <tr>
                            <td>Contact Number</td>
                            <td>: {contactNumber}</td>
                          </tr>
                          <tr>
                            <td>WhatsApp Number</td>
                            <td>: {whatsappNumber}</td>
                          </tr>
                          <tr>
                            <td>Residential Address</td>
                            <td>: {address}</td>
                          </tr>
                          <tr>
                            <td>Landmark</td>
                            <td>: {landmark}</td>
                          </tr>
                          <tr>
                            <td>Home Town</td>
                            <td>: {hometown}</td>
                          </tr>
                          <tr>
                            <td>State</td>
                            <td>: {stateName}</td>
                          </tr>
                          <tr>
                            <td>Nationality</td>
                            <td>: {nationality}</td>
                          </tr>
                          <tr>
                            <td>Pincode</td>
                            <td>: {pincode}</td>
                          </tr>
                          <tr>
                            <td>Field of Study</td>
                            <td>: {fieldOfStudy}</td>
                          </tr>
                          <tr>
                            <td>Degree</td>
                            <td>: {degree}</td>
                          </tr>
                          <tr>
                            <td>Occupation</td>
                          </tr>
                          <tr>
                            <td>Company Name</td>
                            <td>: {companyName}</td>
                          </tr>
                          <tr>
                            <td>Designation</td>
                            <td>: {designation}</td>
                          </tr>
                          <tr>
                            <td>Annual Income</td>
                            <td>: {annualIncome}</td>
                          </tr>
                          <tr>
                            <td>City of Working</td>
                            <td>: {cityOfWorking}</td>
                          </tr>
                          <tr>
                            <td>Height (in feet)</td>
                            <td>: {height}</td>
                          </tr>
                          <tr>
                            <td>Weight (in feet)</td>
                            <td>: {weight}</td>
                          </tr>
                          <tr>
                            <td>Complextion</td>
                            <td>: {complextion}</td>
                          </tr>
                          <tr>
                            <td>Rasi</td>
                            <td>: {rasi}</td>
                          </tr>
                          <tr>
                            <td>Nakshatra</td>
                            <td>: {nakshatra}</td>
                          </tr>
                          <tr>
                            <td>Horoscope Image</td>
                            <td>: {horoscopeImage}</td>
                          </tr>
                          <tr>
                            <td>Gothram</td>
                            <td>: {gothram}</td>
                          </tr>
                          <tr>
                            <td>Lagnam</td>
                            <td>: {lagnam}</td>
                          </tr>
                          {/* <tr>
                            <td>Siblings</td>
                            <td>: {siblings}</td>
                          </tr> */}
                          <tr>
                            <td>Father Name</td>
                            <td>: {fatherName}</td>
                          </tr>
                          <tr>
                            <td>Father Occupation</td>
                            <td>: {fatherOccupation}</td>
                          </tr>
                          <tr>
                            <td>Mother Name</td>
                            <td>: {motherName}</td>
                          </tr>
                          <tr>
                            <td>Mother Occupation</td>
                            <td>: {motherOccupation}</td>
                          </tr>
                          <tr>
                            <td>Religion</td>
                            <td>: {religion}</td>
                          </tr>
                          <tr>
                            <td>Caste</td>
                            <td>: {caste}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MembersPage;
