export const getDayOfWeek = (dateString) => {
  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const date = new Date(dateString);
  const dayIndex = date.getDay();

  return daysOfWeek[dayIndex];
};

// Example usage:
// const birthDate = "1993-08-13"; // YYYY-MM-DD format
// console.log(`The day of the week is: ${getDayOfWeek(birthDate)}`);
