import React from "react";
import { PLACEHOLDER_IMAGE } from "../../constant";

const ProfileCard = ({
  userId,
  username,
  profileImage,
  intro,
  name,
  profileRasi,
  age,
  index,
  onClickReadMore,
  href,
}) => (
  <div
    id={"profile-card-" + index}
    className="card customer-cards gridItem"
    key={index}
    style={{ maxWidth: "275px" }}
  >
    <div className="card-body">
      <div className="gridItem flex-col">
        <img
          src={profileImage || PLACEHOLDER_IMAGE[100]}
          alt=""
          className="border50"
          style={{ height: "150px", width: "150px" }}
        />
        <p
          className="m-0 py-3 text-muted"
          style={{
            textOverflow: "ellipsis",
            overflow: "hidden",
            width: "200px",
            whiteSpace: "nowrap",
          }}
        >
          {intro}
        </p>
        <div className="content-divider m-auto bold"></div>
        <h6 className="pt-3">
          <strong>ID: </strong>
          {userId}
        </h6>
        <h6 className="pt-1">
          <strong>User Name: </strong>
          {username}
        </h6>
        <h3 className="pt-1">{name}</h3>
        <p className="customer-designation text-muted m-0">{profileRasi}</p>
        <p className="customer-designation text-muted m-0">
          <strong>Age: </strong>
          {age}
        </p>
        <a href={href}>
          <button
            className="btn btn-rounded btn-outline-danger mt-2"
            onClick={onClickReadMore}
          >
            Read More
          </button>
        </a>
      </div>
    </div>
  </div>
);

export default ProfileCard;
