import {
  faFile,
  faFilePen,
  faHome,
  faMagnifyingGlass,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import MenuIcon from "@mui/icons-material/Menu";
import { Divider } from "@mui/material";
import MuiAppBar from "@mui/material/AppBar";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import { styled, useTheme } from "@mui/material/styles";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { KW_LOGO, PLACEHOLDER_IMAGE } from "../../constant";
import useMediaQuery from "../../hooks/use-media-query";
import { getCount, getUser } from "../../store/selector";
import { createCount, onSignOut } from "../../store/services";
import { actions } from "../../store/slice";
import { errorToaster, successToaster } from "../../utils/alerts";
import { getItem, removeItem } from "../../utils/local-storage";
import {
  getIsFormChanged,
  getProfile,
  getProfileImage,
} from "../../view/profile/store/selector";
import { saveProfile } from "../../view/profile/store/services";
import { actions as searchProfileActions } from "../../view/search-profile/store/slice";
import Sidebar from "../sidebar";

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

const Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const profileImage = useSelector(getProfileImage);
  const { token } = useSelector(getUser);
  const profileObj = useSelector(getProfile);
  const isFormChanged = useSelector(getIsFormChanged);
  const count = useSelector(getCount);
  const currentUser = getItem("current_user");
  const { isDesktop } = useMediaQuery();
  const [profileHover, setProfileHover] = useState(false);

  useEffect(() => {
    createCount(getItem("the_main_app"))
      .then((res) => {
        if (res?.data?.success) {
          dispatch(actions.setCount(res?.data?.count));
        }
      })
      .catch((err) => console.error(err));
  }, [dispatch]);

  useEffect(() => {
    dispatch(actions.setToken(getItem("the_main_app")));
    getItem("current_user") &&
      dispatch(actions.setLoginSuccess(getItem("current_user")));
    const url = window.location.href;
    url.includes("profile") && dispatch(actions.setEditProfile(true));
    url.includes("profileedit") && dispatch(actions.setEditProfile(false));
  }, [dispatch, token]);

  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const sidebarRef = useRef(null);
  const dropdownmenuRef = useRef(null);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const menu = [
    {
      isVisible: true,
      name: "Home",
      link: "/",
      icon: faHome,
      onClick: () => navigate("/"),
    },
    {
      isVisible: !token,
      name: "Register",
      link: "/register",
      icon: faFilePen,
      onClick: () => navigate("/register"),
    },
    {
      isVisible: !token,
      name: "Login",
      link: "/login",
      icon: faFile,
      onClick: () => navigate("/login"),
    },
    {
      isVisible: token,
      name: "My Profile",
      link: "/profileedit",
      icon: faUser,
      onClick: () => navigate("/profileedit"),
    },
    {
      isVisible: token,
      name: "Search Profile",
      link: "/search-profiles",
      icon: faMagnifyingGlass,
      onClick: () => navigate("/search-profiles"),
    },
    // {
    //   isVisible: token,
    //   name: "Set Preference",
    //   link: "/preference",
    //   icon: faSliders,
    //   onClick: () => navigate("/preference"),
    // },
  ];

  const handleClickSave = () => {
    dispatch(actions.setLoading(true));
    saveProfile(token, {
      ...profileObj,
      ...currentUser,
    })
      .then((res) => {
        dispatch(actions.setLoading(false));
        res.data.success &&
          successToaster("Profile Updated Successfully!!!", 4000);
      })
      .catch(
        (err) =>
          err &&
          errorToaster(
            "Facing issue in saving profile. Please Try After Sometime!!!",
            4000
          )
      );
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutsideDropdown);
    return () => {
      document.removeEventListener("mousedown", handleClickOutsideDropdown);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      setOpen(false);
    }
  };

  const handleClickOutsideDropdown = (event) => {
    if (
      dropdownmenuRef.current &&
      !dropdownmenuRef.current.contains(event.target)
    ) {
      setProfileHover(false);
    }
  };

  return (
    <div className="lime-header" style={{ backgroundColor: "#f7f8fa" }}>
      {isDesktop ? (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <CssBaseline />
          <AppBar position="fixed" open={open}>
            <Toolbar
              style={{
                backgroundColor: "#f4f7fc",
              }}
            >
              <nav
                className="navbar navbar-expand-lg"
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <img
                  onClick={() => navigate("/")}
                  src={KW_LOGO}
                  alt="kalyanawaran"
                  width={225}
                />

                <div
                  className="collapse navbar-collapse"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    justifyContent: isDesktop ? "flex-end" : "center",
                  }}
                  id="navbarSupportedContent"
                >
                  <ul className=" mt-3 d-flex align-items-center justify-content-center">
                    {location.pathname === "/search-profiles" && (
                      <div className=" d-flex align-items-center justify-content-center">
                        <input
                          placeholder="Search for Grooms, Brides..."
                          className=" form-control"
                          onChange={(e) =>
                            dispatch(
                              searchProfileActions.setSearch(e.target.value)
                            )
                          }
                        />
                        <button className=" btn btn-primary mx-3 ">
                          <FontAwesomeIcon icon={faMagnifyingGlass} />
                        </button>
                      </div>
                    )}
                    {menu.map(
                      ({ isVisible, onClick, name, icon, link }, index) => (
                        <div
                          key={index}
                          style={{
                            marginBottom: "5px",
                          }}
                          hidden={!isVisible}
                          className=" px-3 mx-3 mt-2"
                        >
                          {isVisible ? (
                            <button
                              // href={link}
                              className="btn"
                              style={{
                                textDecoration: "none",
                                padding: "5px 0",
                                marginBottom: "5px",
                                color:
                                  location.pathname !== link
                                    ? "gray"
                                    : "#037afb",
                              }}
                              onClick={onClick}
                            >
                              <FontAwesomeIcon icon={icon} />
                              {`  ${name}`}
                            </button>
                          ) : (
                            <></>
                          )}
                        </div>
                      )
                    )}
                    {location.pathname === "/profileedit" && (
                      <button
                        className="btn btn-primary"
                        style={{ marginRight: "10px" }}
                        onClick={handleClickSave}
                        disabled={!isFormChanged}
                      >
                        Save
                      </button>
                    )}
                  </ul>
                  <div style={{ cursor: "pointer", position: "relative" }}>
                    <IconButton>
                      <Avatar
                        onClick={() => setProfileHover(!profileHover)}
                        alt="Remy Sharp"
                        src={
                          profileImage === ""
                            ? PLACEHOLDER_IMAGE[50]
                            : profileImage
                        }
                      />
                    </IconButton>
                    {profileHover && (
                      <div
                        onClick={() => setProfileHover(!profileHover)}
                        style={{
                          width: "max-content",
                          top: "55px",
                          right: "-10px",
                          borderRadius: ".5rem",
                          boxShadow: " 0 3px 10px rgba(62, 85, 120, .045)",
                        }}
                        className="text-black  py-2 bg-white position-absolute"
                        ref={dropdownmenuRef}
                      >
                        <div className="border-bottom">
                          <div>
                            {(getItem("current_user")?.username ||
                              getItem("current_user")?.userId) && (
                              <div className="nav-item">
                                <b>User Info</b>
                              </div>
                            )}
                            {getItem("current_user")?.username && (
                              <h6 className="nav-item">
                                User Name:{" "}
                                <span
                                  style={{ fontStyle: "italic", opacity: ".5" }}
                                >
                                  {getItem("current_user")?.username || ""}
                                </span>
                              </h6>
                            )}
                            {getItem("current_user")?.userId && (
                              <h6 className="nav-item">
                                User Id:{" "}
                                <span
                                  style={{ fontStyle: "italic", opacity: ".5" }}
                                >
                                  {getItem("current_user")?.userId || ""}
                                </span>
                              </h6>
                            )}
                            {/* <h6
                              className="nav-item"
                              onClick={() => navigate("/account")}
                            >
                              Account Information
                            </h6>
                            <h6
                              className="nav-item"
                              onClick={() => navigate("/change-password")}
                            >
                              Change Password
                            </h6>
                            <h6
                              className="nav-item"
                              onClick={() => navigate("/verify")}
                            >
                              Verification
                            </h6> */}
                            <h6 className="nav-item">Count: {count}</h6>
                          </div>
                        </div>
                        <div
                          onClick={() => {
                            if (token) {
                              removeItem("the_main_app");
                              removeItem("current_user");
                              onSignOut(token)
                                .then((res) => res)
                                .catch((err) => console.error("err", err));
                              window.location.reload(false);
                            }
                          }}
                        >
                          <h6 className="nav-item">Log Out</h6>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </nav>
            </Toolbar>
          </AppBar>
        </Box>
      ) : (
        <Box sx={{ display: "flex", justifyContent: "center" }} id={`sidebar`}>
          <CssBaseline />
          <AppBar position="fixed" open={open}>
            <Toolbar
              style={{
                backgroundColor: "#f4f7fc",
              }}
            >
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                sx={{ mr: 2, ...(open && { display: "none" }) }}
              >
                <MenuIcon style={{ color: "#000000", opacity: "0.5" }} />
              </IconButton>
              <nav
                className="navbar navbar-expand-lg"
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <a className="navbar-brand" href="/" style={{ margin: 0 }}>
                  <img
                    src="https://res.cloudinary.com/dc3dylylv/image/upload/v1670433144/Logos/Kalyanawaran/Finalized_Logo_Kalyawaran_-_1725_x_353_guysdv.png"
                    alt="kalyanawaran"
                    width={225}
                  />
                </a>
                <div
                  // className="collapse navbar-collapse"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    justifyContent: isDesktop ? "flex-end" : "center",
                  }}
                  id="navbarSupportedContent"
                >
                  {/* <div className="form-inline my-2 my-lg-0 search">
                      <input
                        className="form-control mr-sm-2"
                        type="search"
                        placeholder="Search for Grooms, Brides..."
                        aria-label="Search"
                      />
                    </div> 
                */}
                  <ul
                    className="navbar-nav ml-auto"
                    style={{ alignItems: "center" }}
                  >
                    {/* <Localization /> */}
                    {token && (
                      <>
                        <button
                          className="btn btn-primary"
                          style={{ marginRight: "10px" }}
                          onClick={() => {
                            if (token) {
                              removeItem("the_main_app");
                              removeItem("current_user");
                              onSignOut(token)
                                .then((res) => res)
                                .catch((err) => console.error("err", err));
                              window.location.reload(false);
                            }
                          }}
                        >
                          Logout
                        </button>
                        <button
                          className="btn btn-primary"
                          style={{ marginRight: "10px" }}
                          onClick={handleClickSave}
                          disabled={!isFormChanged}
                        >
                          Save
                        </button>
                      </>
                    )}
                    <div>
                      <IconButton
                        aria-haspopup="true"
                        color="inherit"
                        aria-controls="profile-menu"
                      >
                        {/* AccountIcon  */}
                        <Avatar
                          alt="account-icon"
                          src={
                            profileImage === ""
                              ? PLACEHOLDER_IMAGE[50]
                              : profileImage
                          }
                        />
                      </IconButton>
                    </div>
                  </ul>
                </div>
              </nav>
            </Toolbar>
          </AppBar>
          <Drawer
            sx={{
              width: drawerWidth,
              flexShrink: 0,
              "& .MuiDrawer-paper": {
                width: drawerWidth,
                boxSizing: "border-box",
              },
            }}
            variant="persistent"
            anchor="left"
            open={open}
            ref={sidebarRef}
          >
            <DrawerHeader>
              <IconButton onClick={handleDrawerClose}>
                {theme.direction === "ltr" ? (
                  <ChevronLeftIcon />
                ) : (
                  <ChevronRightIcon />
                )}
              </IconButton>
            </DrawerHeader>
            <Divider />
            <Sidebar />
          </Drawer>
        </Box>
      )}
    </div>
  );
};

export default Header;
