const initialState = {
  counter: 0,
  pagination: {
    totalPage: 1,
    currentPage: 1,
  },
  profiles: [],
  search: "",
  filter: {
    minAge: 0,
    maxAge: 60,
    rasi: [],
    profilesAvalabilty: false,
  },
  sort: "",
};

export default initialState;
