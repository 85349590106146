export const KW_LOGO =
  "https://res.cloudinary.com/dc3dylylv/image/upload/v1670433144/Logos/Kalyanawaran/Finalized_Logo_Kalyawaran_-_1725_x_353_guysdv.png";

export const ICONS = {
  register:
    "https://res.cloudinary.com/dc3dylylv/image/upload/v1674621027/Kalyanawaran/user_pisnap.png",
  createProfile:
    "https://res.cloudinary.com/dc3dylylv/image/upload/v1674621027/Kalyanawaran/notebook_ezk1mq.png",
  searchProfile:
    "https://res.cloudinary.com/dc3dylylv/image/upload/v1674621027/Kalyanawaran/search_xoharv.png",
};

export const COLORS = {
  primary: "#037afb",
  secondary: "#384c6d",
  success: "#06ba54",
  danger: "#eb3f3f",
  warning: "#ffcd36",
  info: "#5780f7",
  dark: "#37414d",
};

export const PLACEHOLDER_IMAGE = {
  50: "https://picsum.photos/50",
  100: "https://picsum.photos/100",
  150: "https://picsum.photos/150",
  200: "https://picsum.photos/200",
  300: "https://picsum.photos/300",
  400: "https://picsum.photos/400",
  500: "https://picsum.photos/500",
  600: "https://picsum.photos/600",
  700: "https://picsum.photos/700",
};

export const IMAGE_FORMAT = ".jpg, .jpeg, .png, .gif";
