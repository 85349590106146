import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import Message from "../message";

function TextInputWithIcon({
  buttonText,
  buttonIcon,
  type = "text",
  name = "name",
  value = "",
  onChange,
  placeholder = "",
  className = "",
  onKeyDown,
  buttonOnClick,
  error,
  required = false,
}) {
  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "5px",
        }}
      >
        <input
          type={type}
          name={name}
          className={"form-control" + className}
          placeholder={placeholder}
          onChange={onChange}
          onKeyDown={onKeyDown}
          defaultValue={value}
          required={required}
        />
        <div className="input-group-append" style={{ marginLeft: 5 }}>
          {buttonText && (
            <button
              className="btn btn-secondary form-control"
              type="button"
              id="button-addon1"
              onClick={buttonOnClick}
            >
              {buttonText}
            </button>
          )}
          {buttonIcon && (
            <button
              className="btn btn-secondary form-control"
              type="button"
              id="button-addon1"
              onClick={buttonOnClick}
            >
              <FontAwesomeIcon icon={buttonIcon} />
            </button>
          )}
        </div>
      </div>
      {error && <Message message={error} type={"danger"} />}
    </>
  );
}

export default TextInputWithIcon;
