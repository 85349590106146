import Box from "@mui/material/Box";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import LableWithFormField from "../../../components/label-with-formfield";
import * as config from "../../../config/config";
import { getLang } from "../../../store/selector";
import { calcAge } from "../../../utils/age-calculator";
import { getDayOfWeek } from "../../../utils/get-day-of-week";
import { getBirthDetails } from "../store/selector";
import { actions } from "../store/slice";

const BirthDetails = () => {
  const dispatch = useDispatch();
  const birthDetails = useSelector(getBirthDetails);
  const { dob, tamilYear, tamilMonth, tamilDate } = birthDetails;
  const { date, placeOfBirth, time, day } = dob;
  const lang = useSelector(getLang);
  const handleChange = (e, secondaryObj) => {
    const { name, value } = e?.target;
    dispatch(actions.setIsFormChanged(true));
    if (secondaryObj) {
      dispatch(
        actions.setBirthDetails({
          ...birthDetails,
          dob: { ...dob, [name]: value },
        })
      );
    } else {
      dispatch(
        actions.setBirthDetails({
          ...birthDetails,
          [name]: value,
        })
      );
    }
  };

  const birthDetailsFields = [
    {
      type: "text",
      text: "Place of Birth",
      id: "placeOfBirth",
      name: "placeOfBirth",
      defaultValue: placeOfBirth,
      placeholder: "Chennai",
      onChange: (e) => handleChange(e, true),
    },
    {
      type: "select",
      text: "Tamil Date",
      id: "tamilDate",
      name: "tamilDate",
      defaultValue: tamilDate,
      onChange: (e) => handleChange(e),
      array: config.days,
    },
    {
      type: "select",
      text: "Tamil Month",
      id: "tamilMonth",
      name: "tamilMonth",
      defaultValue: tamilMonth,
      onChange: (e) => handleChange(e),
      array: config.tamilMonth[lang],
    },
    {
      type: "select",
      text: "Tamil Year",
      id: "tamilYear",
      name: "tamilYear",
      defaultValue: tamilYear,
      onChange: (e) => handleChange(e),
      array: config.tamilYear[lang],
    },
    {
      type: "textField",
      text: "Date of Birth",
      id: "date",
      name: "date",
      value: date,
      onChange: (e) => handleChange(e, true),
    },
    {
      type: "textField",
      text: "Birth Time",
      id: "time",
      name: "time",
      value: time,
      onChange: (e) => handleChange(e, true),
    },
  ];

  useEffect(() => {
    const d = new Date(date);
    let day = d.getDay();

    dispatch(
      actions.setBirthDetails({
        ...birthDetails,
        dob: { ...dob, day: config.dayOrder[day], age: calcAge(date) },
      })
    );
  }, [date, dispatch]);

  return (
    <Box
      sx={{
        flexGrow: 1,
        padding: "25px",
      }}
    >
      <div className="table-responsive">
        <table className="table">
          <tbody>
            {birthDetailsFields.map((props, index) => (
              <LableWithFormField key={index} {...props} />
            ))}
            <tr>
              <td style={{ borderTop: "none" }}>
                <label htmlFor="exampleFormControlSelect1-age">Age</label>
              </td>
              <td style={{ borderTop: "none" }}>{`${calcAge(
                date
              )} years old`}</td>
            </tr>
            <tr>
              <td style={{ borderTop: "none" }}>
                <label htmlFor="exampleFormControlSelect1-day">Day</label>
              </td>
              <td style={{ borderTop: "none", textTransform: "capitalize" }}>
                {getDayOfWeek(date)}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </Box>
  );
};

export default BirthDetails;
