import { faEye } from "@fortawesome/free-solid-svg-icons/faEye";
import { faEyeSlash } from "@fortawesome/free-solid-svg-icons/faEyeSlash";
import React, { useEffect, useState } from "react";
import ReactGA from "react-ga";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { getUser } from "../../store/selector";
import { onSignUp, verifyToken } from "../../store/services";
import { actions } from "../../store/slice";
import { errorToaster, successToaster } from "../../utils/alerts";
import LayoutPage from "../layout-page";
import Message from "../message";
import TextInputWithIcon from "../text-input-with-icon/text-input-with-icon";

const Register = () => {
  const location = useLocation();
  const currentUser = useSelector(getUser);
  const {
    username,
    emailAddress,
    confirmEmailAddress,
    password,
    confirmPassword,
    isTermsAccepted,
    token,
  } = currentUser;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);

  useEffect(() => {
    token &&
      verifyToken(token)
        .then((res) => {
          if (res.data.success) {
            dispatch(actions.setToken(token));
            dispatch(actions.setIsHeaderFooter(true));
            navigate("/");
          }
        })
        .catch((err) => console.error("err", err));
  }, [dispatch, navigate, token]);

  return (
    <LayoutPage
      content={
        <div>
          <div className="form-group pb-2">
            <input
              type="email"
              name="emailAddress"
              className="form-control"
              id="emailAddress"
              // aria-describedby="emailHelp"
              placeholder="Enter email"
              value={emailAddress}
              onChange={(e) =>
                dispatch(
                  actions.setCurrentUser({
                    ...currentUser,
                    emailAddress: e.target.value,
                  })
                )
              }
              required
            />
          </div>
          <div className="form-group pb-2">
            <input
              type="email"
              name="confirmEmailAddress"
              className="form-control"
              id="confirmEmailAddress"
              aria-describedby="emailHelp2"
              placeholder="Confirm email"
              value={confirmEmailAddress}
              onChange={(e) =>
                dispatch(
                  actions.setCurrentUser({
                    ...currentUser,
                    confirmEmailAddress: e.target.value,
                  })
                )
              }
              required
            />
          </div>
          <div className="form-group pb-2">
            <input
              type="text"
              name="username"
              className="form-control"
              id="username"
              aria-describedby="username"
              placeholder="Username"
              value={username}
              onChange={(e) =>
                dispatch(
                  actions.setCurrentUser({
                    ...currentUser,
                    username: e.target.value,
                  })
                )
              }
              required
            />
          </div>
          <div className="form-group pb-2">
            <TextInputWithIcon
              type={showPassword ? "text" : "password"}
              name="password"
              id="password"
              placeholder="Password"
              value={password}
              onChange={(e) =>
                dispatch(
                  actions.setCurrentUser({
                    ...currentUser,
                    password: e.target.value,
                  })
                )
              }
              buttonOnClick={() => {
                setShowPassword(!showPassword);
              }}
              buttonIcon={showPassword ? faEyeSlash : faEye}
              required
            />
            {/* <input className="form-control" /> */}
            {password.length < 1 ? (
              <></>
            ) : password.length <= 4 ? (
              <p style={{ color: "red", marginTop: "10px" }}>Week</p>
            ) : password.length <= 8 ? (
              <p style={{ color: "orange", marginTop: "10px" }}>Medium</p>
            ) : (
              <p style={{ color: "green", marginTop: "10px" }}>Strong</p>
            )}
          </div>
          <div className="form-group pb-2">
            <TextInputWithIcon
              type={showConfirmPassword ? "text" : "password"}
              name="confirmPassword"
              id="confirmPassword"
              placeholder="Confirm Password"
              value={confirmPassword}
              onChange={(e) =>
                dispatch(
                  actions.setCurrentUser({
                    ...currentUser,
                    confirmPassword: e.target.value,
                  })
                )
              }
              buttonOnClick={() => {
                setShowConfirmPassword(!showConfirmPassword);
              }}
              buttonIcon={showConfirmPassword ? faEyeSlash : faEye}
              required
            />
          </div>
          {confirmPassword.length < 1 ? (
            <Message message={""} type="" />
          ) : password === confirmPassword ? (
            <Message message={"Password matching"} type="success" />
          ) : (
            <Message
              message={"Password mismatch"}
              style={{
                marginTop: "10px",
              }}
              type="danger"
            />
          )}
          <div
            className="custom-control custom-checkbox form-group pb-2"
            style={{ padding: 0 }}
          >
            <label>
              <input
                type="checkbox"
                defaultChecked={isTermsAccepted}
                onChange={() =>
                  dispatch(
                    actions.setCurrentUser({
                      ...currentUser,
                      isTermsAccepted: !isTermsAccepted,
                    })
                  )
                }
              />
              {"  "}I Agree with{" "}
              <button
                className="btn btn-link"
                onClick={() => navigate("/terms")}
              >
                Terms &amp; Conditions
              </button>
            </label>
          </div>

          <button
            //href="#"
            className="btn btn-primary"
            style={{ marginRight: 5 }}
            disabled={
              emailAddress === "" ||
              confirmEmailAddress === "" ||
              password === "" ||
              confirmPassword === "" ||
              username === "" ||
              !isTermsAccepted
            }
            onClick={() => {
              dispatch(actions.setLoading(true));
              onSignUp({
                emailAddress,
                password,
                username,
                isTermsAccepted,
              })
                .then((res) => {
                  dispatch(actions.setLoading(false));
                  dispatch(
                    actions.setCurrentUser({
                      username: "",
                      emailAddress: "",
                      confirmEmailAddress: "",
                      password: "",
                      confirmPassword: "",
                      isTermsAccepted: "",
                    })
                  );
                  return successToaster(res.data.message, 6000);
                })
                .catch((err) => {
                  dispatch(actions.setLoading(false));
                  dispatch(
                    actions.setCurrentUser({
                      username: "",
                      emailAddress: "",
                      confirmEmailAddress: "",
                      password: "",
                      confirmPassword: "",
                      isTermsAccepted: "",
                    })
                  );
                  return errorToaster(err.response.data.message, 6000);
                });
            }}
          >
            Register
          </button>
          <button
            className="btn btn-secondary"
            style={{ marginLeft: 5 }}
            onClick={() => navigate("/login")}
          >
            Login
          </button>
        </div>
      }
      title="Sign Up"
    />
  );
};

export default Register;
