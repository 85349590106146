const initialState = {
  counter: 10,
  personalInfo: {
    name: "",
    iAm: "Groom",
    aboutMe: "",
    height: "4.5",
    weight: "",
    profileImage: "",
    bloodGroup: "A positive (A+)",
    complextion: "",
    maritalStatus: "Single",
  },
  birthDetails: {
    dob: {
      date: "",
      placeOfBirth: "",
      age: "",
      time: "",
      day: "",
    },
    tamilYear: "",
    tamilMonth: "",
    tamilDate: "",
  },
  residentialAddress: {
    address: "",
    landmark: "",
    hometown: "",
    city: "",
    stateName: "",
    nationality: "",
    pincode: "",
  },
  qualification: {
    fieldOfStudy: "",
    degree: "",
  },
  occupation: {
    companyName: "",
    designation: "",
    annualIncome: "",
    cityOfWorking: "",
  },
  horoscopeDetails: {
    rasi: "",
    nakshatra: "",
    gothram: "",
    mathulam: "",
    lagnam: "",
    naaligai: "",
    thithi: "",
    dasaPlanet: "",
    dasaBalance: "",
  },
  familyDetails: {
    fatherName: "",
    fatherOccupation: "",
    fatherAlive: "",
    motherName: "",
    motherOccupation: "",
    motherAlive: "",
    religion: "",
    caste: "",
    siblings: {
      brother: {
        number: 1,
        marriageNum: 0,
      },
      sister: {
        number: 1,
        marriageNum: 0,
      },
    },
  },
  horoscopeImage: "",
  govtId: "",
  lookingFor: "",
  createdBy: "",
  startDate: "",
  contactDetails: {
    emailId: "",
    contactNumber: "",
    whatsappNumber: "",
    alternativeContactNumber: "",
  },
  rasiKatam: [[], [], [], [], [], [], [], [], [], [], [], []],
  rasiPlanets: {
    Ma: "Select",
    Ve: "Select",
    Me: "Select",
    Su: "Select",
    Ka: "Select",
    Ju: "Select",
    Mo: "Select",
    Sa: "Select",
    Ke: "Select",
    La: "Select",
  },
  navamsaKatam: [[], [], [], [], [], [], [], [], [], [], [], []],
  navamsaPlanets: {
    Ma: "Select",
    Ve: "Select",
    Me: "Select",
    Su: "Select",
    Ka: "Select",
    Ju: "Select",
    Mo: "Select",
    Sa: "Select",
    Ke: "Select",
    La: "Select",
  },
  ownHouse: "Yes",
  verificationDetails: {
    emailVerificationDetails: {
      status: "",
      message: "",
    },
    mobileVerificationDetails: {
      status: "",
      message: "",
    },
    govtIdVerificationDetails: {
      status: "",
      message: "",
    },
  },
  eatingHabits: "Doesn't matter",
  drinkingHabits: "Doesn't matter",
  smookingHabits: "Doesn't matter",
  isFormChanged: false,
};

export default initialState;
