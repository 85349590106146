import Lottie from "react-lottie";
import animationData from "../../assets/json/heart-animation.json"; // Replace with your animation data

const Loading = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
  };

  return (
    <div
      // className="loading"
      id="loading"
      style={{
        position: "fixed",
        width: "100%",
        height: "100%",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "rgba(0,0,0,0.5)",
        zIndex: 2,
        cursor: "pointer",
      }}
    >
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%,-50%)",
        }}
      >
        <h1 style={{ color: "white" }}>Loading...</h1>
        <Lottie options={defaultOptions} height={200} width={200} />
      </div>
    </div>
  );
};

export default Loading;
