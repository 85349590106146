import { createSelector } from "reselect";

const globalState = (state) => state.global;

const getCount = createSelector(globalState, (global) => global.count);
const getLang = createSelector(globalState, (global) => global.language);
const getUser = createSelector(globalState, (global) => global.currentUser);
const getUserName = createSelector(
  globalState,
  (global) => global.currentUser.username
);
const getComingSoon = createSelector(
  globalState,
  (global) => global.comingSoon
);
const getIsLoading = createSelector(globalState, (global) => global.isLoading);
const getIsHeaderFooter = createSelector(
  globalState,
  (global) => global.isHeaderFooter
);
const getError = createSelector(globalState, (global) => global.error);
const getCurrentUser = createSelector(globalState, (global) => {
  return {
    emailAddress: global.currentUser.emailAddress,
    username: global.currentUser.username,
    userId: global.currentUser.userId,
  };
});
const getWhatsappLink = createSelector(globalState, (global) => {
  return (
    `https://wa.me/?text=` +
    encodeURIComponent(
      `http://kalyanawaran.com/members/${global.currentUser.userId}`
    )
  );
});
const getCopyLink = createSelector(
  globalState,
  (global) => `http://kalyanawaran.com/members/${global.currentUser.userId}`
);

export {
  getComingSoon,
  getCopyLink,
  getCount,
  getCurrentUser,
  getError,
  getIsHeaderFooter,
  getIsLoading,
  getLang,
  getUser,
  getUserName,
  getWhatsappLink,
};
